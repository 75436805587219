export * from './cacheKey';
export * from './theme';
export * as Zodiac from './zodiac';
export * as Payment from './payment';
export * as AACheckoutConst from './aa-checkout';
export * as PhoneixConst from './phoneix';
export * from './proxy';

export enum ROUTE_URL {
  ROOT = '/', //root
  NOT_FOUND = '/404',
  IFRAME = 'iframe',
  FLIGHTS_CHECKOUT = '/flights/checkout',
  AA_CHECKOUT = '/aa/checkout',
  HOTEL_SEARCH = '/hotel/search',
  HOTEL_DETAIL = '/hotel/:id',
  HOTEL_GUEST_DETAIL = '/hotel/:hotelId/:roomId/:rateId/:bedGroupId/:rateOptionId',
  HOTEL_CONFIRMATION = '/hotel/confirmation',
  HOTEL_APPROVE_DETAIL = '/hotel/approve-detail/:approveId',
}
export const LOCALE_CODE = {
  ZH: 'zh-cn',
  EN: 'en-gb',
};
export const LOCALE_CODE_DEFAULT = 'zh-cn';

export const CURRENCY_DEFAULT = 'CNY';

export const UHF_EVENT_LOCALE_CHANGE = 'uhfLocaleChange';
export const UHF_EVENT_CURRENCY_CHANGE = 'uhfCurrencyChange';

export const HOTEL_ORDER_TYPE_HOTEL = 'hotel';
export const HOTEL_ORDER_ITEMS_ID = '140b5c83-0719-4273-9df1-f8f7601036d4';

export const WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const WECHAT_JS_SDK = 'https://res.wx.qq.com/open/js/jweixin-1.3.2.js';
