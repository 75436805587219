import React from 'react';
import { ROUTE_URL } from '@/helper/constants';
import { NoneLayout } from '@/components';

const Default = React.lazy(
  () => import(/* webpackChunkName: "aa/checkout" */ '@/pages/aa/checkout'),
);
const frameComponent = React.lazy(
  () => import(/* webpackChunkName: "iframe" */ '@/pages/iframe'),
);

export default [
  {
    path: `${ROUTE_URL.AA_CHECKOUT}`,
    component: Default,
    isAuth: false,
    layout: NoneLayout,
    children: [
      {
        path: `${ROUTE_URL.IFRAME}`,
        component: frameComponent,
      },
    ],
  },
];
