import React from 'react';
import { ROUTE_URL } from '@/helper/constants';
import { NoneLayout } from '@/components';

const Index = React.lazy(
  () => import(/* webpackChunkName: "hotel-detail" */ '@/pages/hotel/detail'),
);

export default [
  {
    path: `${ROUTE_URL.HOTEL_DETAIL}`,
    component: Index,
    isAuth: false,
    layout: NoneLayout,
  },
];
