import { useCallback, memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import UniversalHeader from '@airasia/phoenix-widgets/widgets/UniversalHeader';
import { useStore } from '@/hooks';
import Config from '@/config';
import {
  ROUTE_URL,
  UHF_EVENT_LOCALE_CHANGE,
  UHF_EVENT_CURRENCY_CHANGE,
} from '@/helper/constants';
import { getUHFConfig } from './config';
import './index.scss';

interface IProps {
  fireGtm?: (e) => void;
  onUserLoginStatus?: (isLogin: boolean) => void;
}

function Header(props: IProps) {
  const { fireGtm, onUserLoginStatus } = props;
  const { webStore, userStore } = useStore();
  const navigate = useNavigate();
  const uhfConfig = getUHFConfig();
  const isValidated = userStore.isValidated;
  const isLogin = userStore.isLogin;

  //set data
  const currency = userStore.currency;

  const clickHandler = useCallback(
    (label: string) => {
      const labels = label.split('|');
      const type = labels[0];

      switch (true) {
        case type.includes('mybookings'):
          // navigate(ROUTE_URL.MY_BOOKINGS);
          break;
      }
    },
    [navigate],
  );

  const handlerFireGtm = useCallback(
    (e) => {
      if (e['eventDetails.action'] === 'click') {
        clickHandler(e['eventDetails.label']);
      }

      fireGtm && fireGtm(e);
    },
    [fireGtm, clickHandler],
  );

  const hanlderUserLoginStatus = useCallback(
    async (isLogin: boolean) => {
      if (isLogin) {
        await userStore.setLoginFromHeaderCmp();
      } else {
        userStore.setLogout();
      }

      onUserLoginStatus && onUserLoginStatus(isLogin);
    },
    [onUserLoginStatus, userStore],
  );

  //monitor locale change
  useEffect(() => {
    const handleLocaleChange = async (e: Event) => {
      const locale = (e as CustomEvent).detail;

      userStore.setLocale(locale);
    };
    window.addEventListener(UHF_EVENT_LOCALE_CHANGE, handleLocaleChange);
    return () => {
      window.removeEventListener(UHF_EVENT_LOCALE_CHANGE, handleLocaleChange);
    };
  }, [userStore]);

  //monitor currency change
  useEffect(() => {
    const handleCurrencyChange = async (e: Event) => {
      const currency = (e as CustomEvent).detail;
      userStore.setCurrency(currency);
    };
    window.addEventListener(UHF_EVENT_CURRENCY_CHANGE, handleCurrencyChange);
    return () => {
      window.removeEventListener(
        UHF_EVENT_CURRENCY_CHANGE,
        handleCurrencyChange,
      );
    };
  }, [userStore]);

  // open login window
  useEffect(() => {
    isValidated && !isLogin && openLogin();
  }, [isValidated, isLogin]);
  const openLogin = () => {
    const evt = new CustomEvent('openLogin', { detail: true });
    window.dispatchEvent(evt);
  };

  return (
    <>
      <UniversalHeader
        currency={currency}
        data={uhfConfig}
        environment={Config.APP_ENV}
        locale={userStore.locale}
        loginConfig={{
          HideLoginIconForMobile: false,
          reloadOnLogin: false,
          shouldSetCookie: true,
        }}
        showCurrency
        showLocale
        viewport={webStore.deviceType}
        fireGtm={handlerFireGtm}
        onUserLoginStatus={hanlderUserLoginStatus}
      />
    </>
  );
}

export default memo(observer(Header));
