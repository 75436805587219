export default {
  header: {
    myBookings: 'My bookings',
    myAccount: 'My account',
    logOut: 'Log out',
    localecurrency: 'Log out',
    login: 'Login/Signup',
    loginMenu: {
      bigMemberId: 'BIG Member ID',
      bigMemberPoints: 'BIG Points',
      status: 'Status',
    },
    loginTooltip: {
      description:
        'Log into get BIG Member discounts and also redeem your unlimited pass',
      loginButtonText: 'Log in/Sign up',
      title: 'Log in now for BIG Member discounts',
    },
    translations: {
      currency: 'Choose a currency',
      locale: 'Choose a language',
      mobileCurrencyLabel: 'Currency',
      mobileLocaleLabel: 'Language',
      settings: 'Settings',
    },
  },
};
