import { observable, makeObservable, action } from 'mobx';

export default class AStore {
  @observable someData = 'this is A Store';

  constructor() {
    makeObservable(this);
  }

  @action
  updateSomeData(newData) {
    this.someData = newData;
  }
}
