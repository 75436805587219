import { action, observable, makeObservable } from 'mobx';
import { Log } from '@/helper/log';
import { getURLParams } from '@/helper/util';

class HotelGlobal {
  @observable paymentURL = '';

  constructor() {
    makeObservable(this);
  }

  @action
  setFromURL = () => {
    try {
      const params = getURLParams();
      this.paymentURL = params.get('wmpurl');
    } catch (e) {
      console.error(e);
    }
  };
}

export default new HotelGlobal();
