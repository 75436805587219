const colors = {
  primary: '#dc3224',
  primaryDarker: '#c92d21',
  primaryLight: '#fbebe9',
  primaryLighter: '#fdf5f4',

  primaryWhite: '#ffffff',

  secondary: '#4b4fa6',
  secondaryDarker: '#4448a0',
  secondaryLighter: '#ededf6',

  tertiaryGreen: '#067e41',
  tertiaryGreenDarker: '#036835',
  tertiaryGreenLighter: '#e6f2ec',

  tertiaryYellow: '#D97F00',
  tertiaryYellowLighter: '#FFF4E6',

  tertiaryOrange: '#c94318',
  tertiaryOrangeDarker: '#b73810',
  tertiaryOrangeLighter: '#faece8',

  tertiaryBlue: '#2f73d2',
  tertiaryBlueDarker: '#2765bd',
  tertiaryBlueLighter: '#eaf1fb',

  darkBlack: '#212124',
  overlayBlack: '#0003',
  greyDarker: '#4c4c50',
  greyDark: '#75767A',

  grey: '#c5c9d0',
  greyLight: '#d9dbe0',
  greyLighter: '#f8f8f8',
  greyBorder: '#E5E5E5',

  starRatedColor: '#FFC226',
  starEmptyColor: '#C5C9D0',

  gray: '#DADADA',
};

export const theme = {
  colors,
};
