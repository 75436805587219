import http from './base/http';
import Config from '@/config';

const toParams = (obj: any) => {
  const result = [];
  Object.getOwnPropertyNames(obj).map((prop) => {
    const name = `${prop}`.replaceAll(/[A-Z]/g, (char) =>
      `_${char}`.toLowerCase(),
    );
    const val = obj[prop];

    if (Array.isArray(val)) {
      val.map((m) => result.push(`${name}=${m}`));
    } else {
      if (val !== undefined && val !== null && val !== '') {
        result.push(`${name}=${val}`);
      }
    }
  });
  return result.join('&');
};

export const getDestDetail = async <T>(params: {
  language: string;
  locationId: string;
}) => {
  return http.get<T>(
    `${Config.HOTEL.URL}/smartfill/v2/smartfill/${params.locationId}?language=${params.language}`,
    {},
    {
      headers: {
        'client-id': Config.HOTEL.CLIENT_ID,
      },
    },
  );
};

export const searchHotels = async <T>(
  params: {
    currency: string;
    countryCode: string;
    checkin: string;
    checkout: string;
    occupancy: (string | number)[];
    locationId?: string;
    starRating?: ('one' | 'two' | 'three' | 'four' | 'five')[];
    guestScore?: string[];
    category?: string[];
    amenity?: string[];
    paymentMethod?: ('bnpl' | 'freeCancellation' | 'pay_at_hotel')[];
    hotelName?: string;
    sortType?: string;
    pageNumber?: number;
    hotelsSize: number;
    language?: string;
    bigMemberId?: string;
  },
  ssoInfo,
) => {
  return http.get<T>(
    `${Config.HOTEL.URL}/bff/search/v2/hotels?${toParams(params)}&mtype=T4`,
    {},
    {
      headers: {
        'client-id': Config.HOTEL.CLIENT_ID,
        ...(ssoInfo && ssoInfo.accessToken
          ? { 'x-auth': ssoInfo.accessToken }
          : undefined),
      },
    },
  );
};

export const getOrderByKey = async <T>(key: string) => {
  return http.get<T>(
    `${Config.HOTEL.URL}/booking/v1/hotels/booking/wrapper/order/${key}
    `,
    {},
    {
      headers: {
        'client-id': Config.HOTEL.CLIENT_ID,
      },
    },
  );
};

export const getRooms = async <T>(
  hotelId: string,
  params: {
    language: string;
    currency: string;
    countryCode: string;
    checkin: string;
    checkout: string;
    occupancy: (string | number)[];
    bigMemberId?: string;
  },
  ssoInfo,
) => {
  return http.get<T>(
    `${Config.HOTEL.URL}/bff/details/v2/hotels/${hotelId}/rooms?${toParams(
      params,
    )}`,
    {},
    {
      headers: {
        'client-id': Config.HOTEL.CLIENT_ID,
        ...(ssoInfo && ssoInfo && ssoInfo.accessToken
          ? { 'x-auth': ssoInfo.accessToken }
          : undefined),
      },
    },
  );
};

export const getHotelContent = async <T>(hotelId: string, language: string) => {
  return http.get<T>(
    `${Config.HOTEL.URL}/details/v2/hotels/${hotelId}/content?language=${language}`,
    {},
    {
      headers: {
        'client-id': Config.HOTEL.CLIENT_ID,
      },
    },
  );
};

export const getRoomContent = async <T>(
  hotelId: string,
  roomId: string,
  language: string,
) => {
  return http.get<T>(
    `${Config.HOTEL.URL}/details/v2/hotels/${hotelId}/room/${roomId}/content?language=${language}`,
    {},
    {
      headers: {
        'client-id': Config.HOTEL.CLIENT_ID,
      },
    },
  );
};

export const checkoutHotel = async <T>(param: Hotel.CheckOutPayload) => {
  return http.post<T>(
    `${Config.HOTEL.URL}/unified/checkout/api/v1/unified/checkout`,
    param,
    {
      headers: {
        'client-id': Config.HOTEL.CLIENT_ID,
      },
    },
  );
};
