export default {
  components: {
    originDestionation: 'Destination',
    datepickerLabel1: 'Check-in',
    datepickerLabel2: 'Check-out',
    searchButton: 'Search',
    editSearchButton: 'Edit Search',
  },
  translations: {
    adultAgeLimit: '18 years and above',
    adultPlural: 'Adults',
    adultSingle: 'Adult',
    calendarResetButton: 'Reset',
    calendarSubmitButton: 'Confirm',
    childAgeDropdownLabel: 'Child {0} age',
    childAgeLimit: '2 to 17 years',
    childPlural: 'Children',
    childSingle: 'Child',
    guestPlural: 'Guests',
    guestSingle: 'Guest',
    infantAgeLimit: 'Less than 2 years',
    infantPlural: 'Infants',
    infantSingle: 'Infant',
    lowestFareLabel: 'Lowest all-in fares in {0} for 1 adult',
    multiRoomSelectTitle: 'Guests and Rooms',
    noResultLabel: 'There is no result for {0}',
    passengerModalHeaderLabel: 'Select guests and rooms',
    recentSearch: 'Recent Searches',
    roomAddButton: 'Add more rooms',
    roomPlural: 'Rooms',
    roomRemoveButton: 'Remove',
    roomSingle: 'Room',
    roomSubmitButton: 'Done',
    roomTitle: 'Room {0}',
  },
};
