import { StaticApi } from '@/api';
import { LOCALE_CODE_DEFAULT } from '../constants';

let tempAllStations = null;

export async function getStationInfo(
  stationCode: string,
): Promise<StationInfo> {
  const allStations =
    tempAllStations || (await StaticApi.getStations(LOCALE_CODE_DEFAULT));

  //cache for future use
  tempAllStations = allStations;

  const stationInfo = allStations.find((s) => s.StationCode === stationCode);

  if (stationInfo) {
    return {
      lat: stationInfo.Lat,
      dest: stationInfo.Dest,
      long: stationInfo.Long,
      pinYin: stationInfo.PinYin,
      aaFlight: stationInfo.AAFlight,
      cityCode: stationInfo.CityCode,
      cityName: stationInfo.CityName,
      timeZone: stationInfo.TimeZone,
      airportName: stationInfo.AirportName,
      countryCode: stationInfo.CountryCode,
      countryName: stationInfo.CountryName,
      stationCode: stationInfo.StationCode,
      stationName: stationInfo.StationName,
      alternativeName: stationInfo.AlternativeName,
      stationType: stationInfo.StationType,
      tag: stationInfo.Tag,
    };
  }
}
