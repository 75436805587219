import checkout from './checkout';
import aaCheckout from './aa-checkout';

export default {
  flights: {
    ...checkout,
    ...aaCheckout,
    errorPage: {
      errorText: '抱歉，出现错误！',
      errorSubtext: '加载下个页面出现故障，请重新搜索航班，十分抱歉！',
    },
  },
};
