import React from 'react';
import { ROUTE_URL } from '@/helper/constants';
import { NoneLayout } from '@/components';

const Search = React.lazy(
  () => import(/* webpackChunkName: "hotel-search" */ '@/pages/hotel/search'),
);

export default [
  {
    path: `${ROUTE_URL.HOTEL_SEARCH}`,
    component: Search,
    isAuth: false,
    layout: NoneLayout,
  },
];
