import { action, makeObservable } from 'mobx';

export default class IframeStore implements PageStore {
  constructor() {
    makeObservable(this);
  }

  @action
  onLoad = async () => {};

  @action
  onUnload = () => {};
}
