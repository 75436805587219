import { ErrorConfig } from '../base';
import { navigateBackWMP } from '@/helper/util';
import { showAACheckoutAlert } from '../../ui/alerts/aa-checkout-alerts';
import i18n from '@/helper/i18n';
const t = i18n.chain;

const getAACheckoutErrorKey = (error: any) =>
  error?.response?.data?.error?.messageKey;
const getAACheckoutErrorMessage = (error: any) =>
  error?.response?.data?.error?.message;
const getErrorMessage = (messageKey: string): string => {
  return t.error.aaCheckoutErrors?.[messageKey];
};

const getFormattedErrorMessage = (messageKey: string, error: any): string => {
  const baseMessage = getErrorMessage(messageKey);
  const responseMessage = getAACheckoutErrorMessage(error);
  return responseMessage ? `${baseMessage}: ${responseMessage}` : baseMessage;
};

export const aaCheckoutErrorConfig: ErrorConfig<any> = {
  flightUnavailable: {
    getErrorKey: getAACheckoutErrorKey,
    handleError: (error) => {
      const message = getErrorMessage('flightUnavailable');
      showAACheckoutAlert(message, error, {
        primaryHandleCallback: () => {
          navigateBackWMP();
        },
      });
    },
  },
  sessionUnavailable: {
    getErrorKey: getAACheckoutErrorKey,
    handleError: (error) => {
      const message = getErrorMessage('sessionUnavailable');
      showAACheckoutAlert(message, error, {
        primaryHandleCallback: () => {
          navigateBackWMP();
        },
      });
    },
  },
  errorDownStreamServiceCallFailed: {
    getErrorKey: getAACheckoutErrorKey,
    handleError: (error) => {
      const message = getErrorMessage('errorDownStreamServiceCallFailed');
      showAACheckoutAlert(message, error, {
        primaryHandleCallback: () => {
          navigateBackWMP();
        },
      });
    },
  },
  notSupported: {
    getErrorKey: getAACheckoutErrorKey,
    handleError: (error) => {
      const message = getErrorMessage('notSupported');
      showAACheckoutAlert(message, error, {
        primaryHandleCallback: () => {
          navigateBackWMP();
        },
      });
    },
  },
  errorInvalidPassengerDOB: {
    getErrorKey: getAACheckoutErrorKey,
    handleError: (error) => {
      const message = getErrorMessage('errorInvalidPassengerDOB');
      showAACheckoutAlert(message, error);
    },
  },
  seatUnavailable: {
    getErrorKey: getAACheckoutErrorKey,
    handleError: (error) => {
      const message = getErrorMessage('seatUnavailable');
      showAACheckoutAlert(message, error);
    },
  },
  errorDuplicatePassportNumber: {
    getErrorKey: getAACheckoutErrorKey,
    handleError: (error) => {
      const message = getErrorMessage('errorDuplicatePassportNumber');
      showAACheckoutAlert(message, error);
    },
  },
  baggageUnavailable: {
    getErrorKey: getAACheckoutErrorKey,
    handleError: (error) => {
      const message = getErrorMessage('baggageUnavailable');
      showAACheckoutAlert(message, error);
    },
  },
  invalidNationality: {
    getErrorKey: getAACheckoutErrorKey,
    handleError: (error) => {
      const message = getErrorMessage('invalidNationality');
      showAACheckoutAlert(message, error);
    },
  },
  errorPerformingCheckout: {
    getErrorKey: getAACheckoutErrorKey,
    handleError: (error) => {
      const message = getFormattedErrorMessage(
        'errorPerformingCheckout',
        error,
      );

      showAACheckoutAlert(message, error);
    },
  },
  default: {
    getErrorKey: getAACheckoutErrorKey,
    handleError: (error) => {
      const message = getFormattedErrorMessage('_default', error);

      showAACheckoutAlert(message, error, {
        primaryHandleCallback: () => {
          navigateBackWMP();
        },
      });
    },
  },
};
