import i18n from '@/helper/i18n';
import { DEFAULT_NATIONALITY, DEFAULT_TRAVEL_DOC_TYPES } from '../consts';
import { travelDocIsAvailable } from './travel-docs';
import { paxTypes } from '@/helper/constants/aa-checkout';

const t = i18n.chain;

export const getGuests = (paxDetails, passengerDefinitions, tripsInfo) => {
  const guests: AACheckout.Passenger[] = [];
  const { airlineProfile } = tripsInfo;

  paxDetails.map((p) => {
    for (let index = 0; index < Number(p.count); index++) {
      const paxPassportDef = passengerDefinitions[p.paxType].documents.find(
        (d) => d.type === DEFAULT_TRAVEL_DOC_TYPES[0],
      );

      const paxIdDef = passengerDefinitions[p.paxType].documents.find(
        (d) => d.type === DEFAULT_TRAVEL_DOC_TYPES[1],
      );
      const supportedDocTypes = [];

      if (
        paxPassportDef &&
        travelDocIsAvailable(airlineProfile, paxPassportDef)
      ) {
        supportedDocTypes.push(paxPassportDef);
      }

      if (paxIdDef && travelDocIsAvailable(airlineProfile, paxIdDef)) {
        supportedDocTypes.push(paxIdDef);
      }

      const selectedDocType =
        supportedDocTypes.length > 0 ? supportedDocTypes[0]['type'] : '';

      const passengerType = {
        adult: 'adt',
        child: 'chd',
        infant: 'inft',
        ypta: 'ypta',
      };

      guests.push({
        paxType: p.paxType,
        subPaxType: p.paxType,
        seq: guests.length + 1,
        subSeq: index + 1,
        hasError: false,
        referenceId: `pax_${passengerType[p.paxType]}_${index}`,
        infantRef: '',
        supportedDocTypes,
        syncId: '',
        validateOnload: false,
        triggerBigpointsCalc: false,
        email: '',
        loyaltyId: '',
        loyaltyTier: '',
        formData: {
          givenName: '',
          familyName: '',
          nationality: DEFAULT_NATIONALITY,
          gender: 'Male',
          dateOfBirth: '',
          type: selectedDocType,
          docNumber: '',
          expiryDate: '',
          travellingWith: '',
          bigMemberId: '',
        },
      });
    }
  });

  guests
    .filter((g) => g.paxType === 'infant')
    .map((g, index) => {
      g.formData.travellingWith = `pax_adt_${index}`;
      guests.find(
        (a) => a.referenceId === g.formData.travellingWith,
      ).infantRef = g.referenceId;
    });

  return guests;
};

export const getGuestsLimit = (guests: AACheckout.Passenger[]) => {
  return {
    adult: guests.filter((g) => g.paxType === 'adult').length,
    child: guests.filter((g) => g.paxType === 'child').length,
    infant: guests.filter((g) => g.paxType === 'infant').length,
  };
};

export function getGuestDisplayName(g: AACheckout.Passenger) {
  return g.formData.familyName || g.formData.givenName
    ? `${g.formData.givenName} ${g.formData.familyName}`
    : `${t.flights.aaCheckout.guestDetailsForm.adult} ${g.subSeq}`;
}

const addYPTAToPaxDefinition = (paxObj, paxDefinition) => {
  if (!paxObj && !paxObj.documents) {
    return;
  }

  const yptaDoc = paxObj.documents.find((pax) => pax.type === paxTypes.ypta);

  if (!yptaDoc) {
    return;
  }

  paxDefinition[yptaDoc.type] = {
    min: yptaDoc.minAge,
    max: yptaDoc.maxAge,
    document: true,
    changePaxType: yptaDoc.changePaxType,
  };
};

export const getPaxDefinitions = (
  definitions: any,
): AACheckout.PaxDefinition => {
  const paxDefinition: AACheckout.PaxDefinition = {};

  Object.keys(definitions).forEach((paxKey) => {
    const pax = definitions[paxKey];
    paxDefinition[paxKey] = {
      min: pax.minAge,
      max: pax.maxAge,
      document:
        pax.documents && !!pax.documents.filter((doc) => doc.isRequired).length,
    };
    if (paxKey === paxTypes.adult) {
      addYPTAToPaxDefinition(pax, paxDefinition);
    }
  });
  return paxDefinition;
};

export const getYPTAAgeRange = (
  definitions: any,
): { min: number; max: number } => {
  const yptaDocument = definitions[paxTypes.adult]?.documents?.find(
    (d) => d.type === paxTypes.ypta,
  );
  if (yptaDocument) {
    return {
      min: yptaDocument.minAge,
      max: yptaDocument.maxAge,
    };
  } else {
    return null;
  }
};
