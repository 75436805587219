import React from 'react';
import { ROUTE_URL } from '@/helper/constants';
import { NoneLayout } from '@/components';

const Test1 = React.lazy(
  () => import(/* webpackChunkName: "demo-test1" */ '@/pages/demo/test1'),
);

export default [
  {
    path: `/demo/test1`,
    component: Test1,
    isAuth: false,
    layout: NoneLayout,
  },
];
