import i18n from '@/helper/i18n';
import {
  getTranslatedAmenities,
  getGuestRatingMessage,
  getDisplayAddress,
  getAgeRange,
} from './utils';

const { chain: t } = i18n;

export const transformHotelContentData = (
  content: any,
): Hotel.HotelContentInfo => {
  const description = [];

  if (content.description?.amenities) {
    description.push({
      id: 'amenities',
      title: t.hotel.common.amenities,
      desc: content.description?.amenities,
    });
  }
  if (content.description?.dining) {
    description.push({
      id: 'dining',
      title: t.hotel.common.dining,
      desc: content.description?.dining,
    });
  }
  if (content.description?.business_amenities) {
    description.push({
      id: 'business_amenities',
      title: t.hotel.common.businessAmenities,
      desc: content.description?.business_amenities,
    });
  }
  if (content.description?.rooms) {
    description.push({
      id: 'rooms',
      title: t.hotel.common.roomsDescription,
      desc: content.description?.rooms,
    });
  }
  if (content.description?.attractions) {
    description.push({
      id: 'attractions',
      title: t.hotel.common.attractions,
      desc: content.description?.attractions,
    });
  }
  if (content.description?.nearestAirports) {
    description.push({
      id: 'nearestAirports',
      title: t.hotel.common.nearestAirports,
      desc: content.description?.nearestAirports,
    });
  }
  if (content.description?.location) {
    description.push({
      id: 'location',
      title: t.hotel.common.location,
      desc: content.description?.location,
    });
  }

  const { age_policy: agePolicy } = content.policies;

  let childPolicies;

  if (agePolicy) {
    const { children_age_from, children_age_to, min_adult_age, min_guest_age } =
      agePolicy;

    childPolicies = [
      getAgeRange(min_guest_age, children_age_from),
      getAgeRange(children_age_from, children_age_to),
      getAgeRange(min_adult_age, null),
    ].map((range) => {
      if (range.minAge === 0 && range.maxAge === 0) return null;
      return range;
    });
  }

  return {
    id: content.id,
    hotelName: content.name,
    amenities: getTranslatedAmenities(content.amenities?.top_amenities),
    heroImage: content.gallery.images.find((m) => m.hero_image)?.links[
      '1000px'
    ],
    images: content.gallery.images.map((m) => {
      return {
        ...m,
        links: {
          big: m.links['1000px'],
          small: m.links['350px'],
        },
      };
    }),
    address: {
      city: content.address?.city,
      country: content.address?.country,
      countryCode: content.address?.country_code,
      line1: content.address?.line_1,
      line2: content.address?.line_2,
      postalCode: content.address?.postal_code,
      stateProvinceName: content.address?.state_province_name,
    },
    addressDisplay: getDisplayAddress(content.address),
    starRating: {
      stars: Number(content.star_rating.rating),
      text: t.hotel.hotelCard.stars,
    },
    reviews: {
      message: getGuestRatingMessage(Number(content.guest_rating.rating)),
      rating: Number(content.guest_rating.rating),
      reviewCount: content.guest_rating.count,
    },
    description: description,
    policies: {
      agePolicy,
      childPolicies,
      knowBeforeYouGo: content.policies.know_before_you_go,
      fees: content.fees,
      checkinDetails: {
        instructions: content.checkin_details?.instructions,
        specialInstructions: content.checkin_details?.special_instructions,
        time: content.checkin_details?.time,
      },
      checkoutDetails: content.checkout_details,
    },
  };
};
