import Device from '@/helper/device';

const routerContext = require.context('@/pages', true, /routes\.ts$/);
const routes = [];

routerContext.keys().forEach((route) => {
  const routerModule = routerContext(route).default;

  routerModule.forEach((sonRoute) => {
    if (Device.isMobileDevice && sonRoute.mobile) {
      sonRoute.component = sonRoute.mobile;
    }
  });

  routes.push(...routerModule);
});

export default { routes };
