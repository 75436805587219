import { action, observable, makeObservable, transaction, toJS } from 'mobx';
import { Log } from '@/helper/log';
import Storage from '@/helper/storage';
import { SSOAuth } from '@/api/common';
import {
  CURRENCY_DEFAULT,
  KEY_CURRENCY,
  KEY_LOCALE,
  LOCALE_CODE_DEFAULT,
} from '@/helper/constants';
import i18n, { LOCALE_MAPPING } from '@/helper/i18n';
import { getNavigatorLocale, getBase64Value } from '@/helper/util';

class UserStore {
  @observable user: User = null;
  @observable isLogin = false;
  @observable isValidated = false;
  @observable locale = '';
  @observable localeI18n = '';
  @observable currency = '';
  _tokenInfo: TokensExternalAuth = null;

  constructor() {
    makeObservable(this);
    this.init();
  }

  @action
  initLocale = () => {
    // read for setting in localstorage
    let locale: string;
    const v = Storage.getItem(KEY_LOCALE, -1);
    if (v) {
      locale = v;
    }
    // read for broswer
    else {
      locale = getNavigatorLocale();
    }

    this.setLocale(locale, false);
  };

  @action
  setLocale = (locale: string, iscache = true) => {
    //check the local if it was supported by header component
    let localeI18n = LOCALE_MAPPING[locale];
    if (!localeI18n) {
      locale = LOCALE_CODE_DEFAULT;
      localeI18n = LOCALE_MAPPING[locale];
    }
    this.locale = locale;
    this.localeI18n = localeI18n;

    iscache && Storage.setItem(KEY_LOCALE, this.locale);

    //set i18n locale
    i18n.locale(this.localeI18n);
  };

  @action
  initCurrency = () => {
    const currency = Storage.getItem(KEY_CURRENCY, -1);
    this.currency = currency ?? CURRENCY_DEFAULT;
  };

  @action
  setCurrency = async (currency: string) => {
    Storage.setItem(KEY_CURRENCY, currency);
    this.currency = currency;
  };

  @action
  init = () => {
    this.initLocale();
    this.initCurrency();
  };

  @action
  setUserInfoWithToken = async (tokenInfo: TokensExternalAuth) => {
    try {
      if (!tokenInfo.refreshToken) {
        return this.setLogout();
      }

      //flush token
      this._tokenInfo = tokenInfo;
      await this.getFlushedTokenInfo();

      //get User info
      const userInfo = await SSOAuth.getUserInfo(this._tokenInfo);
      this.setLogin(userInfo);

      //print log only when in  development env
      Log.debug(
        `....setLoginFromSearchParams...this.user...${JSON.stringify(
          this.user,
        )}`,
      );
    } catch (e) {
      console.error(e);
      this.setLogout();
    }
  };

  @action
  setLoginFromSearchParams = async (searchParams: URLSearchParams) => {
    const param = searchParams.get('externalAuth');
    const tokenInfo: TokensExternalAuth = getBase64Value(param);
    await this.setUserInfoWithToken(tokenInfo);
  };

  @action
  getFlushedTokenInfo = async () => {
    if (!this._tokenInfo) {
      return this._tokenInfo;
    }

    //flush token
    const accessToken = await SSOAuth.refreshToken(
      this._tokenInfo.refreshToken,
    );
    this._tokenInfo = { ...this._tokenInfo, accessToken };

    return this._tokenInfo;
  };

  @action
  setLogin = (user: User) => {
    try {
      const userInfo = user;
      transaction(() => {
        this.user = userInfo;
        this.isLogin = true;
        this.isValidated = true;
      });
    } catch (e) {
      console.error(e);
    }
  };

  @action
  setLoginFromHeaderCmp = async () => {
    try {
      const aaWidget = window['aaWidget'];
      const userInfo = await aaWidget.getUser();
      this.setLogin(userInfo);
    } catch (e) {
      console.error(e);
      this.setLogout();
    }
  };

  @action
  setLogout = () => {
    transaction(() => {
      this.user = undefined;
      this.isLogin = false;
      this.isValidated = true;
    });
  };
}

export default new UserStore();
