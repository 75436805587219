import http from './base/http';
import Config from '@/config';
import { CommonApi } from '@/api';

interface DataResponse {
  data: {
    ssoInfo?: {
      accessToken: string;
      refreshToken: string;
      userId: string;
    };
    tripsInfo?: {
      tripIds: string[];
      airlineProfile: string;
      sId?: string;
    };
    searchInfo?: SearchInfo;
    openId?: string;
    bgKey?: string;
  };
  token: string;
}

interface ApiResponse {
  data: DataResponse;
  msg: string;
  code: number;
}

interface SearchInfo {
  locationId: string;
  locationName: string;
  totalRooms: string;
  checkin: string;
  checkout: string;
  children: string;
  adults: string;
  totalAdults: string;
  totalChildren: string;
  externalAuth: string;
  wmpurl: string;
}

// get data by UUID
export const getDataByUUID = async (uuid: string): Promise<DataResponse> => {
  try {
    const url = `${Config.H5_SERVICE.URL}/api/data/get?uuid=${uuid}`;
    const response = await http.get<ApiResponse>(url);

    if (response.code === 10000 && response.data) {
      //(window as any).isLauchService401Check = true;
      CommonApi.GCPServiceAuth.setApiKey(response.data.token);
      return response.data;
    } else {
      throw new Error(response.msg || 'Unknown error');
    }
  } catch (error) {
    console.error('Failed to get data by UUID:', error);
    throw error;
  }
};
