import {
  countNights,
  mapBookingLinks,
  getAmenityTranslationKey,
} from './utils';
import { parseCancellationDate, filterPenalties } from './cancellation';
import {
  AmenityIconsMap,
  AMENITY_ICON_TYPE,
  CHARGE_TYPE,
  PAYMENT_METHOD,
  DISCOUNT_METHOD,
} from './constants';
import {
  BOOKING_PAY_AT_HOTEL_MAPPING,
  BOOKING_PAY_LATER_MAPPING,
  BOOKING_MAPPING,
  DEFAULT_LAYOUT_STATUS,
} from './booking';

export const transformHotelOrder = (order) => {
  const {
    order_id: orderId,
    order_number: orderNumber,
    hotel_id: hotelId,
    status,
    checkin,
    checkout,
    total_price: totalPrice,
    payable_at_site,
    rooms,
    cancellation_details: cancellationDetails = {},
    cancellation_policy = { refund_amount: cancellationDetails.amount },
    serverTime,
    creation_date_time: creationDateTime,
    billing_contact: billingContact = {},
    links,
    booking_ref_id: bookingId,
    total_occupancy: totalOccupancy,
    payments,
    pay_at_hotel: isPayAtHotel,
    refundable: isFreeCancellation,
    rate_amenities: rateAmenities,
    buy_now_pay_later: isPayLater,
    buy_now_pay_later_date: payLaterDate,
  } = order;
  const [room] = rooms || [];
  const { room_name: roomName } = room || {};
  const totalRooms = (rooms && rooms.length) || 0;
  const totalNights = countNights(checkin, checkout);

  const {
    total_inclusive_price: totalInclusivePrice,
    total_exclusive_price: totalExclusivePrice,
    total_tax: totalTax,
    total_fee: totalFee,
    tax_breakdown,
    fee_breakdown,
    total_discount: totalDiscount,
    discount_breakdown = [],
  } = totalPrice || {};

  const hasValidAmount = (price) => {
    return price && price.amount && Number(price.amount.value) >= 0;
  };

  const reducePrices = (prices) => {
    return prices ? prices.filter(hasValidAmount) : [];
  };

  const isTaxIncluded = totalTax ? Number(totalTax.value) === 0 : true;
  const isFeeIncluded = totalFee ? Number(totalFee.value) === 0 : true;

  const taxBreakdown = !isTaxIncluded ? reducePrices(tax_breakdown) : [];
  const feeBreakdown = !isFeeIncluded ? reducePrices(fee_breakdown) : [];
  const payableAtSite = reducePrices(payable_at_site);

  const hotelChargedPropertyFee =
    payable_at_site &&
    payable_at_site.find((item) => item.type === CHARGE_TYPE.MANDATORY_FEE);

  const isTaxAndFeeCombined = totalTax
    ? Number(totalTax.value) > 0 &&
      taxBreakdown.find((item) => item.type === CHARGE_TYPE.TAXES_AND_FEES)
    : false;
  const { cancellation_date_time } = cancellationDetails;
  const cancellationDate = parseCancellationDate(cancellation_date_time);
  const { penalties = [] } = cancellation_policy;
  const cancelPenalties = filterPenalties(penalties, checkout, serverTime);
  const { last_name, email } = billingContact;
  const paymentDetails = payments || [];
  const loyaltyOrPromoDiscountPayment = paymentDetails.find(
    (e) =>
      e.payment_method === PAYMENT_METHOD.LOYALTY ||
      e.payment_method === PAYMENT_METHOD.DISCOUNT,
  );
  const totalToBePaid = paymentDetails.find(
    (e) =>
      e.payment_method === PAYMENT_METHOD.CREDIT_CARD ||
      e.payment_method === PAYMENT_METHOD.BNPL ||
      e.payment_method === PAYMENT_METHOD.DIRECT_DEBIT,
  );
  let cancellationRefundAmount = cancellationDetails.refund_details?.find(
    (e) =>
      e.payment_method === PAYMENT_METHOD.CREDIT_CARD ||
      e.payment_method === PAYMENT_METHOD.DIRECT_DEBIT,
  );
  const amountRefundedDefault = '0.0';
  if (!cancellationRefundAmount) {
    cancellationRefundAmount = {
      amount: {
        currency: cancellationDetails.amount?.currency,
        value: amountRefundedDefault,
      },
    };
  }

  const discountBreakup = discount_breakdown;
  const lobDisount = discountBreakup
    ? discountBreakup.find(
        (e) => e.source && e.source === DISCOUNT_METHOD.LOB_DISCOUNT,
      )
    : null;
  const cardDiscount = discountBreakup
    ? discountBreakup.find(
        (e) => e.source && e.source === DISCOUNT_METHOD.CARD_DISCOUNT,
      )
    : null;

  const layoutsBooking = isPayAtHotel
    ? BOOKING_PAY_AT_HOTEL_MAPPING
    : isPayLater
    ? BOOKING_PAY_LATER_MAPPING
    : BOOKING_MAPPING;
  const layout =
    layoutsBooking[status] || layoutsBooking[DEFAULT_LAYOUT_STATUS];

  return {
    cancellation: {
      rate: cancellationRefundAmount.amount,
    },
    payment: {
      totalExclusivePrice,
      totalInclusivePrice,
      totalTax,
      totalFee,
      breakdownPrices: [...taxBreakdown, ...feeBreakdown],
      payableAtSite,
      isTaxAndFeeCombined,
      isTaxIncluded,
      isFeeIncluded,
      hotelChargedPropertyFee,
      loyaltyOrPromoDiscountPayment,
      totalToBePaid,
      totalDiscount,
      lobDisount,
      cardDiscount,
    },
    isFetching: false,
    isFailed: false,
    orderId,
    orderNumber,
    hotelId,
    status,
    checkin,
    checkout,
    roomName,
    totalRooms,
    totalNights,
    totalOccupancy,
    cancellation_policy,
    cancelPenalties,
    cancellationDate,
    layout: layout || {},
    billingContactLastName: last_name,
    billingContactEmail: email,
    creationDateTime: parseCancellationDate(creationDateTime),
    links: mapBookingLinks(links),
    bookingId,
    isPayAtHotel,
    isFreeCancellation,
    amenities: rateAmenities
      ? rateAmenities.map((amenity) => {
          return {
            ...amenity,
            displayId: `rate${AmenityIconsMap.split.level}${
              AmenityIconsMap.rate[amenity.id]
            }`,
            translationKey: getAmenityTranslationKey(
              AMENITY_ICON_TYPE.RATE,
              amenity.id,
            ),
          };
        })
      : [],
    paymentDetails,
    isPayLater,
    payLaterDate,
  };
};
