import { Select } from '@airasia/phoenix-components/components/Select';
import { action, observable, makeObservable } from 'mobx';
import { Log } from '@/helper/log';
import { CommonApi, DemoApi } from '@/api';
import { webStore } from '@/store/base';
import AStore from './a';
import BStore from './b';
import { json } from 'stream/consumers';

const BAGGAGE_DATA = [
  [
    {
      baggageHeadingColor: 'secondaryBlue',
      baggageList: [
        {
          amount: 0,
          currency: 'MYR',
          id: 'CBWT',
          isIncluded: true,
          isPreSelected: false,
          title: [
            {
              dimension: '',
              weight: '7 kg',
            },
          ],
        },
        {
          amount: 97.02,
          currency: 'MYR',
          id: 'CBWT',
          isIncluded: false,
          isPreSelected: false,
          title: [
            {
              dimension: '',
              weight: '7 kg',
            },
          ],
        },
      ],
      baggageType: 'handCarry',
      baggageTypeColor: 'secondaryLight',
      showToolTip: true,
      toolTipColor: 'secondaryBlue',
      toolTipContent: [
        {
          content: [
            '1 piece only.',
            'Dimension must be within 56 cm (H) x 36 cm (W) x 23 cm (D).',
            'Must fit in the overhead compartment in the aircraft cabin.',
          ],
          type: 'list',
        },
        {
          ctaLink:
            'https://support.airasia.com/s/article/What-are-the-rules-for-cabin-baggage-on-board?language=en_GB',
          ctaText: 'Read More',
          type: 'link',
        },
      ],
      toolTipTitle: 'Carry-on Baggage',
    },
    {
      baggageHeadingColor: 'blueMedium',
      baggageList: [
        {
          amount: 426.8,
          currency: 'MYR',
          id: 'PBAA',
          isIncluded: false,
          isPreSelected: false,
          title: [
            {
              dimension: '',
              weight: '15 kg',
            },
          ],
        },
        {
          amount: 853.6,
          currency: 'MYR',
          id: 'PBAB',
          isIncluded: false,
          isPreSelected: true,
          title: [
            {
              dimension: '',
              weight: '20 kg',
            },
            {
              dimension: '',
              weight: '20 kg',
            },
          ],
        },
      ],
      baggageType: 'checkedBaggage',
      baggageTypeColor: 'blueActive',
      showToolTip: true,
      toolTipColor: 'blueMedium',
      toolTipContent: [
        {
          content: [
            'Each piece must weigh below 20 kg.',
            'Fees are higher at the airport compared to pre-booking now.',
          ],
          type: 'list',
        },
        {
          ctaLink:
            'https://support.airasia.com/s/article/What-I-need-to-know-about-checked-baggage?language=en_GB',
          ctaText: 'Read More',
          type: 'link',
        },
      ],
      toolTipTitle: 'Checked Baggage',
    },
    {
      baggageHeadingColor: 'yellow',
      baggageList: [
        {
          amount: 426.8,
          currency: 'MYR',
          id: 'PSEA',
          isIncluded: false,
          isPreSelected: false,
          title: [
            {
              dimension: '',
              weight: '15 kg',
            },
          ],
        },
        {
          amount: 853.6,
          currency: 'MYR',
          id: 'PSEB',
          isIncluded: false,
          isPreSelected: false,
          title: [
            {
              dimension: '',
              weight: '20 kg',
            },
          ],
        },
        {
          amount: 953.6,
          currency: 'MYR',
          id: 'PSEC',
          isIncluded: false,
          isPreSelected: true,
          title: [
            {
              dimension: '',
              weight: '25 kg',
            },
          ],
        },
        {
          amount: 1053.6,
          currency: 'MYR',
          id: 'PSED',
          isIncluded: false,
          isPreSelected: false,
          title: [
            {
              dimension: '',
              weight: '30 kg',
            },
          ],
        },
        {
          amount: 1153.62,
          currency: 'MYR',
          id: 'PSEF',
          isIncluded: false,
          isPreSelected: false,
          title: [
            {
              dimension: '',
              weight: '40 kg',
            },
          ],
        },
      ],
      baggageType: 'sportsEquip',
      baggageTypeColor: 'yellowLight',
      showToolTip: true,
      toolTipColor: '',
      toolTipContent: [
        {
          content: [
            {
              icon: 'SportsGolf',
              translationKey: 'golf',
            },
            {
              icon: 'SportsSki',
              translationKey: 'skisSnowboard',
            },
            {
              icon: 'SportsSurfing',
              translationKey: 'surfBoard',
            },
            {
              icon: 'SportsCricket',
              translationKey: 'cricket',
            },
            {
              icon: 'SportsBicycle',
              translationKey: 'bicycle',
            },
            {
              icon: 'SportsDiving',
              translationKey: 'divingGear',
            },
          ],
          type: 'icons',
        },
        {
          content: [
            'You can check in any other sport equipment not stated here as part of your checked baggage allowance.',
          ],
          type: 'text',
        },
        {
          ctaLink:
            'https://support.airasia.com/s/article/Flying-with-sports-equipments?language=en_GB',
          ctaText: 'Read More',
          type: 'link',
        },
      ],
      toolTipTitle: 'Sports Equipment includes:',
    },
  ],
];

export default class DemoStore implements PageStore {
  private CLASS_NAME = 'DemoStore';
  aStore: AStore = new AStore();
  bStore: BStore = new BStore();

  @observable testName = '';
  @observable apiKey = '';
  @observable xcsTraceId = '';
  @observable selectedBaggage = null;
  @observable baggageData = [];
  @observable isShow = false;

  constructor() {
    makeObservable(this);
  }

  getName = () => {};

  @action
  async onLoad() {
    //test
    webStore.paramManager.setParams({
      openId: 'oSMlo5GRQFHP_z0aPVkq_ELmPGiU',
      bgKey: 'MThhZWI4MjUtYzEyZi00Mzc3LWE1NDAtY2QxYjFjM2VjN2Yy',
    });

    console.info('...in DemoStore onLoad....');

    try {
      const testObj = null;
      testObj.test();
    } catch (error) {
      //DATA ERROR
      Log.errorData(error, this.CLASS_NAME, 'fetchUserData', {});
      //throw error; // Re-throw the error to be handled by the component if needed
    }

    try {
      const generateObj = null;
      generateObj.test();
    } catch (error) {
      //GENERAL ERROR
      Log.errorGeneral(error, { a: 1 });
      //throw error; // Re-throw the error to be handled by the component if needed
    }

    //测试全局错误
    // 测试请求错误
    const triggerFetchError = () => {
      fetch('https://www.aaaaaahhhh.com').then((response) => response.json());
    };
    triggerFetchError();

    // 测试 unhandledrejection - async/await
    const triggerAsyncAwaitError = async () => {
      const result = await Promise.reject(
        new Error('test async/await operation failed'),
      );
    };
    triggerAsyncAwaitError();

    //测试 syntax- 语法错误
    const triggerSyntaxError = () => {
      console.info('...in triggerSyntaxError....');
      throw new Error('test syntax error');
    };
    triggerSyntaxError();

    //const apiKey = await CommonApi.SSOAuth.getApiKey();
    //this.apiKey = apiKey;

    // await this.searchFlight();

    // const a = 1;
    // const b = 0;
    // if (a>b){
    //   throw 'aaaaa'
    // }

    this.setBaggageData();
  }

  @action
  setBaggageData = async () => {
    this.baggageData = BAGGAGE_DATA;
    this.isShow = true;

    console.info('...in setBaggageData..isShow....', this.isShow);
  };

  @action
  searchFlight = async () => {
    const result = await DemoApi.testZodiacSearch();
    this.xcsTraceId = result.xcsTraceId;
  };

  @action
  onUnload = () => {};

  get selectBaggage() {
    console.info('...selectBaggage this...', this);
    console.info('...selectBaggage this...', this.selectedBaggage);
    return JSON.parse(JSON.stringify(this.selectedBaggage));
  }

  get aStoreData() {
    return this.aStore.someData;
  }

  @action
  handleBaggage = (data) => {
    console.info('....handleBaggage data...', JSON.stringify(data));
    this.selectedBaggage = data;
  };
}
