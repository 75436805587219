import React from 'react';
import { observer } from 'mobx-react';
import ActionPopup from '@airasia/phoenix-widgets/widgets/ActionPopup';
import { globalAlertStore } from '@/store/common';
import { PhoneixConst } from '@/helper/constants';
import './index.scss';

const GlobalAlert: React.FC = observer(() => {
  const { isVisible, translation, headerImage, primaryHandleClick } =
    globalAlertStore;

  if (!isVisible) {
    return null;
  }

  return (
    <div className={`global-alert-component`}>
      <ActionPopup
        openModal={isVisible}
        headerImage={headerImage}
        viewPort={PhoneixConst.DEVICE_TYPE.MOBILE}
        primaryHandleClick={primaryHandleClick}
        translation={translation}
      />
    </div>
  );
});

export default GlobalAlert;
