import Breadcrumb from '@airasia/phoenix-widgets/widgets/Breadcrumb';

import './index.scss';

interface IProps {
  [key: string]: any;
}

function Index(props: IProps) {
  return (
    <div className="breadcrumb">
      <Breadcrumb
        accessible
        activeColor="greyDarker"
        disabledColor="greyDark"
        fontSize="XSmall"
        hasBorderBottom={false}
        hoverColor=""
        hoverUnderlined={false}
        isClickable
        theme="AIRASIA_CLASSIC"
        {...props}
      />
    </div>
  );
}

export default Index;
