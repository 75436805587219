export default {
  hotel: {
    common: {
      all: '全部',
      'guestRating.3.0': '好！',
      duty_travel_rate: 'airasia Negotiated Rate',
      infant: '婴儿',
      rooms: [
        '{{smartCount}} 房间',
        {
          smartCount: 0,
        },
      ],
      getHotelVoucher: '获取酒店代金券',
      children: [
        '{{smartCount}} 孩子',
        {
          smartCount: 0,
        },
      ],
      showDetails: '显示详情',
      'bigPoints.redeem.text': '使用%{可用_余额} BIG积分兑换',
      pricePerRoom: '每房每晚',
      send: '发送',
      dedicatedForCovidHealthWorkers: '仅支持健康工作者预订及办理入住',
      to: '飞往',
      location: '位置',
      policy: '加床政策',
      hotelDescription: '酒店介绍',
      ourTopPicksForHotel: '热选酒店',
      'payLater.bigPointNoteMsg':
        'Big points for this booking will get credited to your account within 90 days.',
      currencyNotSupported:
        '因为我们无法支持您的选择，所示价格已变更为%{currency}。',
      knowBeforeYouGo: '继续前须知',
      'bigpointsTooltip.value': 'Total value: ',
      promoCodeTryAgain: '请重新尝试',
      uniquePromoCodeFailure: '促销代码无效。请尝试一个新的促销代码。',
      'bigPoints.redeem.success':
        '成功！我们已根据您的可用BIG积分更新了每家酒店的价格。',
      uniquePromoCodeRedeemed: '促销代码之前已被使用。请尝试一个新的促销代码。',
      expiredPopupMessage:
        "The check-in date you're searching for has passed. We've updated the search result to the latest dates.",
      'payLater.payAbleOn': 'payable on %{date}',
      imagesFrom: '图片来源 %{hotelName}',
      selectedFlight: '为您选择的航班',
      showMore: '显示更多',
      singularAdult: '%{smart_count} 成人',
      specialInstructions: '特别说明',
      mandatory: '强制',
      selectHotel: '请选择您的酒店',
      manageMyBooking: '管理我的预订',
      hotelBigpayCardPromotion: '使用您的BigPay卡獲取額外的大積分',
      checkinIntroductions: '办理入住须知',
      close: '关闭',
      'guestRating.4.0': '非常好！',
      review: [
        '{{count}} 评价',
        {
          count: 0,
        },
      ],
      reviews: [
        '{{count}} 评价',
        {
          count: 0,
        },
      ],
      'selectedStickyFlight.title': '选择的航班',
      languageNotSupported: '我们无法支持您选择的语言',
      checkOut: '退房',
      away: 'away',
      earnBigPointTemplateForLoggedInOld:
        '赚取高达%{multiplier_template}%{point_template} airasia 积分',
      earnBigPointTemplateForLoggedIn: [
        '赚取高达{{multiplier_template}}{{point_template}} airasia 积分',
        {
          multiplier_template: '',
          point_template: '',
        },
      ],
      bigPointMultiplierBanner:
        '今日预订可获得%{multiplier}倍airasia积分。快点，促销即将结束！',
      priceRange: '%{currency} %{min} - %{currency} %{max}',
      changeDepartingFlight: 'Change departing flight',
      bigMemberID: 'airasia会员ID',
      'bigpointsTooltip.balance': 'BIG Points balance: ',
      'selectedStickyFlight.showFlights': '显示详情',
      roomsDescription: '房间',
      getItinerary: '获取行程单',
      specialRequests: '特殊要求',
      optional: '选填',
      'payNow.bigPointNoteMsg':
        '我们会在您办理退房手续后的15日内将BIG积分发送至您的账户。',
      search: '搜寻',
      stillNotSure: 'Still not sure?',
      plus: '+',
      roomIndex: [
        '房间 {{index}}',
        {
          index: 0,
        },
      ],
      promoCodeFailure: '抱歉!您使用的促销代码%{promoCode}无效或不适用于此预订',
      attractions: '旅游胜地',
      guests: '%{smart_count} 旅客 |||| %{smart_count} 旅客',
      businessAmenities: '商务便利设施',
      fees: '费用',
      guestContactDetailsLabel: '旅客及联系信息',
      showLess: '精简显示',
      'selectedStickyFlight.hideFlights': '隐藏详情',
      'payAtHotel.bigPointNoteMsg':
        '我们会在您办理退房手续后的90日内将BIG积分发送至您的账户。',
      'bigpointsTooltip.value.web': '可用余额：',
      from: 'From',
      destination: '目的地',
      amenities: '便利设施',
      hotelLabel: '酒店',
      earnBigPointTemplate:
        '赚取%{multiplier_template}%{point_template} airasia 积分',
      changeFlight: '变更航班',
      otherHotels: '其他值得探索的酒店',
      earnBigPointForLoggedIn: '赚取',
      adult: '成人',
      'bigpointsTooltip.title.web': '您的BIG积分总览',
      child: '孩子',
      'bigpointsTooltip.note': '请知悉：可在账户查看BIG积分余额。',
      cheapest: '最超值',
      bigPoint: 'airasia积分 |||| airasia积分',
      customerSupportLabel: '客户支持',
      promoCodeSuccess: '耶! 您已成功添加促销代码。',
      earnBigPoint: '赚取高达',
      infants: '%{smart_count} 婴儿 |||| %{smart_count} 婴儿',
      'bigpointsTooltip.title': 'Available BIG points',
      'bigpointsTooltip.info':
        '酒店预订最多可使用%{usable_percent}% BIG积分，将用于抵扣预订总金额。',
      adults: '%{smart_count} 成人 |||| %{smart_count} 成人',
      'guestRating.4.5': '太棒了！',
      nearestAirports: '最近的机场',
      minimumAge: '最低年龄',
      nights: [
        '{{smartCount}} 夜',
        {
          smartCount: 0,
        },
      ],
      hotelDetails: '酒店信息',
      bigPointValueTemplate: '%{point}',
      singularChild: '%{smart_count} 孩子',
      propertyTypes: '物业类型',
      dining: '进餐',
      recommended: '推荐',
      pricePerNight: '每夜费用',
      singularInfant: '%{smart_count} 婴儿',
      guestsAge: '客人年龄',
      currencies: '货币',
      hotelPolicies: '酒店政策',
      currentDepartingFlight: 'Current Departing Flight',
      asterisk: '*',
      bigPointMultiplierTemplate: '%{multiplier}x',
      uniquePromoCodeSuccess: '促销代码已应用于您的预订。',
      roomAndGuest: '房间及客人',
      selectReturnFlight: 'Select return flight',
      'unlimitedDeal.regularFareLabel': '政策费率',
      bookingNumber: '预订编号',
      dutyTravelRate: 'airasia Negotiated Rate',
      singularRoom: '%{smart_count} 房间',
      hideDetails: '隐藏详情',
      checkIn: '入住',
      'unlimitedDeal.unlimitedLabel': 'Unlimited',
    },
    error: {
      'hotelNotAvailableAnyMore.subtitle': '查找另一家酒店吧！',
      description: '我们目前遇到了一些技术问题。',
      title: '抱歉，出现问题',
      selectNewFlight: '选择新航班',
      'priceChanged.title': '抱歉，价格已变化。',
      'majorApiFail.subtitle': '请重新搜索！更划算优惠等着您哦！',
      'hotelNotAvailableAnyMore.title': '抱歉!酒店房间已售罄。',
      'flightNotAvailableAnyMore.subtitle': '让我们为您查找其他合适航班。',
      'flightNotAvailableAnyMore.title': '抱歉!航班座位已售罄。',
      selectNewRoom: '选择新房间',
      continue: '继续',
      resourceNotFound:
        '糟糕！本页面部分内容不支持您选择的语言。我们已切换回英语。',
      'majorApiFail.title': '糟糕！出现问题！',
      searchAgain: '继续查询',
      'priceChanged.subtitle': '我们已为您找到下一个最超值的价格。',
      buttonTitle: '返回',
      selectNewCombo: '选择新套餐',
    },
    agePolicy: {
      'ageRange.1': [
        '婴儿{{minAge}}-至{{maxAge}}岁',
        {
          minAge: '',
          maxAge: '',
        },
      ],
      'ageRange.2': [
        '儿童{{minAge}}-至{{maxAge}}岁',
        {
          minAge: '',
          maxAge: '',
        },
      ],
      'ageRange.3': [
        '客人：{{minAge}}岁及以上',
        {
          minAge: '',
          maxAge: '',
        },
      ],
      'description.2.childrenStayFree': '如果您需要加床，则需支付额外费用。',
      'title.3': '',
      'title.2.childrenStayFree': '使用现有的床铺免费入住',
      'title.2': '或需要加床',
      'title.1': '使用现有的床铺免费入住',
      'description.1': '可应要求提供婴儿床',
      'description.3': '视为成年人',
      'description.2': '请与酒店联系以了解更多信息。酒店可能收取额外费用。',
      'childrenAndExtraBeds.content':
        '酒店欢迎儿童入住。加床选项取决于您选择的房间。请查看客房容客量以了解更多详情',
      guestsAgeAndExtraBed: '客人年龄和加床政策',
      'childrenAndExtraBeds.label': '儿童和加床',
    },
    amenityFilter: {
      filter_seemore: '查看更多',
      filter_22: '接待服务',
      filter_20: '商务中心',
      filter_21: '花园',
      filter_9: '餐厅',
      filter_8: '无烟酒店',
      filter_7: '吸烟区',
      filter_6: '机场中转',
      filter_5: '私人海滩',
      filter_4: '水疗',
      filter_3: '健身房',
      filter_2: '游泳池',
      filter_1: '免费Wi-Fi',
      filter_seeless: '显示更少',
      filter_19: '行李存放',
      filter_18: '干洗/洗衣服务',
      filter_13: '网球场',
      filter_12: '酒吧',
      filter_11: '小餐馆',
      filter_10: '泳池酒吧',
      filter_17: '24小时前台',
      filter_16: '免费自助停车',
      filter_15: '夜店',
      filter_14: '桑拿',
    },
    tags: {
      penangtourism_sale: '槟城旅游特卖',
      songkransupersplash: 'Songkran Super Splash',
      quarantine_hotel: '检疫酒店',
      aa_flash_sale: '限时特惠',
      duty_travel_rate: 'airasia Negotiated Rate',
      instant_confirmation: '当下确认',
      instant_confirmation_hover: [
        '于{{minutes}} 分钟内收到预订确认。',
        { minutes: '' },
      ],
      the_match: 'THEMATCH',
      airasia_anniversary: '亚航20岁生日特卖',
      member_rate: "Member's Exclusive",
      supertravel: 'SUPERTRAVEL',
      x_sell_rate: '旅客独家优惠',
      special_promotion: 'SUPER VALUE',
      rooms_left: ['仅剩{{smart_count}}个房间', { smart_count: 0 }],
      sabahtourism_sale: '沙巴旅游特卖',
      momo_mcd: "airasia x MoMo's x McD",
      vtl_campaign: 'VTL活动',
      certified_super: 'CERTIFIED SUPER',
      workcation_campaign: '工作活动',
      ayokembali: 'AYOKEMBALI',
      Extra10: '10%额外折扣',
      best_price: 'BEST PRICE GUARANTEED',
      free_cancellation: '免费取消',
      recommended: '推荐',
      bestdeal: 'BEST DEAL',
      pgt_sales: 'PGT特惠',
      flyer_discount_hover: '亚航旅客独家折扣',
      pay_later: '支持稍后支付',
      flash_sale: 'FLASH DEAL',
      supersale: '10.10 SUPER SALE',
      pay_at_hotel_description: '马上预订酒店，办理入住时支付！',
      crazy_price: '限时特惠',
      superhourdeals: 'SUPER HOUR DEALS',
      superhotelpartner: 'Super Hotel Partner',
      pay_at_hotel: '在酒店支付',
      crazy_price_promotion: 'Wednesday specials',
      ramadhanspecial: 'Ramadhan Special',
      dealoftheday: 'DEAL OF THE DAY',
      sarawaktourism_sale: '砂拉越旅游特卖',
      superpayday: 'SUPERPAYDAY',
      wednesday_deal: 'WEDNESDAY FLASH DEAL',
      free_room_upgrade: '免费房间升级',
      eidsale: 'EID SALE',
      flyer_discount: '飞行折扣',
      twelve_twelve: '亚航独家',
      'everyday30%off': 'Everyday 30% OFF',
      dealoftheweek: 'DEAL OF THE WEEK',
      best_price_guaranteed: '亚航独家',
      min_night: '至少 2 晚',
    },
    hotelCard: {
      'noHotelFound.description': '查找另一个日期或地点，好吗？',
      flightOneWayHotel: '机票+酒店',
      perGuestText: '每位旅客',
      poi: [
        '{{location}}出发{{poi}}公里路程',
        {
          location: '',
          poi: '',
        },
      ],
      snapOMSOnewayPrice: [
        '包括航班及{{smart_count}}夜酒店住宿',
        {
          smart_count: 0,
        },
      ],
      bundlePrice: '机票+酒店套餐价格',
      total: '支付总额',
      review: 'review',
      snapOMSPriceText: [
        '包括往返航班及{{smart_count}}夜酒店住宿',
        {
          smart_count: 0,
        },
      ],
      priceOff: [
        '{{percent}}折扣',
        {
          percent: '',
        },
      ],
      priceDiscount: [
        '节省 {{currency}} {{amount}}',
        { currency: '', amount: '' },
      ],
      free_cancellation: 'Free hotel cancellation',
      stars: '星级',
      'Deal.OFTHE.Day': '今日特卖',
      snapGuestText: [
        '{{smart_count}} 位客人',
        {
          smart_count: 0,
        },
      ],
      priceText: '每间客房每晚',
      Timer: '促销将于(time)结束，马上行动！',
      comboSavings: '組合儲蓄',
      availableFromAA: '亚航提供',
      fromOtherAirlines: '其他航空公司提供',
      'Expires.in': '失效日期',
      flightPlusHotel: '机票+酒店',
      perGuestNight: [
        '每人{{smart_count}} 夜',
        {
          smart_count: 0,
        },
      ],
      reviews: ' 评价',
      'noHotelFound.title': '抱歉，无可选酒店。',
      today: 'TODAY',
      'noHotelFound.buttonTitle': '编辑查询',
      snapPriceText: [
        '包括航班 & {{smart_count}} 夜酒店住宿',
        {
          smart_count: 0,
        },
      ],
    },
    hotelCouponDiscount: {
      'momo_mcd.listing.Description': '特别促销',
      'eidsale.payment.title': 'EID SALE',
      'GOLD.payment.title': 'Airasia Gold',
      'BLACK.listing.Description': 'Airasia Black Discount Description',
      'pertiwi_10.listing.Description': '为慈善而留下 - 10% 给 Pertiwi',
      'GOLD.listing.title': 'Airasia Gold',
      'super_travel_sale.listing.Description': 'Super Travel Sale',
      'ramadhanspecial.listing.Description': 'Ramadhan Special',
      'everyday30%off.payment.title': 'Everyday 30% OFF',
      'superhourdeals.listing.title': 'SUPER HOUR DEALS',
      'momo_mcd.listing.title': "airasia x MoMo's x McD",
      'songkransupersplash.payment.title': 'Songkran Super Splash',
      'everyday30%off.listing.title': 'Everyday 30% OFF',
      'RED.listing.title': 'Airasia RED',
      'RED.payment.title': 'Airasia RED',
      'penangtourism_sale.listing.Description': '槟城旅游特卖',
      'bestdeal.listing.Description': 'BEST DEAL',
      'PLATINUM.payment.Description': 'Airasia Platinum Discount Description',
      'superhourdeals.listing.Description': '超級獨家1小時優惠',
      'supertravel.listing.title': 'SUPERTRAVEL',
      'superpayday.payment.title': 'SUPERPAYDAY',
      'ramadhanspecial.listing.title': 'Ramadhan Special',
      'superhotelpartner.listing.Description': 'Super Hotel Partner',
      'best_price.listing.Description': 'BEST PRICE GUARANTEED',
      'Extra5.payment.Description': '恭喜！这家酒店拥有5%的额外折扣！',
      'test_SA.listing.Description': 'testSA_taggingdescription',
      'superhotelpartner.listing.title': 'Super Hotel Partner',
      'sabahtourism_sale.listing.Description': '沙巴旅游特卖',
      'min_night.listing.title': '至少 2 晚',
      'the_match.listing.Description': 'THEMATCH',
      'flash_sale.listing.Description': '限时优惠！ 只在airasia Super App上',
      'RED.payment.Description': 'Airasia Red Discount Description',
      'penangtourism_sale.listing.title': '槟城旅游特卖',
      'PLATINUM.listing.title': 'Airasia Platinum',
      'supersale.payment.title': '10.10 SUPER SALE',
      'GOLD.listing.Description': 'Airasia Gold Discount Description',
      'airasia_anniversary.listing.Description': '亚航20岁生日特卖',
      'Extra5.listing.title': '5%额外折扣',
      'BLACK.payment.title': 'Airasia Black',
      'dealoftheday.payment.title': 'DEAL OF THE DAY',
      'flash_sale.listing.title': 'FLASH DEAL',
      'superpayday.listing.Description': 'SUPERPAYDAY',
      'workcation_campaign.listing.Description': '工作活动',
      'sarawaktourism_sale.listing.title': '砂拉越旅游特卖',
      'Extra5.listing.Description': '折扣已添加到付款頁面',
      'everyday30%off.listing.Description': 'Everyday 30% OFF',
      'pertiwi_10.listing.title': '10% 到 Pertiwi',
      'superpayday.listing.title': 'SUPERPAYDAY',
      'eidsale.listing.Description': 'EID SALE',
      'RED.listing.Description': 'Airasia Red Discount Description',
      'super_travel_sale.payment.Description': 'Super Travel Sale',
      'bestdeal.payment.title': 'BEST DEAL',
      'supersale.listing.Description': '10.10 SUPER SALE',
      'free_room_upgrade.listing.title': '免费房间升级',
      'superhotelpartner.payment.title': 'Super Hotel Partner',
      'certified_super.listing.title': 'CERTIFIED SUPER',
      'supertravel.listing.Description': 'SUPERTRAVEL',
      'ramadhanspecial.payment.title': 'Ramadhan Special',
      'super_travel_sale.payment.title': 'Super Travel Sale',
      'ayokembali.listing.Description': 'AYOKEMBALI',
      'sarawaktourism_sale.listing.Description': '砂拉越旅游特卖',
      'ayokembali.listing.title': 'AYOKEMBALI',
      'dealoftheday.listing.title': 'DEAL OF THE DAY',
      'Extra5.payment.title': '5%额外折扣',
      'PLATINUM.listing.Description': 'Airasia Platinum Discount Description',
      'PLATINUM.payment.title': 'Airasia Platinum',
      'supertravel.payment.title': 'SUPERTRAVEL',
      'vtl_campaign.listing.title': 'VTL活动',
      'wednesday_deal.listing.title': 'WEDNESDAY FLASH DEAL',
      'the_match.payment.title': 'THEMATCH',
      'ayokembali.payment.title': 'AYOKEMBALI',
      'bestdeal.listing.title': 'BEST DEAL',
      'songkransupersplash.listing.Description': 'Songkran Super Splash',
      'dealoftheday.listing.Description': 'DEAL OF THE DAY',
      'Extra10.listing.Description': '折扣已添加到付款頁面',
      'Extra10.payment.title': '額外10折',
      'Extra10.payment.Description':
        '恭喜你！ 我們已經在此預訂上應用了Flat 10 MYR折扣',
      'sabahtourism_sale.listing.title': '沙巴旅游特卖',
      'free_room_upgrade.listing.Description': '免费房间升级',
      'testSA.listing.title': 'testSA_listingtagging',
      'GOLD.payment.Description': 'Airasia Gold Discount Description',
      'super_travel_sale.listing.title': 'Super Travel Sale',
      'airasia_anniversary.listing.title': '亚航20岁生日特卖',
      'best_price.listing.title': 'BEST PRICE GUARANTEED',
      'eidsale.listing.title': 'EID SALE',
      'best_price.payment.title': 'BEST PRICE GUARANTEED',
      'Extra10.listing.title': '額外10',
      'min_night.listing.Description': '至少 2 晚',
      'BLACK.payment.Description': 'Airasia Black Discount Description',
      'special_promotion.listing.title': 'SUPER VALUE',
      'special_promotion.listing.Description':
        '限时优惠！ 只在airasia Super App上',
      'certified_super.listing.Description':
        '经 airasia Super App 认证的特别促销，提供超值服务',
      'BLACK.listing.title': 'Airasia Black',
      'the_match.listing.title': 'THEMATCH',
      'songkransupersplash.listing.title': 'Songkran Super Splash',
      'supersale.listing.title': '10.10 SUPER SALE',
      'workcation_campaign.listing.title': '工作活动',
    },
    'hotel.hotelList': {
      subTitle: '%{date} | %{guests} | %{rooms}',
      title: '以下是我们在%{destination}找到的酒店',
      viewHotelsInMap: '通过地图查看酒店',
      'recommendation.redirect.title': '酒店推荐',
      resultSearchCount: '%{count}物业满足您的搜索。',
      hotelNotFound: '没有找到酒店！查看其他超赞酒店吧！',
      loadMore: '显示更多',
      pageTitle: '在线预订%{location}最佳酒店|亚航酒店',
      searchHotel: '搜索{0}的酒店',
      fetchedAllHotels: '这是您全部的搜索结果',
      searchedHotel: '您搜索了此酒店',
      mapView: '打开地图',
      promoCampaign: '这家酒店正值促销！立即查看！',
      searchPreferredHotel: '于{0}搜索您喜欢的酒店',
    },
    hotelDetailsPage: {
      'guestReview.showMore': '显示更多',
      hotelAmenities: '酒店设施',
      'guestReview.title': '旅客评价',
      'guestReview.collapseAll': 'Collapse All',
      readReviews: '阅读评论',
      'mobileHotelSummary.checkin': '入住',
      'map.title': '酒店位置',
      'map.showMap': '显示地图',
      'map.seeOnAMap': '通过地图查看',
      'amenity.all': '全部酒店设施',
      'poi.seeAllPlaces': '查看全部地方',
      'poi.title': '探索周边',
      'mobileHotelSummary.checkout': '退房',
      'amenity.more': ['+ {{amount}}', { amount: 0 }],
      'guestReview.noReviews':
        'Sorry! There are no reviews available for this hotel at this moment.',
    },
    roomCard: {
      'optional.freeHotelCancellation': 'Free Hotel Cancellation',
      priceFlightHotelHintText:
        '包括航班 & %{smart_count} 夜酒店住宿 |||| 包括航班 & %{smart_count} 夜酒店住宿',
      adults: '成人',
      sleeps: '睡眠',
      priceHotelNights: '',
      selectRoom: '请选择房间',
      priceIncludeFlight: '',
      ratePlanDetails: '评价方案信息',
      benefitsIncludedInThePrice: '价格已含福利',
      children: '儿童',
      seeAllAmenities: '查看全部设施',
      guest: '位客人',
      seeLessOptions: '隐藏选项',
      priceOff: '%{percent}折扣',
      squareMeter: '%{squareMeterArea}平方米',
      priceHeader: '价格',
      optionsAndBenefits: '选项及福利',
      moreDetails: '更多信息',
      priceFlightHotelHintText2:
        '包括 %{numFlights, plural,\r\n    =0 {没有航班}\r\n    one {1 班机}\r\n    other {# 班机}\r\n} 和 %{numHotelNights, plural,\r\n    =0 {没有酒店住宿}\r\n    one {1 晚酒店住宿}\r\n    other {# 晚酒店住宿}\r\n}',
      pricePerRoomPerNight: '价格(每房每夜)',
      pricePerPerson:
        '价格 (包含航班 & %{smart_count} 夜酒店住宿) |||| 价格 (包含航班 & %{smart_count} 夜酒店住宿)',
      'optional.penaltyPartial': '付费取消',
      payAtHotelDescription: '在酒店支付',
      totalPayable: '待支付总额',
      seeRateMoreOptions: '查看更多选项',
      option: '选项',
      includedInPrice: '价格已含',
      includeTaxText: 'incl. taxes',
      max: '最长 ',
      payNow: '马上支付',
      viewRoomImages: '查看酒店图片',
      photos: '照片',
      'optional.freeCancellation': '免费取消',
      priceHintText: '每间客房每晚',
      morePhotos: '更多照片',
      buyNowPayLater: '先购买，后支付',
      bedGroupSelectionNote: '请知悉：酒店不保证满足客人的床型偏好',
      reserveNow: '立即预订',
      totalInclTax: '总额',
      aboutTheRoom: '关于房间',
      'optional.nonRefundable': '不予退款',
      night: '晚',
      payLater: '稍后支付',
      bookNow: '立即预订',
      roomsLeftText: '仅剩%{smart_count}个房间 |||| 仅剩%{smart_count}个房间',
      showMore: '显示更多',
      at: '于',
    },
    'roomCard.rateName': {
      name_2: 'Bed and Breakfast',
      name_3: '半食宿',
      name_1: '仅房间',
      name_6: '房间+晚餐',
      name_7: '房间+免费取消',
      name_4: '全食宿',
      name_5: '房间+午餐',
      name_8: '房间+免费取消&早餐',
      name_9: 'Room with free cancellation + Lunch/ Dinner ',
      name_10: 'Room with free cancellation & half board ',
      name_11: 'Room with free cancellation & full board',
      name_12: '房间+免费早&午餐',
      name_13: 'Room with free breakfast and dinner',
      name_14: '房间+免费早、午及晚餐',
      name_15: '房间+主题公园门票',
      name_16: '特惠房间',
    },
    roomTags: {
      penangtourism_sale: '槟城旅游特卖',
      songkransupersplash: 'Songkran Super Splash',
      quarantine_hotel: '检疫酒店',
      the_match: 'THEMATCH',
      airasia_anniversary: ['亚航20岁生日特卖', { date: '' }],
      'cancellationPolicyText.noRefund.Mobile': ['取消不予退款', { date: '' }],
      'cancellationPolicyText.partialRefund': [
        '酒店当地时间{{date}} 前付费取消',
        { date: '' },
      ],
      supertravel: 'SUPERTRAVEL',
      sabahtourism_sale: '沙巴旅游特卖',
      vtl_campaign: 'VTL活动',
      certified_super: 'CERTIFIED SUPER',
      workcation_campaign: '工作活动',
      ayokembali: 'AYOKEMBALI',
      best_price: 'BEST PRICE GUARANTEED',
      Extra10: '10%额外折扣',
      bestdeal: 'BEST DEAL',
      min_night: '至少 2 晚',
      'cancellationPolicyText.noRefund': ['取消不予退款', { date: '' }],
      flash_sale: 'FLASH DEAL',
      supersale: '10.10 SUPER SALE',
      'cancellationPolicyText.partialRefund.Mobile': [
        '酒店当地时间{{date}} 前',
        { date: '' },
      ],
      superhourdeals: 'SUPER HOUR DEALS',
      superhotelpartner: 'Super Hotel Partner',
      ramadhanspecial: 'Ramadhan Special',
      dealoftheday: 'DEAL OF THE DAY',
      sarawaktourism_sale: '砂拉越旅游特卖',
      'cancellationPolicyText.fullRefund.Mobile': [
        '酒店当地时间{{date}} 前',
        { date: '' },
      ],
      'cancellationPolicyText.fullRefund': [
        '酒店当地时间{{date}} 前免费取消',
        { date: '' },
      ],
      superpayday: 'SUPERPAYDAY',
      wednesday_deal: 'WEDNESDAY FLASH DEAL',
      free_room_upgrade: '免费房间升级',
      eidsale: 'EID SALE',
      special_promotion: 'SUPER VALUE',
      'everyday30%off': 'Everyday 30% OFF',
      best_price_guaranteed: '强烈推荐',
    },
    bookingDetail: {
      'paymentDetails.payLater.bookingAmount': 'Booking amount',
      'paymentDetails.multiply': [
        '{{x}} x {{y}}',
        {
          x: '',
          y: '',
        },
      ],
      'cancellation.bannerMessage.fail': '我们无法处理您的取消,请重新尝试。',
      'cancellation.reasons.PLAN_CHANGE': '我改变了计划',
      'paymentDetails.payAtHotel.amountToBePaid': '待付金额：',
      'cancellation.buttons.confirm': '取消预订',
      roomCount: '%{smart_count} 房间 |||| %{smart_count} 房间',
      getBookingConfirmation: '获取预订确认',
      'paymentDetails.amountToBePaid': '待支付总额',
      'cancellation.confirmDialog.messages.partialRefundMsg':
        '若您现在取消，取消费(value)(currency)将从您的银行卡扣除。',
      'paymentDetails.taxIncludedTooltip':
        '所示价格含税。出口退税费由卖家代为收取。',
      'cancellation.confirmDialog.subtitle': '请让我们知道您取消的原因',
      'cancellation.actionSheet.title': '取消原因',
      cancelBooking: '取消预订',
      bookingStatus: '预订状态',
      'paymentDetails.payNow.taxAndFeeIncludedTooltip':
        '此价格包含税款及收费。AirAsia.com将代表酒店收取补征税。此预订可能会收取服务费。',
      'cancellation.confirmDialog.messages.fullRefund': '我们会为您全额退款。',
      title: '预订信息',
      'paymentDetails.payLater.amountToBePaid': 'Amount to be paid',
      'cancellation.confirmDialog.messages.nonRefundableMsg':
        '若您现在取消预订金额将从您的银行卡扣除。',
      'paymentDetails.totalAmount': '应付总额',
      'cancellation.buttons.cancel': '保留此预订',
      pageTitle: '酒店客人预订详情|亚航酒店',
      freeWifi: 'Free Wifi',
      buyNowPayLaterBooking: 'Reserve now, pay later',
      manageBookingLabel: '管理预订',
      'cancellation.actionSheet.cancel': '保留此预订',
      'paymentDetails.rooms': ['{{smartCount}} 房间', { smartCount: 0 }],
      'paymentDetails.cardDiscount': '额外折扣',
      'paymentDetails.lobDiscount': '酒店折扣',
      'cancellation.refundInformation.amount': '%{currency} %{value}',
      'cancellation.confirmDialog.messages.freeCancelMsg':
        '若您现在取消您的银行卡不会被扣费。',
      'payAtHotel.inventory_pending.getBookingConfirmation': '获取预定确认',
      'paymentDetails.payNow.taxIncludedTooltip':
        '此价格包含税费。AirAsia.com将代表酒店收取补征税。',
      getBookingConfirmationFailed: '我们无法发送您的预订确认。请重新尝试！',
      'payAtHotel.confirmed.getBookingConfirmation': '获取预定确认',
      'cancellation.refundInformation.subtitle':
        '您于%{date}申请了退款。我们将在14个工作日内处理并为您退款。我们将退还用于兑换此预订的任何亚航积分到您的亚航会员帐户。',
      'cancellation.confirmDialog.select': '取消原因',
      'payAtHotel.confirmed.cancelBooking': '取消预定',
      'paymentDetails.payLater.total': 'Total',
      'cancellation.reasons.MISTAKE': '我预订错误',
      amountPaidNotice: '请知悉，客人需在酒店支付额外费用。',
      'payAtHotel.completed.getBookingConfirmation': '获取预定确认',
      'payAtHotel.inventory_pending.cancelBooking': '取消预定',
      'cancellation.confirmDialog.messages.partialRefund':
        '由于这是部分退款的预订，我们将退还您%{currency}%{value}。',
      getBookingConfirmationSuccess: '我们已将您的预订确认发送至%{email}',
      'paymentDetails.payNow.feeIncludedTooltip':
        '此价格包含税款及收费。AirAsia.com将代表酒店收取补征税。此预订可能会收取服务费。',
      amountPaid: '已支付金额',
      'paymentDetails.promotionalDiscount': '折扣',
      'paymentDetails.feeIncludedTooltip': '出口退税费由卖家代为收取。',
      'cancellation.confirmDialog.title': '取消我的预订',
      'paymentDetails.bigPointsRedemption': 'BIG Points redemption',
      'cancellation.reasons.OTHER_REASON': '其他原因',
      'cancellation.refundInformation.title': '退款金额',
      'cancellation.confirmDialog.messages.noRefund':
        '若您取消预订，将不予退款。',
      'cancellation.bannerMessage.success':
        '我们已取消您的预订。我们会向您发送一封确认电子邮件。',
      'paymentDetails.taxAndFeeIncludedTooltip':
        '所示价格包含税&费。出口退税费由卖家代为收取。本预订或收取服务费。',
      'paymentDetails.additionFeesText': '酒店收取的额外费用',
      'paymentDetails.amountPaid': '已支付金额',
      'paymentDetails.taxAndFeeCombinedTooltip':
        'AirAsia.com将代表酒店收取补征税。税费是指欧洲地区酒店的“补税手续费”，其他地区酒店的“补税手续费和服务费”。',
      bookingNumber: '预订编号',
      'paymentDetails.title': '支付详情',
      'paymentDetails.paidThroughBIGPoints': '使用airasia积分支付',
      'cancellation.reasons.BETTER_DEAL': '我找到了一个更好的价格',
      'paymentDetails.payAtHotel.bookingAmount': '预定金额',
      'cancellation.reasons.BOOK_DIFFERENT_HOTEL': '我选择另一家酒店',
      'paymentDetails.payableAtSiteItem': [
        '{{currency}} {{value}} {{name}}',
        {
          currency: '',
          value: '',
          name: '',
        },
      ],
    },
    hotelDetailsConfirm: {
      'hotelBookingInfo.note': '请知悉，无法保证特殊床型及吸烟要求',
      'hotelBookingInfo.nonRefundable': '不予退款',
      'hotelBookingInfo.checkInLabel': '入住',
      'hotelBookingInfo.customerSupport':
        '恭喜，您获得超值优惠！酒店预订一律不支持退款。除非有紧急情况否则不支持取消。如需任何协助请联系我们的客服人员。',
      'hotelBookingInfo.awaitConfirmation':
        "We're awaiting confirmation for your hotel booking.",
      'hotelBookingInfo.sendVoucherText':
        "We'll send the hotel voucher to your email once we've confirmed the booking",
      'hotelBookingInfo.nonSmoking': '禁烟',
      'hotelBookingInfo.checkOutLabel': '退房',
    },
    cancellationPolicy: {
      'description.None': [
        '{{date}}前可免费取消，之后或须支付手续费。不适用于不支持退款的房间。',
        {
          date: '',
        },
      ],
      'cancellationFund.P.Partial': [
        '我们将退还您已支付总额的{{percent}}',
        {
          percent: '',
        },
      ],
      Full: '不予退款',
      title: '取消政策',
      checkinDate: '入住日期',
      'cancellationFund.P.None': '您将收到100％退款',
      'description.NoRefund': [
        '不予退款预订，取消预订酒店依旧会收取您支付的全款。',
        {
          date: '',
        },
      ],
      destinationTime: [
        '{{time}} {{city}} 时间',
        {
          time: '',
          city: '',
        },
      ],
      'cancellationFund.N.Full': '您的预订不予退款',
      None: '全额退款',
      'cancellationFund.A.None': '您将收到100％退款',
      'cancellationFund.N.None': '您将收到100％退款',
      'cancellationFund.A.Partial': [
        '获得总支付款项减去{{amount}} 费用的退款',
        {
          amount: '',
        },
      ],
      'payAtHotel.Full': 'Non-refundable',
      'payAtHotel.None': '免费取消',
      now: '马上',
      'cancellationFund.A.Full': '您的预订不予退款',
      Partial: '部分退款',
      'cancellationFund.N.Partial': [
        '获得总款项减去%{smart_count}夜住宿费用的退款',
        {
          smart_count: 0,
        },
      ],
      'description.Partial': [
        '酒店当地时间{{date}} 前付费取消',
        {
          date: '',
        },
      ],
      'payAtHotel.Partial': 'Partially refundable',
      'cancellationFund.P.Full': '您的预订不予退款',
      'description.Full': [
        '取消时不退款',
        {
          date: '',
        },
      ],
    },
    tripDetails: {
      tripDetails: '没关系，您暂时无需付款',
      cancellationPolicy: '取消政策',
      whoIsCheckingBanner:
        '请输入办理入住客人的姓名。所有信息须与办理入住时使用的证件信息相一致。',
      header: '行程详情',
      'footer.totalRoomPrice': '%{rooms}总价格',
      'payLater.termsOfUse': 'Terms of Use',
      'payLater.youCanAlsoChooseToPayNow':
        '您也可以选择使用借记卡、信用卡或其他可选支付方式完成支付。',
      title: '谁在旅行？',
      whoIsChecking: '谁在办理入住？',
      'payLater.riskFreeCancellationsBefore': '%{date}前无风险取消',
      NoRefund: '您需要现场支付',
      'button.continue': '继续',
      payLaterPaymentChargeMessage: '您今日需要支付%{currency} 00',
      'priceBreakup.amount': '待支付总额',
      'footer.totalPrice':
        '%{smart_count}位客人总价格 |||| %{smart_count}位客人总价格',
      willNotBeChargedBeforeMessage: ['您于{{date}}前不会被扣费', { date: '' }],
      'choosePaymentOption.Desktop': '选择您的支付方式',
      'footer.totalNightsAndGuests': '%{nights} 及 %{guests}',
      paymentChargeMessage: '我们将在结账时全额收费',
      extraNoteTitle: '请知悉：',
      'footer.totalNights':
        '包括航班 & %{smart_count} 夜酒店住宿 |||| 包括航班 & %{smart_count} 夜酒店住宿',
      'text.continuetopay': '没关系，您暂时无需付款',
      'selectBedType.description': '此是酒店将为您所有房间预订提供的床型。',
      buyNowPayLater: '先购买，后支付',
      'payLater.dependingOnYourPayment':
        'Depending on your payment currency, AirAsia’s currency exchange rate may be applied as per AirAsia’s',
      guestIndex: '%{guest} %{count}',
      'payLater.warningMessage':
        'airasia积分兑换目前不适用于马上购买&稍后支付选项。',
      'payLater.payNothingUntil': ['{{date}}才需支付', { date: '' }],
      'choosePaymentOption.Mobile': '您希望如何支付？',
      'selectBedType.title': '选择床型',
      'footer.totalNights.oneWay':
        '包括航班 & %{smart_count} 夜酒店住宿 |||| 包括航班 & %{smart_count} 夜酒店住宿',
    },
    ['hotel.roomSelect']: {
      pageTitle: '酒店房间选择|亚航酒店',
      thereAreNoRooms: '啊哦！没有房间。',
      letSearchForRooms: '我们去另一家酒店看看?',
    },
    tripSummary: {
      hotelIn: [
        '酒店位于 {{place}}',
        {
          place: '',
        },
      ],
      totalPrice: [
        '{{smartCount}} 间客房总价格',
        {
          smartCount: '',
        },
      ],
      totalNights: [
        '{{fromDate}} - {{toDate}} ({{smartCount}})',
        {
          fromDate: '',
          toDate: '',
          smartCount: (value = 1) => {
            return `${value}夜`;
          },
        },
      ],
      depart: '出发',
      roundTripFlight: '往返航班',
      andGuest: [
        '及 {{smartCount}} 旅客',
        {
          smartCount: '',
        },
      ],
      oneWayTrip: 'One way trip',
      departure: '出发',
      flightSummaryTitle: '航班总览',
      singleStop: '1转机',
      night: [
        '{{smartCount}} 夜',
        {
          smartCount: '',
        },
      ],
      return: '回程',
      nonStop: '直飞',
    },
    chargeName: {
      malaysia_tourism_tax: 'Malaysia Tourism Tax',
      property_fee: '物业费',
      mandatory_fee: '强制费用',
      mandatory_tax: '强制税费',
      sales_tax: '营业税',
      service_tax: '服务税',
      taxes_and_fees: '税费及收费',
      service_fee: '服务费',
      resort_fee: '度假费',
      service_charge: '服务费',
    },
    form: {
      'message.missingFields': '请填写所有信息，以便我们就您的预订与您联系。',
    },
    bookerDetails: {
      'message.mobileError': '请输入您的手机号码',
      name: '姓名',
      countryCode: '电话代码',
      'message.maxLengthError': [
        '{{field}}不支持超过{{length}}字符',
        { field: '', length: '' },
      ],
      mobile: '手机号码',
      'button.done': '完成',
      familyName: '姓氏',
      'message.alphabetsError':
        '仅限英文字母(A到Z)。不支持使用特殊字符或符号。',
      'message.emailError': '请输入一个有效的电子邮箱地址',
      header: '谁在预订？',
      'message.numberError': '仅可输入数字',
      givenName: '名字',
      'button.reset': '重置',
      email: '电子邮件地址',
      continue: '继续',
      nationality: '国籍/地区',
    },
    bookingStatus: {
      'payAtHotel.inventory_pending.listId': '待定',
      'confirmed.listId': '已确认',
      'payLater.aa_refund_pending.listId': 'Cancelled by AirAsia',
      'payLater.aa_inventory_release_pending.listId': '亚航取消',
      'booking_failed.msgId': [
        '非常抱歉！确认预订时出现问题。我们正处理您的全额退款，并将向{{email}} 发送一封包含详细信息的电子邮件。',
        {
          date: '',
          email: '',
        },
      ],
      'payAtHotel.booking_failed.headerId': '预订失败',
      'payAtHotel.user_inventory_release_pending.msgId': [
        '您的取消正在处理中！取消接受后我们会向{{email}}发送邮件告知详情。',
        {
          date: '',
          email: '',
        },
      ],
      'payLater.user_refund_pending.headerId': 'Refund pending',
      'pvdr_refund_pending.headerId': 'pvdr_refund_pending_header_id',
      'user_refund_pending.listId': '取消',
      'payment_failed_temp.listId': '失败',
      'payLater.provisionally_confirmed.listId': 'Confirmed',
      'payAtHotel.payment_failed.listId': '失败',
      'payAtHotel.airasia_cancelled.listId': '亚航取消',
      'payLater.inventory_release_pending.headerId':
        'Inventory release pending',
      'payAtHotel.payment_failed.headerId': '预订失败',
      'payAtHotel.hotel_cancelled.msgId': [
        '抱歉，酒店已取消您的预定，您将获得全额退款。我们会将详情发送至 {{email}}。',
        {
          date: '',
          email: '',
        },
      ],
      'payment_void_refund_failed.listId': '失败',
      'payLater.inventory_release_pending.listId': 'Cancelled',
      'payLater.completed.msgId': [
        'Thank you for booking with us. We hope you had a great stay.',
        {
          date: '',
          email: '',
        },
      ],
      'payLater.provisionally_confirmed.headerId': 'Booking Reserved',
      'refund_pending.msgId': [
        '非常抱歉！确认预订时出现问题。我们正处理您的全额退款，并将向{{email}} 发送一封包含详细信息的电子邮件。',
        {
          date: '',
          email: '',
        },
      ],
      'payAtHotel.user_inventory_release_pending.headerId': '取消',
      'payLater.refund_pending.msgId': [
        "We're sorry. There was an issue while confirming your booking. We're procssing your refund in FULL and will also send an email to {{email}} with all the details.",
        {
          date: '',
          email: '',
        },
      ],
      'payLater.Debit_Failed.headerId': '亚航取消',
      'payLater.hotel_cancelled.headerId': 'Cancelled by Hotel',
      'aa_refund_pending.headerId': 'aa_refund_pending_header_id',
      'payLater.booking_failed.headerId': 'Refund Pending',
      'payLater.hotel_cancelled.listId': 'Cancelled by Hotel',
      'payAtHotel.airasia_cancelled.msgId': [
        '抱歉，确认您的预定时出现问题，您将获得全额退款。我们会将详情发送至 {{email}}。',
        {
          date: '',
          email: '',
        },
      ],
      'payLater.user_cancelled.headerId': 'User cancelled',
      'payLater.inventory_pending.msgId': [
        "Your payment was successful. We're waiting for the hotel to confirm your booking. We'll send an email to {{email}} when it is confimed.",
        {
          date: '',
          email: '',
        },
      ],
      'payLater.Debit_Failed.msgId': [
        "We're sorry. There was an issue while securing payment for your booking. We have cancelled your booking & send an email to {{email}} with all the details.",
        {
          date: '',
          email: '',
        },
      ],
      'payLater.user_refund_failed.listId': 'Cancelled',
      'payLater.hotel_refund_failed.headerId': 'Cancelled by Hotel',
      'payLater.hotel_cancelled.msgId': [
        "We're sorry. The hotel cancelled your booking. We're processing your refund in FULL and will also send an email to {{email}} with all the details.",
        {
          date: '',
          email: '',
        },
      ],
      'payLater.aa_refund_failed.listId': 'Cancelled by AirAsia',
      'user_inventory_release_pending.msgId': [
        '您的取消正在处理中！取消接受后我们会向{{email}}发送邮件告知详情。',
        {
          date: '',
          email: '',
        },
      ],
      'payLater.user_refund_failed.msgId': [
        "We're sorry. There was an issue while confirming your booking. We're procssing your refund in FULL and will also send an email to {{email}} with all the details.",
        {
          date: '',
          email: '',
        },
      ],
      'pvdr_refund_pending.listId': '酒店取消',
      'refund_pending.listId': '失败',
      'payLater.aa_inventory_release_pending.headerId': '亚航取消',
      'payLater.user_cancelled.msgId': [
        '',
        {
          date: '',
          email: '',
        },
      ],
      'payAtHotel.user_inventory_release_pending.listId': '取消',
      'payLater.provisionally_confirmed.msgId': [
        'Congratulations! Your booking is reserved. We will send a confirmation email with all the details to {{email}} once the funds are realized from your card',
        {
          date: '',
          email: '',
        },
      ],
      'payAtHotel.completed.msgId':
        '希望您有一个愉快的住宿经历！感谢您选择我们。',
      'aa_inventory_release_pending.listId': '亚航取消',
      'pvdr_refund_pending.msgId': [
        '非常抱歉！确认预订时出现问题。我们正处理您的全额退款，并将向{{email}} 发送一封包含详细信息的电子邮件。',
        {
          date: '',
          email: '',
        },
      ],
      'payAtHotel.hotel_cancelled.headerId': '预定已取消',
      'payLater.payment_pending.listId': 'Pending',
      'booking_failed.headerId': '退款待定',
      'user_inventory_release_pending.listId': '取消',
      'payLater.inventory_pending.headerId': 'Booking Pending',
      'payAtHotel.user_cancelled.listId': '取消',
      'payLater.aa_inventory_release_pending.msgId': [
        '非常抱歉！取消您的预订时出现问题。我们将取消您的预订并进入全额退款程序。我们将向{{email}}发送一封包含详细信息的电子邮件。',
        {
          date: '',
          email: '',
        },
      ],
      'payLater.hotel_refund_pending.msgId': [
        " We're sorry. There was an issue while confirming your booking. We're procssing your refund in FULL and will also send an email to {{email}} with all the details.",
        {
          date: '',
          email: '',
        },
      ],
      'payLater.airasia_cancelled.headerId': 'Cancelled by AirAsia',
      'user_refund_pending.headerId': 'user_refund_pending_header_id',
      'payAtHotel.user_cancelled.msgId': [
        '我们已经接受您的取消请求，我们已经向{{email}}发送邮件告知详情。',
        {
          date: '',
          email: '',
        },
      ],
      'payLater.booking_failed.msgId': [
        "We're sorry. There was an issue while confirming your booking. We have started processing FULL refund to your original payment method on {{date}}. This may take up to 14 working days. We've sent an email to {{email}} with all the details.",
        {
          date: '',
          email: '',
        },
      ],
      'payLater.confirmed.msgId': [
        'Awesome! Your booking is confirmed. We have sent an email to {{email}} with all the details.',
        {
          date: '',
          email: '',
        },
      ],
      'inventory_pending.listId': '待定',
      'payAtHotel.orderId_null.headerId': 'Created',
      'pvdr_refund_failed.headerId': 'pvdr_refund_failed_header_id',
      'payLater.payment_failed_temp.headerId': '支付失败',
      'payLater.user_cancelled.listId': 'Cancelled',
      'payAtHotel.orderId_null.listId': 'Created',
      'payLater.aa_inventory_release_failed.listId': 'Cancelled by AirAsia',
      'payment_pending.msgId': [
        '我们仍在处理您的支付。完成后，我们会通过{{email}}向您发送一封电子邮件。',
        {
          date: '',
          email: '',
        },
      ],
      'payLater.user_refund_failed.headerId': 'Refund failed',
      'hotel_cancelled.listId': '酒店取消',
      'payLater.refund_pending.listId': 'Failed',
      'user_cancelled.listId': '取消',
      'user_inventory_release_failed.listId': '取消',
      'payLater.payment_failed_temp.msgId': [
        '非常抱歉！出现多次支付失败！您尚未就本次交易支付费用。',
        {
          date: '',
          email: '',
        },
      ],
      'payAtHotel.completed.headerId': '已完成',
      'payAtHotel.airasia_cancelled.headerId': '预定已取消',
      'payLater.aa_refund_pending.headerId': 'Cancelled by AirAsia',
      'user_cancelled.msgId': [
        "Your cancellation is in progress. We'll send an email to {{email}} when your cancellation is accepted.",
        {
          date: '',
          email: '',
        },
      ],
      'hotel_cancelled.headerId': 'hotel_cancelled_header_id',
      'payLater.inventory_release_failed.headerId': 'Inventory release failed',
      'payLater.confirmed.headerId': 'Reservation Confirmed',
      'payLater.completed.listId': 'Completed',
      'payLater.Debit_Failed.listId': '亚航取消',
      'aa_inventory_release_failed.listId': '亚航取消',
      'user_inventory_release_failed.msgId': [
        '非常抱歉！取消预订和处理退款时出现问题。我们正努力为您全额退款。我们将向{{email}}发送一封包含详细信息的电子邮件。',
        {
          date: '',
          email: '',
        },
      ],
      'payAtHotel.orderId_null.msgId': [
        '抱歉，确认您的预订出现问题。我们并未就此预订向您收费。',
        {
          date: '',
          email: '',
        },
      ],
      'aa_inventory_release_pending.msgId': [
        '非常抱歉！取消您的预订时出现问题。我们将取消您的预订并进入全额退款程序。我们将向{{email}}发送一封包含详细信息的电子邮件。',
        {
          date: '',
          email: '',
        },
      ],
      'user_refund_pending.msgId': [
        'user_refund_pending_msg_id',
        {
          date: '',
          email: '',
        },
      ],
      'airasia_cancelled.headerId': 'airasia_cancelled_header_id',
      'payment_void_refund_failed.msgId': [
        '非常抱歉！确认预订时出现问题。我们正处理您的全额退款，并将向{{email}} 发送一封包含详细信息的电子邮件。',
        {
          date: '',
          email: '',
        },
      ],
      'payment_failed.headerId': '支付失败',
      'payAtHotel.confirmed.msgId': [
        '太好啦！预订成功！您需要在酒店进行支付。我们已经向{{email}}发送邮件告知详情。',
        {
          date: '',
          email: '',
        },
      ],
      'aa_refund_pending.msgId': [
        '非常抱歉！确认预订时出现问题。我们正处理您的全额退款，并将向{{email}} 发送一封包含详细信息的电子邮件。',
        {
          date: '',
          email: '',
        },
      ],
      'payAtHotel.confirmed.listId': '已确认',
      'payAtHotel.inventory_pending.msgId': [
        '您的银行卡信息已记录成功。请等待酒店确认您的预订。确认后我们会向{{email}}发送电子邮件。',
        {
          date: '',
          email: '',
        },
      ],
      'user_inventory_release_failed.headerId':
        'user_inventory_release_failed_header_id',
      'payAtHotel.hotel_cancelled.listId': '酒店取消',
      'payLater.user_refund_pending.listId': 'Cancelled',
      'user_refund_failed.msgId': [
        '非常抱歉！确认预订时出现问题。我们正处理您的全额退款，并将向{{email}} 发送一封包含详细信息的电子邮件。',
        {
          date: '',
          email: '',
        },
      ],
      'payLater.payment_failed.headerId': '支付失败',
      'payLater.payment_failed.msgId': [
        "We're sorry. There was an issue while confirming your reservation. You have not been charged for this booking. We've sent an email with all the details to {{email}}.",
        {
          date: '',
          email: '',
        },
      ],
      'payLater.inventory_release_failed.msgId': [
        "We're sorry. There was an issue while cancelling your booking and procssing your refund. We're working to get you a FULL refund. We'll send an email to {{email}} with all the details.",
        {
          date: '',
          email: '',
        },
      ],
      'payLater.aa_inventory_release_failed.msgId': [
        "We're sorry. There was an issue while confirming your booking and procssing your refund. We're working to get you a FULL refund. We'll send an email to {{email}} with all the details.",
        {
          date: '',
          email: '',
        },
      ],
      'payLater.inventory_pending.listId': 'Pending',
      'payLater.aa_refund_failed.msgId': [
        "We're sorry. There was an issue while confirming your booking. We're procssing your refund in FULL and will also send an email to {{email}} with all the details.",
        {
          date: '',
          email: '',
        },
      ],
      'aa_inventory_release_failed.headerId':
        'aa_inventory_release_failed_header_id',
      'aa_refund_failed.listId': '亚航取消',
      'user_cancelled.headerId': 'Cancelled',
      'airasia_cancelled.listId': '亚航取消',
      'aa_refund_failed.headerId': 'aa_refund_failed_header_id',
      'user_refund_failed.headerId': 'user_refund_failed_header_id',
      'payLater.hotel_refund_failed.msgId': [
        "We're sorry. The hotel cancelled your booking. We're processing your refund in FULL and will also send an email to {{email}} with all the details.",
        {
          date: '',
          email: '',
        },
      ],
      'payAtHotel.payment_failed.msgId': [
        '抱歉，确认您的预订出现问题。我们并未就此预订向您收费。',
        {
          date: '',
          email: '',
        },
      ],
      'payLater.aa_inventory_release_failed.headerId': 'Cancelled by AirAsia',
      'inventory_pending.msgId': [
        '支付成功！我们正在等待酒店确认您的预订。确认后，我们会通过{{email}}向您发送一封电子邮件。',
        {
          date: '',
          email: '',
        },
      ],
      'orderId_null.listId': '失败',
      'aa_inventory_release_pending.headerId':
        'aa_inventory_release_pending_header_id',
      'payAtHotel.refund_pending.headerId': '预订中',
      'payLater.payment_pending.headerId': '待支付',
      'payAtHotel.completed.listId': '已完成',
      'orderId_null.headerId': 'orderId_null_header_Id',
      'aa_refund_pending.listId': '亚航取消',
      'payLater.booking_failed.listId': 'Failed',
      'payment_failed.listId': '失败',
      'user_inventory_release_pending.headerId': '取消',
      'payment_pending.headerId': '待支付',
      'payLater.completed.headerId': 'Completed',
      'pvdr_refund_failed.msgId': [
        '非常抱歉！酒店取消了您的预订。我们正处理您的全额退款，并将向{{email}} 发送一封包含详细信息的电子邮件。',
        {
          date: '',
          email: '',
        },
      ],
      'payLater.inventory_release_failed.listId': 'Cancelled',
      'payLater.aa_refund_pending.msgId': [
        "We're sorry. There was an issue while confirming your booking. We're procssing your refund in FULL and will also send an email to {{email}} with all the details.",
        {
          date: '',
          email: '',
        },
      ],
      'aa_inventory_release_failed.msgId': [
        '非常抱歉！确认您的预订和处理退款时出现问题。我们正努力为您全额退款。我们将向{{email}}发送一封包含详细信息的电子邮件。',
        {
          date: '',
          email: '',
        },
      ],
      'payLater.airasia_cancelled.listId': 'Cancelled by AirAsia',
      'payLater.hotel_refund_failed.listId': 'Cancelled by Hotel',
      'pvdr_refund_failed.listId': '酒店取消',
      'payLater.payment_failed.listId': 'Failed',
      'completed.listId': '已完成',
      'payment_pending.listId': '待定',
      'payAtHotel.user_cancelled.headerId': '取消',
      'payLater.confirmed.listId': 'Confirmed',
      'aa_refund_failed.msgId': [
        '非常抱歉！确认预订时出现问题。我们正处理您的全额退款，并将向{{email}} 发送一封包含详细信息的电子邮件。',
        {
          date: '',
          email: '',
        },
      ],
      'payment_failed_temp.headerId': '支付失败',
      'payLater.hotel_refund_pending.listId': 'Cancelled by Hotel',
      'airasia_cancelled.msgId': [
        '非常抱歉！确认预订时出现问题。我们已处理您的全额退款。我们将向{{email}}发送一封包含详细信息的电子邮件。',
        {
          date: '',
          email: '',
        },
      ],
      'payLater.airasia_cancelled.msgId': [
        "We're sorry. There was an issue while confirming your booking. We've processed your refund in FULL. We'll send an email to {{email}} with all the details.",
        {
          date: '',
          email: '',
        },
      ],
      'completed.headerId': 'completed_header_id',
      'payAtHotel.inventory_pending.headerId': '预订中',
      'refund_pending.headerId': [
        '退款待定',
        {
          date: '',
          email: '',
        },
      ],
      'orderId_null.msgId':
        '我們很抱歉。 確認您的預訂時出現問題。 我們沒有為此預訂向您收費',
      'payAtHotel.refund_pending.listId': '失败',
      'payAtHotel.confirmed.headerId': '预订确认',
      'payLater.aa_refund_failed.headerId': 'Cancelled by AirAsia',
      'payAtHotel.booking_failed.msgId': [
        '抱歉，与酒店提供方确认预订时出现问题。我们并未就此预订向您收费且已经向{{email}}发送邮件告知详情。',
        {
          date: '',
          email: '',
        },
      ],
      'user_refund_failed.listId': '取消',
      'booking_failed.listId': '失败',
      'payAtHotel.refund_pending.msgId': [
        '抱歉，确认您的预定时出现问题，您将获得全额退款。我们会将详情发送至 {{email}}。',
        {
          date: '',
          email: '',
        },
      ],
      'inventory_pending.headerId': '预订待定',
      'hotel_cancelled.msgId': [
        '非常抱歉！酒店取消了您的预订。我们正处理您的全额退款，并将向{{email}} 发送一封包含详细信息的电子邮件。',
        {
          date: '',
          email: '',
        },
      ],
      'completed.msgId': [
        '感谢您选择亚航！希望您有一个愉快的住宿经历！',
        {
          date: '',
          email: '',
        },
      ],
      'confirmed.headerId': '订单已确认',
      'payLater.payment_failed_temp.listId': 'Failed',
      'payment_failed.msgId': [
        '非常抱歉！处理您的支付时出现问题。我们已经向{{email}}发送了一封包含详细信息的电子邮件。',
        {
          date: '',
          email: '',
        },
      ],
      'confirmed.msgId': [
        '太好了！您的预订已确认。我们已经向{{email}}发送了一封包含详细信息的电子邮件。',
        {
          date: '',
          email: '',
        },
      ],
      'payAtHotel.booking_failed.listId': '失败',
      'payment_failed_temp.msgId': [
        '非常抱歉！出现多次支付失败！您尚未就本次交易支付费用。',
        {
          date: '',
          email: '',
        },
      ],
      'payLater.refund_pending.headerId': 'Refund Pending',
      'payment_void_refund_failed.headerId':
        'payment_void_refund_failed_header_id',
      'payLater.hotel_refund_pending.headerId': 'Cancelled by Hotel',
      'payLater.payment_pending.msgId': [
        "Your card details have been captured successfully. We're waiting for the hotel to confirm your reservation. We'll send an email to {{email}} when it is confimed.",
        {
          date: '',
          email: '',
        },
      ],
    },
  },
};
