import { getURLParams } from '@/helper/util';
import { userStore, webStore } from '@/store/base';
import { H5Service } from '@/api';

export const getUUIDFromSearchParams = (searchParams = null) => {
  if (!searchParams) {
    searchParams = getURLParams();
  }

  return searchParams.get('uuid');
};

export const parseSearchParams = async () => {
  try {
    const searchParams = getURLParams();
    const uuid = getUUIDFromSearchParams(searchParams);

    const { data } = await H5Service.getDataByUUID(uuid);

    await userStore.setUserInfoWithToken(data.ssoInfo);
    webStore.paramManager.setParams({ openId: data.openId, bgKey: data.bgKey });

    return data;
  } catch (error) {
    console.error('Failed to retrieve data by UUID:', error);
    throw error;
  }
};

export const updateSearchParams = (data) => {
  const newSearchParams = new URLSearchParams({
    locationId: data.locationId,
    locationName: data.locationName,
    totalRooms: data.totalRooms,
    adults: data.adults,
    children: data.children,
    totalAdults: data.totalAdults,
    totalChildren: data.totalChildren,
    checkin: data.checkin,
    checkout: data.checkout,
    wmpurl: data.wmpurl,
  });

  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${newSearchParams.toString()}`,
  );
};
