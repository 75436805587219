import validator from 'validator';

export function deepMerge(a, b) {
  return Object.entries(b || {}).reduce((o, [k, v]) => {
    o[k] =
      v && typeof v === 'object'
        ? deepMerge((o[k] = o[k] || (Array.isArray(v) ? [] : {})), v)
        : v;
    return o;
  }, a || {});
}

export type ValidationRule = {
  overallMessage?: string;
  rules: {
    required?: boolean;
    max?: number;
    min?: number;
    minLength?: number;
    maxLength?: number;
    includesNumber?: number;
    includesLetters?: number;
    includesUppercase?: number;
    includesLowercase?: number;
    includesSpecialCharacters?: number;
    pattern?: RegExp;
    validatorMethod?:
      | 'equals'
      | 'contains'
      | 'matches'
      | 'isEmail'
      | 'isURL'
      | 'isMACAddress'
      | 'isIP'
      | 'isIPRange'
      | 'isFQDN'
      | 'isBoolean'
      | 'isIBAN'
      | 'isBIC'
      | 'isAlpha'
      | 'isAlphaLocales'
      | 'isAlphanumeric'
      | 'isAlphanumericLocales'
      | 'isNumeric'
      | 'isPassportNumber'
      | 'isPort'
      | 'isLowercase'
      | 'isUppercase'
      | 'isAscii'
      | 'isFullWidth'
      | 'isHalfWidth'
      | 'isVariableWidth'
      | 'isMultibyte'
      | 'isSemVer'
      | 'isSurrogatePair'
      | 'isInt'
      | 'isIMEI'
      | 'isFloat'
      | 'isFloatLocales'
      | 'isDecimal'
      | 'isHexadecimal'
      | 'isOctal'
      | 'isDivisibleBy'
      | 'isHexColor'
      | 'isRgbColor'
      | 'isHSL'
      | 'isISRC'
      | 'isMD5'
      | 'isHash'
      | 'isJWT'
      | 'isJSON'
      | 'isEmpty'
      | 'isLength'
      | 'isLocale'
      | 'isByteLength'
      | 'isUUID'
      | 'isMongoId'
      | 'isAfter'
      | 'isBefore'
      | 'isIn'
      | 'isLuhnNumber'
      | 'isCreditCard'
      | 'isIdentityCard'
      | 'isEAN'
      | 'isISIN'
      | 'isISBN'
      | 'isISSN'
      | 'isMobilePhone'
      | 'isMobilePhoneLocales'
      | 'isPostalCode'
      | 'isPostalCodeLocales'
      | 'isEthereumAddress'
      | 'isCurrency'
      | 'isBtcAddress'
      | 'isISO6391'
      | 'isISO8601'
      | 'isRFC3339'
      | 'isISO31661Alpha2'
      | 'isISO31661Alpha3'
      | 'isISO4217'
      | 'isBase32'
      | 'isBase58'
      | 'isBase64'
      | 'isDataURI'
      | 'isMagnetURI'
      | 'isMimeType'
      | 'isLatLong'
      | 'whitelist'
      | 'blacklist'
      | 'isWhitelisted'
      | 'isSlug'
      | 'isStrongPassword'
      | 'isTaxID'
      | 'isDate'
      | 'isTime'
      | 'isLicensePlate'
      | 'isVAT';
    validatorOptions?: any;
    message?: string;
    callback?: (
      value: any,
      path: string,
      validationPath: string,
      rule: ValidationRules,
      data: object,
    ) => {
      message: string;
      hasError: boolean;
    };
  }[];
};

export type ValidationRules = {
  [key: string]: ValidationRule;
};

export type ValidationResult = {
  [key: string]: {
    hasError: boolean;
    overallmessage: string;
    details: {
      message?: string;
      hasError: boolean;
    }[];
  };
};

function getPaths(data: any) {
  function iter(r: any, p: any): any {
    if (Array.isArray(r)) {
      r.map((x, i) => {
        iter(x, p.concat(`[${i}]`));
      });

      result.push(p);
    } else if (typeof r === 'object') {
      const keys = Object.keys(r);
      if (keys.length) {
        return keys.forEach((x) => iter(r[x], p.concat(x)));
      }
      result.push(p);
    } else if (
      ['string', 'number', 'bitint', 'boolean', 'undefined'].includes(typeof r)
    ) {
      result.push(p);
    }
  }

  const result: any[] = [];
  iter(data, []);
  return result;
}

export async function validate(
  data: object,
  validationRules: ValidationRules,
  path?: string,
): Promise<ValidationResult> {
  if (!path) {
    const paths = getPaths(data);
    let result: ValidationResult = {};
    await Promise.all(
      paths.map(async (props) => {
        const tmpPath: string = props.join('.');
        const res = await validate(data, validationRules, tmpPath);
        result = deepMerge(result, res);
      }),
    );

    return result;
  }

  const props: string[] = [];
  let value: any = data;

  path.split('.').map((prop) => {
    if (
      prop.startsWith('[') &&
      prop.endsWith(']') &&
      !isNaN(Number(prop.substring(1, prop.length - 1)))
    ) {
      const arrayIndex = prop.substring(1, prop.length - 1);
      props.push('[]');
      value = value[arrayIndex];
    } else {
      props.push(prop);
      value = value[prop];
    }
  });

  const validationPath = props.join('.');
  const { rules, overallMessage } = validationRules[validationPath] || {};

  let details: {
    message?: string;
    hasError: boolean;
  }[] = [];

  if (rules) {
    details = await Promise.all(
      rules.map(async (rule) => {
        let res = {
          message: rule.message,
          hasError: false,
        };

        if (rule.validatorMethod) {
          const valid =
            value !== undefined &&
            value !== null &&
            (validator as any)[rule.validatorMethod](
              value,
              ...(rule.validatorOptions || []),
            );

          if (!valid) {
            return {
              message: rule.message,
              hasError: true,
            };
          }
        }

        if (rule.required) {
          const valid = value !== undefined && value !== null && value !== '';

          if (!valid) {
            return {
              message: rule.message,
              hasError: true,
            };
          }
        }
        if (rule.max !== undefined) {
          const valid = !isNaN(Number(value)) && Number(value) <= rule.max;

          if (!valid) {
            return {
              message: rule.message,
              hasError: true,
            };
          }
        }
        if (rule.min !== undefined) {
          const valid = !isNaN(Number(value)) && Number(value) >= rule.min;

          if (!valid) {
            res = {
              message: rule.message,
              hasError: true,
            };
          }
        }
        if (rule.maxLength !== undefined) {
          const valid =
            value !== undefined &&
            value !== null &&
            value.length !== undefined &&
            value.length <= rule.maxLength;

          if (!valid) {
            return {
              message: rule.message,
              hasError: true,
            };
          }
        }

        if (rule.minLength !== undefined) {
          const valid =
            value !== undefined &&
            value !== null &&
            value.length !== undefined &&
            value.length >= rule.minLength;

          if (!valid) {
            return {
              message: rule.message,
              hasError: true,
            };
          }
        }

        if (rule.includesNumber !== undefined) {
          const matches = `${value}`.match(/\d/g);
          const valid =
            ['string', 'number', 'bigint'].includes(typeof value) &&
            matches &&
            matches.length >= rule.includesNumber;

          if (!valid) {
            return {
              message: rule.message,
              hasError: true,
            };
          }
        }

        if (rule.includesLowercase !== undefined) {
          const matches = `${value}`.match(/\w/g);
          const valid =
            ['string', 'number', 'bigint'].includes(typeof value) &&
            matches &&
            matches.length >= rule.includesLowercase;

          if (!valid) {
            return {
              message: rule.message,
              hasError: true,
            };
          }
        }
        if (rule.includesUppercase !== undefined) {
          const matches = `${value}`.match(/[A-Z]/g);
          const valid =
            ['string', 'number', 'bigint'].includes(typeof value) &&
            matches &&
            matches.length >= rule.includesUppercase;

          if (!valid) {
            return {
              message: rule.message,
              hasError: true,
            };
          }
        }

        if (rule.includesLetters !== undefined) {
          const matches = `${value}`.match(/[A-Za-z]/g);
          const valid =
            ['string', 'number', 'bigint'].includes(typeof value) &&
            matches &&
            matches.length >= rule.includesLetters;

          if (!valid) {
            return {
              message: rule.message,
              hasError: true,
            };
          }
        }

        if (rule.includesSpecialCharacters !== undefined) {
          const matches = `${value}`.match(/[^A-Za-z0-9]/g);
          const valid =
            ['string', 'number', 'bigint'].includes(typeof value) &&
            matches &&
            matches.length >= rule.includesSpecialCharacters;

          if (!valid) {
            return {
              message: rule.message,
              hasError: true,
            };
          }
        }

        if (rule.pattern !== undefined) {
          const matches = `${value}`.match(rule.pattern);
          const valid =
            ['string', 'number', 'bigint'].includes(typeof value) &&
            matches &&
            matches.length > 0;

          if (!valid) {
            return {
              message: rule.message,
              hasError: true,
            };
          }
        }

        if (rule.callback !== undefined) {
          const res = await rule.callback(
            value,
            path,
            validationPath,
            validationRules,
            data,
          );

          return {
            message: res?.message || rule.message,
            hasError: res?.hasError,
          };
        }

        return res;
      }),
    );

    return {
      [path]: {
        hasError: details.filter((d) => d.hasError).length > 0,
        overallmessage:
          overallMessage || details.find((d) => d.hasError)?.message || '',
        details,
      },
    };
  } else {
    return {};
  }
}
