import i18n from '@/helper/i18n';
import globalAlertStore from '@/store/common/global-alert';

const t = i18n.chain;

export const showAACheckoutAlert = (
  message: string,
  error: any,
  config: any = {},
) => {
  globalAlertStore.showAlert({
    translation: {
      title: t.error.aaCheckoutErrors._title,
      subTitle: message,
      primaryButtonTitle: t.error.aaCheckoutErrors._buttonTitle,
    },
    headerImage:
      'https://static.airasia.cn/wmp/miniapp/assets/images/search-query-incorrect.svg',
    primaryHandleClick: () => {
      globalAlertStore.hideAlert();
      config.primaryHandleCallback?.(error);
    },
  });
};
