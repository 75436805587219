import { action, makeObservable, observable } from 'mobx';
import { ZodiacApi } from '@/api';
import { userStore } from '@/store/base';
import { CURRENCY_DEFAULT } from '@/helper/constants';
import globalLoadingStore from '@/store/common/global-loading';
import globalMessageStore from '@/store/common/global-message';
import i18n from '@/helper/i18n';
import { Log } from '@/helper/log';

const t = i18n.chain;
const METHOD_NAMES = {
  LOAD_DATA: 'loadData',
} as const;

import { SeatParser } from './parse';

const SEAT_TYPE_MAP = {
  HOT_SEAT: {
    seatType: 'Hot Seat',
    groupType: 'Normal Seat',
  },
  HOT_SEAT_FIRST_ROW: {
    seatType: 'Hot Seat',
    groupType: 'First Seat',
  },
  HOT_SEAT_FRONT_ROWS: {
    seatType: 'Hot Seat',
    groupType: 'Front Seat',
  },
  STANDARD_SEAT: {
    seatType: 'Standard Seat',
    groupType: 'Normal Seat',
  },
  STANDARD_SEAT_FIRST_ROW: {
    seatType: 'Standard Seat',
    groupType: 'First Seat',
  },
  STANDARD_SEAT_FRONT_ROWS: {
    seatType: 'Standard Seat',
    groupType: 'Front Seat',
  },
};

export default class AncillarySeatStore {
  private CLASS_NAME = 'AncillarySeatStore';
  @observable seatMapData: AACheckout.SeatMapData = null;
  @observable startPrice: number | null = null;
  @observable totalAmount = 0;
  @observable plainAddonItems: AACheckout.PlainAddonItem[] = [];
  @observable selectedSeatData: AACheckout.SelectedSeatData = {};
  @observable bundlePlainAddonItems: AACheckout.PlainAddonItem[] = [];

  @observable forceUpdateShow = true;
  @observable dataLoading = false;
  @observable isDataLoaded = false;

  @observable toggleSeatsModal = false;
  @observable isIncluded = false;
  @observable bundleSeats: AACheckout.BundleSeats;

  tripsInfo: TripsInfo = null;
  paxTitles: AACheckout.PaxTitle[];
  conversionRates: AACheckout.ConversionRates;

  constructor() {
    makeObservable(this);
  }

  @action
  loadData = async () => {
    try {
      if (
        !(this.paxTitles && this.paxTitles.length > 0) ||
        !this.tripsInfo ||
        !(
          this.conversionRates &&
          Object.getOwnPropertyNames(this.conversionRates).length > 0
        )
      ) {
        return;
      }

      if (this.isDataLoaded || this.dataLoading) {
        return;
      }

      this.dataLoading = true;

      const tokenInfo = await userStore.getFlushedTokenInfo();
      const originalPageData = await ZodiacApi.getPageData(
        this.tripsInfo.tripIds,
        this.tripsInfo.airlineProfile,
        tokenInfo,
        ['seat'],
        ['ancillary'],
      );

      const { startPrice, seatMapData } = new SeatParser(
        originalPageData,
        this.conversionRates,
      ).parse();

      this.startPrice = startPrice;
      this.seatMapData = seatMapData;

      this.dataLoading = false;
      this.isDataLoaded = true;
    } catch (error) {
      console.log('***load seat data failed***', error);

      this.dataLoading = false;
      this.forceUpdate();

      globalMessageStore.show(
        error?.errorMessage ||
          t.flights.aaCheckout.ancillary.seat.loadDataError,
      );

      Log.errorData(error, this.CLASS_NAME, METHOD_NAMES.LOAD_DATA);
    }
  };

  @action
  onSelectedSeat = (selectedSeatData: AACheckout.SelectedSeatData) => {
    this.handleSelection(selectedSeatData);
  };

  @action
  onModelOpenedCallback = async () => {
    globalLoadingStore.close();
  };

  @action
  private handleSelection = (selectedSeatData: AACheckout.SelectedSeatData) => {
    this.selectedSeatData = selectedSeatData;
    const plainAddonItems = this.generatePlainAddonItems(selectedSeatData);

    this.plainAddonItems = plainAddonItems.filter((p) => !p.isBundled);
    this.bundlePlainAddonItems = plainAddonItems.filter((p) => p.isBundled);

    this.totalAmount = this.plainAddonItems
      .map((i) => i.price)
      .reduce((a, b) => a + b, 0);
  };

  @action
  bundledSeatCallback = async (availableSeats) => {
    // Callback when there is no available seats but bundle is selected, maybe seat are sold out.
    console.log('***availableSeats***', availableSeats);
  };

  @action
  handleBundleValid = async (isBundleValid) => {
    this.toggleSeatsModal = !isBundleValid;
  };

  private generatePlainAddonItems = (
    selectedSeatData: AACheckout.SelectedSeatData,
  ) => {
    const items: AACheckout.PlainAddonItem[] = [];

    for (const journeyKey in selectedSeatData) {
      const seats = selectedSeatData[journeyKey];
      for (const key in seats) {
        const seat = seats[key];
        const type = SEAT_TYPE_MAP[seat.seatType];

        items.push({
          id: `seat_${seat.paxId}_${journeyKey}`,
          groupBy: 'seat',
          currency: CURRENCY_DEFAULT,
          name: '座位',
          price: seat.price,
          type: 'seat',
          category: 'Seat',
          departureStation: journeyKey.split('_')[0],
          arrivalStation: journeyKey.split('_')[1],
          properties: {
            key: seat.seatKey,
            seatNumber: seat.seatNumber,
            seatType: type.seatType,
            groupType: type.groupType,
          },
          passengerReferenceId: seat.paxId,
          isBundled: seat.bundledSeat,
        });
      }
    }

    return items;
  };

  generateCheckoutAddonItems = () => {
    return [...this.plainAddonItems, ...this.bundlePlainAddonItems].map(
      (addonItem) => {
        return {
          id: addonItem.id,
          category: addonItem.category,
          designator: {
            departureStation: addonItem.departureStation,
            arrivalStation: addonItem.arrivalStation,
            departureTime: null,
            arrivalTime: null,
            departureTimeUtc: null,
            arrivalTimeUtc: null,
            arrivalTerminal: null,
            departureTerminal: null,
          },
          properties: addonItem.properties,
          passengerReferenceId: addonItem.passengerReferenceId,
        };
      },
    );
  };

  @action
  handleBundleInfluenceData = async (bundleInfluenceData) => {
    const isIncluded = !!(
      bundleInfluenceData &&
      (bundleInfluenceData.departFlight || bundleInfluenceData.returnFlight)
    );

    const getbundleSeats = (flight) => {
      return flight
        ? flight.includedSsrs[0].journeyDetails
            .map((journeyDetail) => {
              return (journeyDetail.availableUpgrade as any)?.group;
            })
            .join(',')
        : undefined;
    };

    const bundleSeats: AACheckout.BundleSeats = isIncluded
      ? {
          depart: getbundleSeats(bundleInfluenceData.departFlight),
          return: getbundleSeats(bundleInfluenceData.returnFlight),
        }
      : null;

    this.isIncluded = isIncluded;
    this.bundleSeats = bundleSeats;
    await this.clearSelection();
    await this.forceUpdate();
  };

  @action
  private clearSelection = async () => {
    this.handleSelection({});
  };

  @action
  private forceUpdate = async () => {
    return new Promise((resolve, _reject) => {
      this.forceUpdateShow = false;
      setTimeout(() => {
        this.forceUpdateShow = true;
        resolve(true);
      }, 1);
    });
  };
}
