import home from './home';
import header from './header';
import common from './common';
import error from './error';
import flights from './flights';
import hotel from './hotel';
import dutyTravel from './duty-travel';

export default {
  ...home,
  ...header,
  ...common,
  ...flights,
  ...hotel,
  ...dutyTravel,
  error,
};
