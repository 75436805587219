import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import Config from '@/config';
import HttpDecorate from './decorate';
import Proxy from './proxy';

enum Method {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
}

const instance = axios.create({
  baseURL: Config.BASE_URL,
  timeout: 5 * 60 * 1000, // 5 minute
});

const excute = async <T = any>(
  url: string,
  method: Method,
  data?: object,
  config?: AxiosRequestConfig,
) => {
  try {
    const axiosConfig: AxiosRequestConfig = {
      url,
      method,
      data,
      ...{ headers: { 'Content-Type': 'application/json' }, ...config },
    };

    if (method === Method.GET) {
      axiosConfig.params = data;
    }

    //initialize the proxy for the request URL
    const proxy = new Proxy(url);
    //change config by proxy
    proxy.config(axiosConfig);

    //change config by decorate
    const httpDecorate = new HttpDecorate();
    await httpDecorate.config(axiosConfig);

    const _request = (config: AxiosRequestConfig) =>
      instance.request<T>(config);

    try {
      let result: AxiosResponse = await _request(axiosConfig);

      // proxy response
      result = proxy.success(result);

      // decorate response
      return await httpDecorate.success(result, _request);
    } catch (error) {
      let err = error as AxiosError;

      //proxy error
      err = proxy.error(err);

      // decorate error
      return await httpDecorate.error(err, _request);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const createMethod =
  (method: Method) =>
  <T>(
    url: string,
    data?: object,
    config?: AxiosRequestConfig,
  ): Promise<any> => {
    return excute<T>(url, method, data, config);
  };

const http = {
  get: createMethod(Method.GET),
  post: createMethod(Method.POST),
  patch: createMethod(Method.PATCH),
};

export default http;
