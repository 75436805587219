import { memo } from 'react';
import { useStore } from '@/hooks';
import Middle from '@/pages/middle';

interface IProps {
  children: any;
}

function RequireAuth(props: IProps) {
  const { children } = props;
  const { userStore } = useStore();

  if (userStore.isValidated && userStore.isLogin) {
    return children;
  } else {
    return <Middle component={children} />;
  }
}

export default memo(RequireAuth);
