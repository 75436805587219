import { BaseLogHelper } from './base/base.log.helper';
import { RequestInfo, ResponseInfo } from './base/types';

export class AddonsLog extends BaseLogHelper {
  static logID = 'addons-flow';

  static logFlightDetailsSuccess(request: RequestInfo, response: ResponseInfo) {
    return this.logInfo({
      msg: `${this.logID}: flight details succeeded`,
      extra: this.getCallingDetail(request, response),
    });
  }

  static logFlightDetailsFailure(request: RequestInfo, response: ResponseInfo) {
    return this.logError({
      msg: `${this.logID}: flight details failed`,
      extra: this.getCallingDetail(request, response),
    });
  }

  static logCheckoutSuccess(request: RequestInfo, response: ResponseInfo) {
    return this.logInfo({
      msg: `${this.logID}: checkout succeeded`,
      extra: this.getCallingDetail(request, response),
    });
  }

  static logCheckoutFailure(request: RequestInfo, response: ResponseInfo) {
    return this.logError({
      msg: `${this.logID}: checkout failed`,
      extra: this.getCallingDetail(request, response),
    });
  }
}
