import i18n from '@/helper/i18n';

const t = i18n.chain;

const passengerType = {
  adult: 'adt',
  child: 'chd',
  infant: 'inft',
};

const baggageSelectorPropsMap = {
  cabin_bag: {
    baggageHeadingColor: 'secondaryBlue',
    baggageType: 'handCarry',
    baggageTypeColor: 'secondaryLight',
    showToolTip: true,
    toolTipColor: 'secondaryBlue',
  },
  hold_bag: {
    baggageHeadingColor: 'blueMedium',
    baggageType: 'checkedBaggage',
    baggageTypeColor: 'blueActive',
    toolTipTitle: t.flights.checkout.baggageSelector.checkedBaggage,
  },
};

const getBaggageData = (baggageBreakdown, paxType) => {
  const baggageList = baggageBreakdown.map((b) => {
    return {
      type: b.type,
      amount: b.unitPrice,
      currency: b.currency,
      id: b.id,
      isIncluded: b.isIncluded,
      isPreSelected: b.isPreSelected,
      title: [
        {
          weight: b.weight,
        },
      ],
      properties: b.properties,
      passengerGroups: b.passengerGroups,
    };
  });

  const baggageData = [];

  const cabinBags = baggageList.filter(
    (b) => b.type === 'cabin_bag' && b.passengerGroups.includes(paxType),
  );
  const checkedBags = baggageList.filter(
    (b) => b.type === 'hold_bag' && b.passengerGroups.includes(paxType),
  );

  if (cabinBags.length > 0) {
    baggageData.push({
      ...baggageSelectorPropsMap.cabin_bag,
      baggageList: cabinBags,
    });
  }

  if (checkedBags.length > 0) {
    baggageData.push({
      ...baggageSelectorPropsMap.hold_bag,
      baggageList: checkedBags,
    });
  }

  return baggageData;
};

export const getGuests = (
  paxDetails,
  baggageBreakdown,
  passengerDefinitions,
) => {
  const guests: OTACheckout.Passenger[] = [];

  paxDetails.map((p) => {
    for (let index = 0; index < Number(p.count); index++) {
      const paxPassportDef = passengerDefinitions[p.paxType].documents.find(
        (d) => d.type === 'passport',
      );

      const paxIdDef = passengerDefinitions[p.paxType].documents.find(
        (d) => d.type === 'id',
      );
      const supportedDocTypes = [];

      if (paxPassportDef && paxPassportDef.isRequired) {
        supportedDocTypes.push('passport');
      }

      if (paxIdDef && paxIdDef.isRequired) {
        supportedDocTypes.push('id');
      }

      guests.push({
        paxType: p.paxType,
        seq: guests.length + 1,
        subSeq: index + 1,
        hasError: false,
        referenceId: `pax_${passengerType[p.paxType]}_${index}`,
        infantRef: '',
        supportedDocTypes,
        data: {
          givenName: '',
          familyName: '',
          nationality: '',
          gender: 'Male',
          dateOfBirth: '',
          type: supportedDocTypes[0] || '',
          docNumber: '',
          expiryDate: '',
          travellingWith: '',
        },
      });
    }
  });

  guests
    .filter((g) => g.paxType === 'infant')
    .map((g, index) => {
      g.data.travellingWith = `pax_adt_${index}`;
      guests.find((a) => a.referenceId === g.data.travellingWith).infantRef =
        g.referenceId;
    });

  guests
    .filter((g) => g.paxType !== 'infant')
    .map((g) => {
      g.baggageData = getBaggageData(baggageBreakdown, g.paxType);
    });
  return guests;
};

export const getGuestsLimit = (guests: OTACheckout.Passenger[]) => {
  return {
    adult: guests.filter((g) => g.paxType === 'adult').length,
    child: guests.filter((g) => g.paxType === 'child').length,
    infant: guests.filter((g) => g.paxType === 'infant').length,
  };
};
