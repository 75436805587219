import http from '../base/http';
import Config from '@/config';

export const testZodiacSearch = async () => {
  const result: any = await http.post<any>(
    `${Config.ZODIAC.SERVICE_ENDPOINT}/fp/search/flights/v4/aggregated-results?airlineProfile=kiwi`,
    {
      consumerId: 'ListingAPP',
      flightJourney: {
        journeyType: 'O',
        journeyDetails: [
          {
            departDate: '03/02/2023',
            origin: 'KUL',
            destination: 'SIN',
          },
        ],
        passengers: {
          adult: 1,
          child: 0,
          infant: 0,
        },
      },
      searchContext: {
        promocode: ' ',
        sort: 'CHEAPEST',
        filters: {
          cabin: {
            applyMixedClasses: false,
            cabinClass: 'ECONOMY',
          },
          carriers: {
            allowAllCarriers: true,
            excludedCarriers: [],
            onlyAllowedCarriers: [],
          },
          duration: {
            maxStopoverTimeInHrs: 0,
            maxTravelTimeInHrs: 0,
          },
          stops: {
            allowOvernight: false,
            stopType: 'ANY',
          },
        },
      },
      userContext: {
        currency: 'MYR',
        geoId: 'IN',
        locale: 'en-gb',
      },
    },
    {
      headers: {
        'User-Type': 'ANONYMOUS',
        channel_hash:
          '9b8cfe8d704c6f46f174aa923e08c3cd5b19ccbef215cd76b6e91a14fbba1791',
      },
    },
  );
  return result;
};
