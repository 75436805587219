import { CURRENCY_DEFAULT } from '@/helper/constants';
import { getFareDetails } from './fare-detail';
import { getBaggageBreakdown, getComplimentaryBaggage } from './baggage';
import { getJourneyDetails } from './journey-details';
import { getCountries } from './countries';
import { getGuests, getGuestsLimit } from './guests';
import { getFamilyAndFriends } from './family-and-friends';
import i18n from '@/helper/i18n';

const t = i18n.chain;

export const transformPageData = async (
  data,
  protectedByActionCallback,
  selfTransferActionCallback,
) => {
  const {
    feeDetails = {},
    currency,
    paxDetails = [],
    baggageList = {},
    countryList,
    flightDefinitions,
    content,
  } = data;

  const { conversionRates, currencyCode: baseCurrency } = currency;
  const { flightDetails, priceChanged } = data.flightDetails;
  const { baggage = {} } = flightDetails;
  const { friendsAndFamily: originalFriendsAndFamily, userDetails } = data.sso;
  const { favoriteMembers = [] } = originalFriendsAndFamily || {};
  const { definitions } = flightDefinitions;
  const { passenger: passengerDefinitions } = definitions;

  const departureTime = flightDetails.designator.departureTime;
  const familyAndFriends = getFamilyAndFriends(
    favoriteMembers,
    userDetails,
    departureTime,
    passengerDefinitions,
  );
  const baggageBreakdown = getBaggageBreakdown(
    baggageList,
    baseCurrency,
    conversionRates,
    paxDetails,
  );

  const complimentaryBaggage = getComplimentaryBaggage(baggage);
  const journeyDetails = await getJourneyDetails(
    flightDetails,
    protectedByActionCallback,
    selfTransferActionCallback,
  );
  const countries = getCountries(countryList);
  const guests = getGuests(paxDetails, baggageBreakdown, passengerDefinitions);
  const guestsLimit = getGuestsLimit(guests);
  const fareDetails = getFareDetails(
    feeDetails,
    baseCurrency,
    conversionRates,
    guestsLimit,
  );

  return {
    baseCurrency,
    currency: CURRENCY_DEFAULT,
    familyAndFriends,
    fareDetails,
    baggage: {
      checkedBaggageAllowed: baggage ? baggage.checkedBaggageAllowed : false,
      complimentaryBaggage,
      baggageBreakdown,
    },
    conversionRates,
    journeyDetails,
    priceChanged,
    countries,
    guests,
    guestsLimit,
    documentTypes: [
      {
        label: t.flights.checkout.guestDetailsForm.passport,
        value: 'passport',
      },
      {
        label: t.flights.checkout.guestDetailsForm.id,
        value: 'id',
      },
    ],
    gender: content.gender,
    salutation: content.salutation,
  };
};
