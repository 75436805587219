import UAParser from 'ua-parser-js';

class Device {
  isMobileDevice: boolean;

  constructor() {
    const device = this._getDevice(navigator.userAgent);
    this.isMobileDevice = this._hasDeviceAttributes(device);
  }
  _hasDeviceAttributes = ({
    model,
    type,
    vendor,
  }: UAParser.IDevice): boolean => {
    return Boolean(model || type || vendor);
  };
  _getDevice = (userAgent = navigator.userAgent): string => {
    const uaParser = new UAParser(userAgent);
    return uaParser.getDevice();
  };
}
export default new Device();
