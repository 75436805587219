import React, { useCallback, useEffect, useState } from 'react';
import { Log } from '@/helper/log';

type PageProps = {
  className?: string;
  store?: PageStore | Array<PageStore>;
  initComponent?: any;
} & React.HTMLAttributes<HTMLDivElement>;

async function execute(
  store: PageStore | Array<PageStore> | undefined,
  call,
): Promise<any> {
  if (store) {
    if (Array.isArray(store)) {
      for (const s of store) {
        s[call] && (await s[call]());
      }
    } else {
      store[call] && (await store[call]());
    }
  }
}

/**
 *
 */
export default function Page(props: PageProps) {
  const { store } = props;
  const [init, setInit] = useState(!props.store);
  const InitComponent = props.initComponent;

  //刷新
  const onRefresh = useCallback(async () => {
    try {
      await execute(store, 'onLoad');
    } catch (e) {
      Log.errorData(e, (store as any)?.CLASS_NAME, 'onLoad');
    }
    setInit(true);
  }, []);

  //store卸载
  useEffect(() => {
    onRefresh();
    return () => {
      execute(store, 'onUnload');
    };
  }, []);

  if (!init) {
    return props.initComponent ? <InitComponent /> : <div></div>;
  }

  return <div {...props}></div>;
}
