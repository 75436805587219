import { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';

export interface IDecorate {
  config?: (config: AxiosRequestConfig) => void;
  success?: (
    result: AxiosResponse,
    request?: (config: AxiosRequestConfig) => any,
  ) => void;
  error?: (
    error: AxiosError,
    request?: (config: AxiosRequestConfig) => any,
  ) => void;
}

export class Decorate implements IDecorate {
  currentReRequestCount = 0;
  requestConfig: AxiosRequestConfig = {};
  async config(config: AxiosRequestConfig) {
    this.requestConfig = config;
  }
  async success(result: AxiosResponse) {
    if (result.status >= 200 && result.status < 400) {
      return result.data;
    } else {
      throw new Error('http error: ' + result.status);
    }
  }
  async error(
    error: AxiosError,
    request?: (config: AxiosRequestConfig) => any,
  ) {
    throw error;
  }
}
