export const getCountries = (countryList) => {
  const countries = countryList.countries.map((c) => {
    return {
      countryCode3C: c.countryCode3C,
      cultureCode: c.cultureCode,
      defaultCurrencyCode: c.defaultCurrencyCode,
      dialingCode: `+${c.dialingCode}`,
      iconName: '',
      mainDisplayName: `${c.name}(+${c.dialingCode})`,
      name: c.name,
      value: c.countryCode,
    };
  });

  return countries;
};
