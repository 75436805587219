import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

export default withStyles(() => ({
  tooltip: {
    backgroundColor: 'white',
    padding: '16px',
    color: '#4c4c50',
    fontSize: 12,
    lineHeight: '18px',
    border: '1px solid #d9dbe0',
    borderRadius: '6px',
    boxShadow: 'rgb(0 0 0 / 16%) 0px 4px 16px',
  },
  arrow: {
    color: 'white',
  },
}))(Tooltip);
