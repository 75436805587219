import { Log } from '@/helper/log';

export const handleGlobalErrors = () => {
  window.onerror = (message, source, lineno, colno, error) => {
    Log.errorGeneral(error || new Error(message as string), {
      source,
      lineno,
      colno,
      type: 'window.onerror',
    });
    return true;
  };

  window.addEventListener('unhandledrejection', (event) => {
    Log.errorGeneral(event.reason, {
      type: 'unhandledrejection',
    });
  });
};
