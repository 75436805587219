import i18n from '@/helper/i18n';
import { CURRENCY_DEFAULT } from '@/helper/constants';
import { convertMoneyToDefaultCurrency } from '@/helper/util/common';

const t = i18n.chain;

const baggageIcons = {
  cabin_bag: 'https://a.staticaa.com/images/icon/baggage_sml.svg',
  personal_item: 'https://a.staticaa.com/images/icon/backpack.svg',
  hold_bag: 'https://a.staticaa.com/images/icon/baggage_large.svg',
};

const baggageNames = t.flights.checkout.baggageNames;

const convertBaggageFare = (
  data,
  baseCurrency,
  conversionRates,
  paxDetails,
  type,
) => {
  return data
    ? data.map((h) => {
        const weight = `${
          h.properties.baggageDimension.find((d) => d.baggageCode === type)
            .weight.value
        } ${h.properties.baggageDimension[0].weight.unit}`;

        const displayName = baggageNames[type];
        const passengerGroups = h.rules.passengerGroups;

        const quantity =
          passengerGroups.length > 0
            ? passengerGroups
                .map((p) =>
                  Number(paxDetails.find((d) => d.paxType === p)?.count),
                )
                .reduce((a, b) => a + b)
            : 0;

        const isIncluded = h.rules.isIncluded;
        const isPreselected = h.rules.isPreselected;
        return {
          type,
          price: isIncluded
            ? convertMoneyToDefaultCurrency(
                h.price.amount * quantity,
                baseCurrency,
                conversionRates,
              )
            : 0,
          basePrice: isIncluded ? h.price.amount * quantity : 0,
          unitPrice: convertMoneyToDefaultCurrency(
            h.price.amount,
            baseCurrency,
            conversionRates,
          ),
          baseUnitPrice: h.price.amount,
          currency: CURRENCY_DEFAULT,
          weight,
          displayName,
          quantity: isIncluded ? quantity : 0,
          isIncluded,
          isPreselected,
          properties: h.properties,
          passengerGroups,
          id: h.id,
        };
      })
    : [];
};

export const getBaggageBreakdown = (
  baggageList,
  baseCurrency,
  conversionRates,
  paxDetails,
) => {
  const baggageBreakdown = [];
  if (baggageList?.data?.length > 0) {
    const { ancillarylist } = baggageList.data[0];
    baggageBreakdown.push(
      ...convertBaggageFare(
        ancillarylist.hand,
        baseCurrency,
        conversionRates,
        paxDetails,
        'cabin_bag',
      ),
    );
    baggageBreakdown.push(
      ...convertBaggageFare(
        ancillarylist.hold,
        baseCurrency,
        conversionRates,
        paxDetails,
        'hold_bag',
      ),
    );
  }

  return baggageBreakdown;
};

export const getComplimentaryBaggage = (baggage) => {
  const complimentaryBaggage =
    baggage && baggage.complimentaryBaggage
      ? baggage.complimentaryBaggage.map((b) => {
          return {
            iconName: baggageIcons[b.type],
            mainDisplayName: baggageNames[b.type],
            value: `${b.weight} kg`,
            mainDisplayNameQty: b.count,
          };
        })
      : [];

  return complimentaryBaggage;
};
