import i18n from '@/helper/i18n';
import dayjs from 'dayjs';

import { getStationInfo } from '../station';

const t = i18n.chain;

const convertJourneyDetails = async (
  journey,
  protectedByActionCallback,
  selfTransferActionCallback,
) => {
  if (journey) {
    const departure = await getStationInfo(journey.designator.departureStation);
    const arrival = await getStationInfo(journey.designator.arrivalStation);

    const segments = await Promise.all(
      journey.segments.map(async (s, i) => {
        const segDeparture = await getStationInfo(
          s.designator.departureStation,
        );
        const segArrival = await getStationInfo(s.designator.arrivalStation);

        const marketingCarrierCode = s.marketingCarrierCode || s.carrierCode;
        const marketingCarrierName = s.marketingAirlineName || s.airlineName;
        const marketingFlightNumber =
          s.marketingFlightNo ||
          s.marketingFlightNumber ||
          s.flightNo ||
          s.flightNumber;

        let operatingCarrierCode = s.operatingCarrierCode;
        let operatingCarrierName = s.operatingAirlineName;
        let operatingFlightNumber =
          s.operatingFlightNo || s.operatingFlightNumber;

        if (
          marketingCarrierCode === operatingCarrierCode &&
          marketingFlightNumber === operatingFlightNumber
        ) {
          operatingCarrierCode = '';
          operatingCarrierName = '';
          operatingFlightNumber = '';
        }

        const arrivalTime = dayjs(s.designator.arrivalTimeUtc);
        const departureTime = dayjs(s.designator.departureTimeUtc);

        const nextSegment = journey.segments[i - 1];
        let layoverTime = s.layoverTime;

        if (!layoverTime && nextSegment) {
          const lastArrivalTime = dayjs(nextSegment.designator.arrivalTimeUtc);
          layoverTime = departureTime.diff(lastArrivalTime) / 1000;
        }

        return {
          designator: {
            ...s.designator,
            departureStationCode: s.designator.departureStation,
            arrivalStationCode: s.designator.arrivalStation,
            arrivalTime: s.designator.arrivalTime.replace('.000Z', ''),
            departureTime: s.designator.departureTime.replace('.000Z', ''),
            departureAirport: `${segDeparture.airportName}(${s.designator.departureStation})`,
            departureStation: segDeparture.stationName,
            arrivalAirport: `${segArrival.airportName}(${s.designator.arrivalStation})`,
            arrivalStation: segArrival.stationName,
          },
          carrier: {
            cabinClass: s.fareClass,
            image: `https://images.kiwi.com/airlines/64/${s.marketingCarrierCode}.png?default=airline.png`,
            marketingCarrierCode,
            marketingCarrierName,
            marketingFlightNumber,
            operatingCarrierCode,
            operatingCarrierName,
            operatingFlightNumber,
          },
          duration: s.duration || arrivalTime.diff(departureTime) / 1000,
          layoverTime,
          isProtected: s.connectionProtectedByAirline,
          isSelfTransfer: s.isSelfTransfer,
          protectedBy: s.connectionProtectedByAirline
            ? t.flights.checkout.journey.carrier
            : '',
          protectedByActionCallback: function noRefCheck() {
            protectedByActionCallback && protectedByActionCallback();
          },
          selfTransferActionCallback: function noRefCheck() {
            selfTransferActionCallback && selfTransferActionCallback();
          },
        };
      }),
    );

    return {
      totalDaysCount: journey.totalDaysCount,
      designator: {
        departureStationCode: journey.designator.departureStation,
        arrivalStationCode: journey.designator.arrivalStation,
        arrivalTime: journey.designator.arrivalTime.replace('.000Z', ''),
        departureTime: journey.designator.departureTime.replace('.000Z', ''),
        departureAirport: `${departure.airportName}(${journey.designator.departureStation})`,
        departureStation: departure.stationName,
        arrivalAirport: `${arrival.airportName}(${journey.designator.arrivalStation})`,
        arrivalStation: arrival.stationName,
      },
      images: [
        ...new Set(
          journey.segments.map(
            (s) =>
              `https://images.kiwi.com/airlines/64/${s.marketingCarrierCode}.png?default=airline.png`,
          ),
        ),
      ],
      segments,
    };
  }
};

export const getJourneyDetails = async (
  flightDetails,
  protectedByActionCallback,
  selfTransferActionCallback,
) => {
  const journeyDetails = [];

  if (flightDetails.depart) {
    journeyDetails.push(
      await convertJourneyDetails(
        flightDetails.depart,
        protectedByActionCallback,
        selfTransferActionCallback,
      ),
    );
  }

  if (flightDetails.return) {
    journeyDetails.push(
      await convertJourneyDetails(
        flightDetails.return,
        protectedByActionCallback,
        selfTransferActionCallback,
      ),
    );
  }

  return journeyDetails;
};
