import {
  roundOffPrice,
  getHeroImage,
  makeImageCarouselFromImages,
} from './utils';
import { THUMBNAIL_SIZE, ROOMS_LEFT_TAG } from './constants';

const getTagTranslation = ({ id, value = '', data = {} }) => {
  if (!id) {
    return '';
  }
  const { number_of_rooms: numberOfRooms } = data as any;
  if (numberOfRooms && numberOfRooms > 0) {
    return { translationId: `tags.${id}`, numberOfRooms, value };
  } else if (id !== ROOMS_LEFT_TAG) {
    return { translationId: `tags.${id}`, value };
  }
  return value ? { value } : '';
};

const tagsReducer = (result, tag) => {
  tag && result.push(getTagTranslation(tag));
  return result;
};

export const reduceHotels = (hotels) => {
  const result = [];

  hotels.forEach((hotel, index) => {
    const {
      id,
      name,
      prices,
      gallery,
      star_rating,
      debug,
      guest_rating,
      headline,
      tags,
      is_hotel_selected: isHotelSelected,
      tripadvisor,
      location,
      amenities,
      distance_from_poi,
    } = hotel;
    const {
      id: discountId,
      type: discountType,
      description: discountDescription,
    } = prices.rate.discount || {};
    const {
      base_rate: baseRate,
      strike_through: strikethroughPrice,
      price_after_deducting_big_points: baseRateAfterBigPointsDeduction,
    } = prices.rate.lead_price || {};
    if (baseRate && baseRate.value) {
      baseRate.value = roundOffPrice(baseRate.value);
    }
    if (strikethroughPrice && strikethroughPrice.value) {
      strikethroughPrice.value = roundOffPrice(strikethroughPrice.value);
    }
    if (
      baseRateAfterBigPointsDeduction &&
      baseRateAfterBigPointsDeduction.value
    ) {
      baseRateAfterBigPointsDeduction.value = roundOffPrice(
        baseRateAfterBigPointsDeduction.value,
      );
    }
    const { big_life: bigPoint } = prices.rate || {};
    const { images } = gallery || {};
    const starRating =
      star_rating &&
      star_rating.rating &&
      star_rating.rating.length &&
      parseFloat(star_rating.rating);
    const {
      rating: guestRating,
      count: guestRatingCount,
      amenities: amenitiesRating,
      cleanliness: cleanlinessRating,
      comfort: comfortRating,
      condition: conditionRating,
      location: locationRating,
      neighbourhood: neighbourhoodRating,
      quality: qualityRating,
      service: serviceRating,
      value: valueRating,
    } = guest_rating || {};
    const {
      label_above: labelAbove,
      left_view: leftView,
      right_view: rightView,
    } = tags || {};
    const aboveNameLabels = labelAbove && labelAbove.reduce(tagsReducer, []);
    const leftPillTexts = leftView && leftView.reduce(tagsReducer, []);
    const rightPillTexts = rightView && rightView.reduce(tagsReducer, []);

    const image = getHeroImage(images, THUMBNAIL_SIZE);
    const { type: paymentDiscountType } = prices.rate.discount || {};
    const isCrossSellRate = prices.rate?.cross_sell_rate;
    const imagesCarousel = makeImageCarouselFromImages(images, THUMBNAIL_SIZE);
    const {
      pay_at_hotel_available: isPayAtHotel,
      buy_now_pay_later_available: isPayLater,
    } = prices;

    result.push({
      id,
      name,
      baseRate,
      strikethroughPrice,
      image,
      starRating,
      debug,
      guestRating,
      guestRatingCount,
      amenitiesRating,
      cleanlinessRating,
      comfortRating,
      conditionRating,
      locationRating,
      neighbourhoodRating,
      qualityRating,
      serviceRating,
      valueRating,
      aboveNameLabels,
      leftPillTexts,
      rightPillTexts,
      discountId,
      discountType,
      discountDescription,
      distancePoi: distance_from_poi,
      tags,
      location: headline,
      recommended: index,
      bigPoint,
      paymentDiscountType,
      isPayAtHotel,
      isCrossSellRate,
      isHotelSelected,
      images: imagesCarousel,
      baseRateAfterBigPointsDeduction,
      tripadvisor,
      coordinates: location,
      isPayLater,
      amenities,
    });
  });

  return result;
};
