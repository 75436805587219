export const convertToENDate = (origin) =>
  origin
    ? `${origin.substring(0, 2)}/${origin.substring(2, 4)}/${origin.substring(
        4,
        8,
      )}`
    : '';

export const convertToCNDate = (origin) =>
  origin
    ? `${origin.substring(4, 8)}-${origin.substring(2, 4)}-${origin.substring(
        0,
        2,
      )}`
    : '';
