import { action, makeObservable, observable } from 'mobx';

export default class SessionTimerStore {
  @observable isShowPopup = false;
  @observable endDate: string;

  constructor() {
    makeObservable(this);
  }

  // set a new future date
  @action
  setNewEndDate(minutes) {
    this.endDate = this.getFutureDate(minutes);
  }

  formatNumber(number) {
    return number.toString().padStart(2, '0');
  }

  getLocalISOTime(date = new Date()) {
    const year = date.getFullYear();
    const month = this.formatNumber(date.getMonth() + 1);
    const day = this.formatNumber(date.getDate());
    const hours = this.formatNumber(date.getHours());
    const minutes = this.formatNumber(date.getMinutes());
    const seconds = this.formatNumber(date.getSeconds());

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  // calculate the future date
  getFutureDate(minutes) {
    const newDate = new Date(new Date().getTime() + minutes * 60000);

    return this.getLocalISOTime(newDate);
  }

  @action
  timeEndCallback() {
    const currentDate = new Date();
    const endDate = new Date(this.endDate);

    if (this.endDate && currentDate >= endDate) {
      this.isShowPopup = true;
    }
  }

  searchAgain() {
    (window as any).wx.miniProgram.navigateBack();
  }
}
