import { CURRENCY_DEFAULT } from '@/helper/constants';

export const isUrl = (url: string): boolean => {
  return /http[s]{0,1}:\/\/([\w.]+\/?)\S*/.test(url);
};

export const isEmail = (address: string): boolean => {
  return /@/.test(address);
};

export const round = (number: number, v = 2) => {
  const vv = Math.pow(10, v);
  return Math.round(number * vv) / vv;
};

export const floor = (number: number, v = 2) => {
  const vv = Math.pow(10, v);
  return Math.floor(number * vv) / vv;
};

export const ceil = (number: number, v = 2) => {
  const vv = Math.pow(10, v);
  return Math.ceil(number * vv) / vv;
};

function addLimiter(value: string) {
  return value.replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export function roundToFixed(value: number, v = 2, limiter = true) {
  if (limiter) {
    return addLimiter(round(value, v).toFixed(2));
  } else {
    return round(value, v).toFixed(2);
  }
}

export function floorToFixed(value: number, v = 2, limiter = true) {
  if (limiter) {
    return addLimiter(floor(value, v).toFixed(2));
  } else {
    return floor(value, v).toFixed(2);
  }
}

export function ceilToFixed(value: number, v = 2, limiter = true) {
  if (limiter) {
    return addLimiter(ceil(value, v).toFixed(2));
  } else {
    return ceil(value, v).toFixed(2);
  }
}

export const jsonToQuery = (obj) => {
  return new URLSearchParams(obj).toString();
};

export const openUrl = (
  href,
  target: '_self' | '_blank' | '_parent' | '_top' = '_self',
) => {
  Object.assign(document.createElement('a'), {
    target,
    href,
  }).click();
};

export const getURLParams = () => {
  return new URLSearchParams(location.search);
};

export const coustomInterval = (callback, interval = 5000) => {
  let timer;
  let isStop = false;
  const stop = () => {
    isStop = true;
    clearTimeout(timer);
  };
  const start = async () => {
    isStop = false;
    return await loop();
  };
  const loop = async () => {
    let res;
    try {
      res = await callback(stop);
    } catch (err) {
      throw new Error(err);
    }

    if (isStop) return res;

    //return (timer = setTimeout(loop, interval))
    return await new Promise(
      (resolve) =>
        (timer = setTimeout(async () => {
          const res = await loop();
          resolve(res);
        }, interval)),
    );
  };
  return {
    start,
    stop,
  };
};

export const convertMoneyToDefaultCurrency = (
  baseAmount,
  baseCurrency,
  conversionRates,
) => {
  if (baseCurrency === CURRENCY_DEFAULT) {
    return baseAmount;
  } else {
    return baseAmount * conversionRates[CURRENCY_DEFAULT];
  }
};

export function groupByKey<T>(
  array: T[],
  key,
): {
  [key: string]: T[];
} {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    });
  }, {});
}

export function traverseObj(obj, keys, callBack) {
  if (typeof obj === 'object' || Array.isArray(obj)) {
    for (const prop in obj) {
      keys.includes(prop) && callBack(obj, prop);
      if (typeof obj[prop] === 'object' || Array.isArray(obj[prop])) {
        traverseObj(obj[prop], keys, callBack);
      }
    }
  }
  return obj;
}

export function toArray<T>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : [value];
}
