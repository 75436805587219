import React from 'react';
import { ROUTE_URL } from '@/helper/constants';
import { NoneLayout } from '@/components';

const Test2 = React.lazy(
  () => import(/* webpackChunkName: "demo-test2" */ '@/pages/demo/test2'),
);

export default [
  {
    path: `${ROUTE_URL.ROOT}/demo/test2`,
    component: Test2,
    isAuth: false,
    layout: NoneLayout,
  },
];
