import { createContext } from 'react';
import * as BaseStores from './base';
import * as CommonStores from './common';
import DemoStore from './demo';
import HomeStore from './home';
import IfameStore from './iframe';
import FlightsCheckoutStore from './flights/checkout';
import AACheckoutStore from './aa/checkout';
import HotelSearchStore from './hotel/search';
import HotelDetailStore from './hotel/detail';
import HotelGuestDetailStore from './hotel/guest-detail';
import HotelConfirmationStore from './hotel/confirmation';
import { configure } from 'mobx';

configure({
  enforceActions: 'never',
});

export const Store = {
  ...BaseStores,
  ...CommonStores,
  demoStore: new DemoStore(),
  homeStore: new HomeStore(),
  iframeStore: new IfameStore(),
  flightsCheckoutStore: new FlightsCheckoutStore(),
  aaCheckoutStore: new AACheckoutStore(),
  hotelSearchStore: new HotelSearchStore(),
  hotelDetailStore: new HotelDetailStore(),
  hotelGuestDetailStore: new HotelGuestDetailStore(),
  hotelConfirmationStore: new HotelConfirmationStore(),
};

export const storeContext = createContext(Store);
export const StoreProvider = storeContext.Provider;
