import { action, observable, makeObservable } from 'mobx';
import { HotelApi } from '@/api';
import { userStore } from '@/store/base';
import { getKeyFromUrl } from '@/helper/hotel/utils';
import { transformHotelOrder } from '@/helper/hotel/hotel-order';
import { transformHotelContentData } from '@/helper/hotel/hotel-content';
import { BOOKING_STATUS_COLOR } from '@/helper/hotel/booking';
import { theme } from '@/helper/constants';

export default class HotelConfirmationStore implements PageStore {
  @observable isLoading = true;
  @observable hasError = false;
  @observable key = '';
  @observable hotelContent: Hotel.HotelContentInfo = null;
  @observable order = null;
  @observable headerStyle = null;

  constructor() {
    makeObservable(this);
  }

  @action
  onLoad = async () => {
    // const params = new URLSearchParams(location.search);
    // this.key = params.get('key');
    this.key = getKeyFromUrl(location.search);
    this.loadData();
  };

  @action
  onUnload = () => {};

  resetData = () => {
    const { layout } = this.order;

    const headerStyle = {
      backgroundColor: 'primaryWhite',
      headerColor: 'darkBlack',
    };

    switch (layout.color) {
      case BOOKING_STATUS_COLOR.CONFIRMED:
      case BOOKING_STATUS_COLOR.SUCCESS:
        headerStyle.backgroundColor = 'tertiaryGreenLighter';
        headerStyle.headerColor = 'tertiaryGreen';
        break;
      case BOOKING_STATUS_COLOR.FAILED:
        headerStyle.backgroundColor = 'primaryLight';
        headerStyle.headerColor = 'primary';
        break;
      case BOOKING_STATUS_COLOR.WARNING:
        headerStyle.backgroundColor = '#d97f001a';
        headerStyle.headerColor = 'tertiaryYellow';
        break;
      default:
    }

    headerStyle.backgroundColor = theme.colors[headerStyle.backgroundColor]
      ? theme.colors[headerStyle.backgroundColor]
      : headerStyle.backgroundColor;

    headerStyle.headerColor = theme.colors[headerStyle.headerColor]
      ? theme.colors[headerStyle.headerColor]
      : headerStyle.headerColor;

    this.headerStyle = headerStyle;
  };

  loadData = async () => {
    this.hotelContent = null;
    this.isLoading = true;
    this.hasError = false;

    try {
      await this.loadOrder();
      await this.loadHotelContent();

      this.resetData();
    } catch (error) {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  };

  loadOrder = async () => {
    const result = await HotelApi.getOrderByKey<any>(this.key);
    this.order = transformHotelOrder(result);
  };

  loadHotelContent = async () => {
    const hotelContent = await HotelApi.getHotelContent<any>(
      this.order?.hotelId,
      userStore.locale,
    );
    this.hotelContent = transformHotelContentData(hotelContent);
  };
}
