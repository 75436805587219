import Config from '@/config';
import { action, makeObservable } from 'mobx';
import { ZodiacApi } from '@/api';
import { Log } from '@/helper/log';
import { AxiosError } from 'axios';
import { Payment } from '@/helper/constants';
import { LOCALE_CODE_DEFAULT } from '@/helper/constants';
import { Zodiac } from '@/helper/constants';
import { generateGaId } from '@/api/zodiac';

type ComposeCheckoutData = {
  selectedAncillaries: AACheckout.CheckoutAddonItem[];
  guestsAndContact: any;
  fareSummary: any;
  flightDetails: any;
  loggedInUserInfo: any;
  tripsInfo: TripsInfo;
  [key: string]: any;
};

class CheckoutStore {
  constructor() {
    makeObservable(this);
  }

  @action
  async performCheckout(data: ComposeCheckoutData) {
    try {
      // 1. organize checkout data
      const checkoutData = await this.composeCheckoutData(data);

      // 2. call the checkout API
      const res = await ZodiacApi.checkoutV3(checkoutData);
      if (!res) {
        return;
      }

      // 3. redirect to payment page on success
      if (res.successRedirectionUrl) {
        this.redirectToPaymentPage(res.successRedirectionUrl);
        return;
      }
    } catch (error) {
      console.error(error);
      this.handleCheckoutError(error);
    }
  }

  async composeCheckoutData(data: ComposeCheckoutData) {
    const {
      guestsAndContact,
      fareSummary,
      flightDetails,
      loggedInUserInfo,
      tripsInfo,
      selectedAncillaries,
    } = data;

    const userId = loggedInUserInfo.ssoDetails.userId;

    const param = {
      ...guestsAndContact,
      ...flightDetails,
      ...fareSummary,
      loggedInUserInfo,
      geoId: Zodiac.GEO_ID,
      locale: LOCALE_CODE_DEFAULT,
      airlineProfile: tripsInfo.airlineProfile,
      tripId: tripsInfo.tripIds,
      emergencyContact: null,
      mailingAddress: null,
      companyDetails: null,
      selectedAncillaries,
      clientInfo: {
        platform: 'Mobile',
        cleverTapId: null,
        gaId: generateGaId(userId),
      },
      redirectionLinks: {
        selectPage: Config.AIRASIA_WMP_H5,
        confirmationPage: Config.AIRASIA_WMP_H5,
      },
      favouriteMembers: null,
    };

    return param;
  }

  redirectToPaymentPage(url) {
    const param = new URLSearchParams();
    param.set('data', url);
    (window as any).wx.miniProgram.navigateTo({
      url: `${Payment.PAYMENT_PAGE_AA}?${param.toString()}`,
    });
  }

  @action
  handleCheckoutError(error) {
    // extract error message
    const errorMessage = this.extractErrorMessage(error);

    if (errorMessage) {
      throw new Error(errorMessage);
    } else {
      throw error;
    }
  }

  extractErrorMessage(error) {
    if (error instanceof AxiosError) {
      return error.response?.data?.error?.messagee;
    }
    return null;
  }
}

export default CheckoutStore;
