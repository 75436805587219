import i18n from '@/helper/i18n';
import dayjs from 'dayjs';

import { getStationInfo } from '@/helper/station';

const t = i18n.chain;

async function convertJourneyDetails(
  journey,
  protectedByActionCallback,
  selfTransferActionCallback,
) {
  if (journey) {
    const departure = await getStationInfo(journey.designator.departureStation);
    const arrival = await getStationInfo(journey.designator.arrivalStation);

    const segments = await Promise.all(
      journey.segments.map(async (s, i) => {
        const segDeparture = await getStationInfo(
          s.designator.departureStation,
        );
        const segArrival = await getStationInfo(s.designator.arrivalStation);

        const marketingCarrierCode = s.marketingCarrierCode || s.carrierCode;
        const marketingCarrierName = s.marketingAirlineName || s.airlineName;
        const marketingFlightNumber =
          s.marketingFlightNo ||
          s.marketingFlightNumber ||
          s.flightNo ||
          s.flightNumber;

        let operatingCarrierCode = s.operatingCarrierCode;
        let operatingCarrierName = s.operatingAirlineName;
        let operatingFlightNumber =
          s.operatingFlightNo || s.operatingFlightNumber;

        if (
          marketingCarrierCode === operatingCarrierCode &&
          marketingFlightNumber === operatingFlightNumber
        ) {
          operatingCarrierCode = '';
          operatingCarrierName = '';
          operatingFlightNumber = '';
        }

        const arrivalTime = dayjs(s.designator.arrivalTimeUtc);
        const departureTime = dayjs(s.designator.departureTimeUtc);

        const nextSegment = journey.segments[i - 1];
        let layoverTime = s.layoverTime;

        if (!layoverTime && nextSegment) {
          const lastArrivalTime = dayjs(nextSegment.designator.arrivalTimeUtc);
          layoverTime = departureTime.diff(lastArrivalTime) / 1000;
        }

        return {
          designator: {
            departureStationCode: s.designator.departureStation,
            departureTime: s.designator.departureTime.replace('.000Z', ''),
            departureAirport: `${segDeparture.airportName}(${s.designator.departureStation})`,
            departureStation: segDeparture.stationName,
            departureCountryCode: segDeparture.countryCode,
            departureCountry: segDeparture.countryName,
            arrivalStationCode: s.designator.arrivalStation,
            arrivalTime: s.designator.arrivalTime.replace('.000Z', ''),
            arrivalAirport: `${segArrival.airportName}(${s.designator.arrivalStation})`,
            arrivalStation: segArrival.stationName,
            arrivalCountryCode: segArrival.countryCode,
            arrivalCountry: segArrival.countryName,
          },
          originalDesignator: s.designator,
          carrier: {
            cabinClass: s.fareClass,
            image: `https://images.kiwi.com/airlines/64/${s.marketingCarrierCode}.png?default=airline.png`,
            marketingCarrierCode,
            marketingCarrierName,
            marketingFlightNumber,
            operatingCarrierCode,
            operatingCarrierName,
            operatingFlightNumber,
          },
          duration: s.duration || arrivalTime.diff(departureTime) / 1000,
          layoverTime,
          isProtected: s.connectionProtectedByAirline,
          isSelfTransfer: s.isSelfTransfer,
          protectedBy: s.connectionProtectedByAirline
            ? t.flights.checkout.journey.carrier
            : '',
          protectedByActionCallback: function noRefCheck() {
            protectedByActionCallback && protectedByActionCallback();
          },
          selfTransferActionCallback: function noRefCheck() {
            selfTransferActionCallback && selfTransferActionCallback();
          },
        };
      }),
    );

    return {
      totalDaysCount: journey.totalDaysCount,
      designator: {
        departureStationCode: journey.designator.departureStation,
        arrivalStationCode: journey.designator.arrivalStation,
        arrivalTime: journey.designator.arrivalTime.replace('.000Z', ''),
        departureTime: journey.designator.departureTime.replace('.000Z', ''),
        departureAirport: `${departure.airportName}(${journey.designator.departureStation})`,
        departureStation: departure.stationName,
        arrivalAirport: `${arrival.airportName}(${journey.designator.arrivalStation})`,
        arrivalStation: arrival.stationName,
      },
      originalDesignator: journey.designator,
      images: [
        ...new Set(
          journey.segments.map(
            (s) =>
              `https://images.kiwi.com/airlines/64/${s.marketingCarrierCode}.png?default=airline.png`,
          ),
        ),
      ],
      segments,
    };
  }
}

async function getJourneyDetails(
  pageData,
  protectedByActionCallback,
  selfTransferActionCallback,
) {
  const { flightDetails } = pageData.flightDetails;

  const journeyDetails = [];

  if (flightDetails.depart) {
    journeyDetails.push(
      await convertJourneyDetails(
        flightDetails.depart,
        protectedByActionCallback,
        selfTransferActionCallback,
      ),
    );
  }

  if (flightDetails.return) {
    journeyDetails.push(
      await convertJourneyDetails(
        flightDetails.return,
        protectedByActionCallback,
        selfTransferActionCallback,
      ),
    );
  }

  return journeyDetails;
}

function getJourneys(journeyDetails): AACheckout.Journey[] {
  return journeyDetails.map((j, i) => {
    const { designator, originalDesignator, segments } = j;

    return {
      designator: originalDesignator,
      destination: {
        stationCode: designator.arrivalStationCode,
        stationName: designator.arrivalStation,
      },
      origin: {
        stationCode: designator.departureStationCode,
        stationName: designator.departureStation,
      },
      segments: segments.map((segment) => ({
        arrivalStation: segment.designator.arrivalStationCode,
        arrivalStationName: segment.designator.arrivalStation,
        departureStation: segment.designator.departureStationCode,
        departureStationName: segment.designator.departureStation,
      })),
      tripType: i === 0 ? 'depart' : 'return',
    };
  });
}

function getStationDetails(journeyDetails: AACheckout.JourneyDetail[]) {
  const stationDetails: AACheckout.StationDetails = {};

  journeyDetails.map((journeyDetail) => {
    const { segments } = journeyDetail;
    segments.map((segment) => {
      const { designator } = segment;

      const {
        departureAirport,
        departureCountry,
        departureCountryCode,
        departureStation,
        departureStationCode,
        arrivalAirport,
        arrivalCountry,
        arrivalCountryCode,
        arrivalStation,
        arrivalStationCode,
      } = designator;

      !(departureStationCode in stationDetails) &&
        (stationDetails[departureStationCode] = {
          AirportName: departureAirport,
          StationName: departureStation,
          CountryCode: departureCountryCode,
          CountryName: departureCountry,
        });

      !(arrivalStationCode in stationDetails) &&
        (stationDetails[arrivalStationCode] = {
          AirportName: arrivalAirport,
          StationName: arrivalStation,
          CountryCode: arrivalCountryCode,
          CountryName: arrivalCountry,
        });
    });
  });

  return stationDetails;
}

export const transform = async (
  pageData,
  protectedByActionCallback,
  selfTransferActionCallback,
) => {
  const journeyDetails: AACheckout.JourneyDetail[] = await getJourneyDetails(
    pageData,
    protectedByActionCallback,
    selfTransferActionCallback,
  );

  const journeys: AACheckout.Journey[] = getJourneys(journeyDetails);

  const stationDetails: AACheckout.StationDetails =
    getStationDetails(journeyDetails);

  return {
    journeyDetails,
    journeys,
    stationDetails,
  };
};
