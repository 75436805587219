import { mapGuestTypes, paxTypes } from '@/helper/constants/aa-checkout';
import dayjs from 'dayjs';
import { convertToCNDate, convertToENDate } from './utils';

const getPaxType = (dateOfBirth, departureTime, passengerDefinitions) => {
  const infantMax = passengerDefinitions.infant.maxAge || 2;
  const childMax = passengerDefinitions.child.maxAge || 12;
  const yptaDocument = (
    passengerDefinitions[paxTypes.adult]?.documents || []
  ).find((d) => d.type === paxTypes.ypta);

  const dobObj = dayjs(convertToCNDate(dateOfBirth));
  const depObj = dayjs(departureTime);
  if (dobObj.add(infantMax, 'years').isAfter(depObj)) {
    return {
      paxType: paxTypes.infant,
      subPaxType: paxTypes.infant,
    };
  } else if (dobObj.add(childMax, 'years').isAfter(depObj)) {
    return {
      paxType: paxTypes.child,
      subPaxType: paxTypes.child,
    };
  } else if (
    yptaDocument &&
    dobObj.add(yptaDocument.maxAge, 'years').isAfter(depObj)
  ) {
    return {
      paxType: paxTypes.adult,
      subPaxType: paxTypes.ypta,
    };
  } else {
    return {
      paxType: paxTypes.adult,
      subPaxType: paxTypes.adult,
    };
  }
};

function getSelf(
  userDetails,
  departureTime,
  passengerDefinitions,
): AACheckout.FamilyAndFriend {
  const dateOfBirth = userDetails.dateOfBirth
    ? convertToENDate(userDetails.dateOfBirth)
    : '';

  const { paxType, subPaxType } = userDetails.dateOfBirth
    ? getPaxType(userDetails.dateOfBirth, departureTime, passengerDefinitions)
    : { paxType: paxTypes.adult, subPaxType: paxTypes.adult };

  return {
    bigMemberId: userDetails.id,
    givenName: userDetails.firstName,
    paxType,
    subPaxType,
    guestType: mapGuestTypes[paxType],
    isSelected: true,
    surname: userDetails.lastName,
    nationality: userDetails.nationality,
    gender:
      userDetails.gender.charAt(0).toUpperCase() + userDetails.gender.slice(1),
    dateOfBirth,
    docNumber: '',
    expiryDate: '',
    email: userDetails.username,
    loyaltyId: userDetails.loyaltyId,
    loyaltyTier: userDetails.loyaltyTier,
  };
}

export function getFamilyAndFriends(
  favoriteMembers,
  userDetails,
  departureTime,
  passengerDefinitions,
): AACheckout.FamilyAndFriend[] {
  const self = getSelf(userDetails, departureTime, passengerDefinitions);

  const familyAndFriends: AACheckout.FamilyAndFriend[] = [self];

  favoriteMembers.map((m) => {
    const gender = m.gender.charAt(0).toUpperCase() + m.gender.slice(1);
    const dateOfBirth = convertToENDate(m.dateOfBirth);
    const expiryDate = convertToENDate(m.expirationDate);
    const { paxType, subPaxType } = getPaxType(
      m.dateOfBirth,
      departureTime,
      passengerDefinitions,
    );

    familyAndFriends.push({
      bigMemberId: m.id,
      givenName: m.firstName,
      paxType,
      subPaxType,
      guestType: mapGuestTypes[paxType],
      isSelected: false,
      surname: m.lastName,
      nationality: m.nationality,
      gender,
      dateOfBirth,
      docNumber: m.docNo,
      expiryDate,
      email: '',
      loyaltyId: '',
      loyaltyTier: '',
    });
  });

  return familyAndFriends;
}
