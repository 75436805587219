import { parseISO, compareAsc, format } from 'date-fns';
import { REFUND_DATE_FORMAT } from './constants';
import {
  CANCELLATION_REFUND_TYPE,
  CANCELLATION_PENALTY_TYPES,
} from './booking';

const formatTime = (day, timeFormat) => format(day, timeFormat);
const formatRefundDate = (day) => formatTime(day, REFUND_DATE_FORMAT);

export const parseCancellationDate = (dateString) => {
  if (!dateString) {
    return '';
  }

  const date = parseISO(dateString);
  return formatRefundDate(date);
};

export const isActivePenalty = ({ start, end, serverTime = new Date() }) => {
  const startDate = start ? parseISO(start).setMilliseconds(0) : serverTime;
  const endDate = parseISO(end);
  return startDate <= serverTime && serverTime <= endDate;
};

export const filterPenalties = (penalties, checkout, today = new Date()) =>
  penalties
    .filter((penalty) => {
      const { end = checkout } = penalty;
      const endDate = parseISO(end);
      return endDate >= today;
    })
    .sort((penaltyLeft, penaltyRight) => {
      const { end: endLeft = checkout } = penaltyLeft;
      const { end: endRight = checkout } = penaltyRight;
      const endDateLeft = parseISO(endLeft);
      const endDateRight = parseISO(endRight);

      return compareAsc(endDateLeft, endDateRight);
    });

export const getRefundType = (activePenalty) => {
  if (activePenalty === CANCELLATION_PENALTY_TYPES.NONE) {
    return CANCELLATION_REFUND_TYPE.FULL_REFUND;
  }

  if (activePenalty === CANCELLATION_PENALTY_TYPES.PARTIAL) {
    return CANCELLATION_REFUND_TYPE.PARTIAL_REFUND;
  }

  if (activePenalty === CANCELLATION_PENALTY_TYPES.FULL) {
    return CANCELLATION_REFUND_TYPE.NO_REFUND;
  }

  return 'undefined';
};
