import { action, makeObservable, observable } from 'mobx';

type MessageType =
  | 'info'
  | 'error'
  | 'warning'
  | 'success'
  | 'newWarning'
  | 'newInfo'
  | 'newNotif';

class MessageStore {
  @observable showMessage = false;
  @observable message = '';
  @observable type: MessageType = 'error';
  @observable duration = 8000;

  constructor() {
    makeObservable(this);
  }

  @action
  show = (message: string, duration = 10000, type: MessageType = 'error') => {
    this.message = message;
    this.duration = duration;
    this.type = type;
    this.showMessage = true;
  };

  @action
  onClose = () => {
    this.showMessage = false;
  };
}

export default new MessageStore();
