import i18n from '@/helper/i18n';
import { useState } from 'react';

import './index.scss';

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  /**
   * default value is 1
   */
  showElements?: number;
}

const Index = (props: IProps) => {
  const t = i18n.use();
  const [showMore, setShowMore] = useState(false);
  const { showElements = 1, children, ...others } = props;
  const switchShowMore = () => {
    setShowMore(!showMore);
  };

  if (Array.isArray(children)) {
    const displayElements = children.filter((_m, i) => i < showElements);
    const hideElements = children.filter((_m, i) => i >= showElements);

    return (
      <div className="show-more" {...others}>
        {displayElements}
        {showMore && hideElements}
        {hideElements.length > 0 && (
          <div className="show-more-link" onClick={switchShowMore}>
            {showMore ? t.hotel.common.showLess : t.hotel.common.showMore}
          </div>
        )}
      </div>
    );
  } else {
    return <div {...props}></div>;
  }
};

export default Index;
