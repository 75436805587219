function getTotalPax(paxDetails: AACheckout.PaxDetail[]): number {
  return paxDetails.reduce((total, pax) => {
    if (pax.paxType !== 'infant') {
      return total + parseInt(pax.count, 10);
    }
    return total;
  }, 0);
}

export function getComponentData(
  apiResponse: AACheckout.InsuranceResponse,
  convertCurrency: (amount: number) => number,
): AACheckout.InsuranceComponentData {
  const tuneData = apiResponse.ancillary.insurance.data[0].ancillarylist.tune;
  const insurancePlans = tuneData.plans;
  const paxDetails = apiResponse.paxDetails;
  const totalPax = getTotalPax(paxDetails);

  const insuranceData = insurancePlans.map((plan) => ({
    benefitsData: {
      newTab: true,
      title: plan.viewDetailsBenefits.text,
      url: plan.viewDetailsBenefits.cta,
    },
    iconName: 'SecurityIcon',
    iconSrc:
      'https://storage.googleapis.com/airasia-repository-prod/images/misc/Icon_1_web.png',
    inclusives: plan.points.map((point) => point.text),
    isSelected: false,
    message: null,
    planCode: plan.planCode,
    price: convertCurrency(plan.totalPremiumAmount),
    ssrFeeCode: plan.ssrFeeCode.toString(),
    termsData: {
      title: plan.termsAndCondition.content,
    },
    title: plan.planTitle,
  }));

  return {
    data: {
      insuranceData,
      readMoreURL: '',
    },
    totalPax,
  };
}
