import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/vi';
import 'intl-list-format';
import 'intl-list-format/locale-data/en';
import 'intl-list-format/locale-data/vi';

import i18n from '@/helper/i18n';
import { format, parse, parseISO, differenceInCalendarDays } from 'date-fns';
import {
  TIMELINE_LOCAL_TIME_LONG_FORMAT,
  TIMELINE_LOCAL_TIME_LONGER_FORMAT,
  CHINA_LOCALES,
  API_DATE_FORMAT,
  SHORT_DATE_FORMAT,
} from './constants';
import { LOCALE_CODE_DEFAULT } from '../constants';

const { chain: t } = i18n;

export const formatMoney = (number, language) => {
  return new Intl.NumberFormat(language, { minimumFractionDigits: 2 }).format(
    number,
  );
};

export const listFormat = (
  list = [],
  formatOptions = {},
  locale = LOCALE_CODE_DEFAULT,
) =>
  new Intl.ListFormat(locale, {
    style: 'long',
    type: 'unit',
    ...formatOptions,
  }).format(list);

const formatZonedTime = (day, pattern) => {
  return format(day, pattern || TIMELINE_LOCAL_TIME_LONG_FORMAT);
};

const formatTime = (day, timeFormat) => format(day, timeFormat);

const parseAPIDay = (day, dateFormat = API_DATE_FORMAT) =>
  parse(day, dateFormat, new Date());

export const formatPayLaterDate = (date, format) =>
  formatTime(parseISO(date), format);

export const formatShortDate = (day) =>
  formatTime(parseAPIDay(day), SHORT_DATE_FORMAT);

export const formatLongerLocalTime = (day) => {
  return formatZonedTime(parseISO(day), TIMELINE_LOCAL_TIME_LONGER_FORMAT);
};

export const getGuestRatingMessage = (rating: number) => {
  if (rating >= 4.5) {
    return t.hotel.common['guestRating.4.5'];
  } else if (rating >= 4) {
    return t.hotel.common['guestRating.4.0'];
  } else if (rating >= 3) {
    return t.hotel.common['guestRating.3.0'];
  }
};

export const numberFormat = (
  number = 0,
  formatOptions = {},
  locale = LOCALE_CODE_DEFAULT,
) =>
  new Intl.NumberFormat(locale, { style: 'decimal', ...formatOptions }).format(
    number,
  );

export const roundPrice = (baseValue) => Math.ceil(baseValue);

export const getTranslatedAmenities = (amenities = []) => {
  return amenities.map((amenity) => ({
    name: t.hotel.amenityFilter[`filter_${amenity.id}`],
    id: amenity.id,
  }));
};

export const getDisplayAddress = ({
  line_1 = '',
  line_2 = '',
  city = '',
  state_province_name = '',
  country = '',
  postal_code = '',
}) => {
  const addressArray = [line_1, line_2, city, state_province_name, country];
  return `${addressArray
    .map((item) => item.trim())
    .filter(Boolean)
    .join(', ')} ${postal_code}`;
};

export const getAgeRange = (minAge, maxAge) => ({ minAge, maxAge });

const percentPromotion = (baseValue, strikethroughPrice) => {
  if (!strikethroughPrice) {
    return 0;
  }
  return Math.trunc(
    ((strikethroughPrice - baseValue) / strikethroughPrice) * 100,
  );
};

const getDiscountPercentageLocale = (percent, locale) => {
  if (CHINA_LOCALES.includes(locale)) {
    return (100 - percent) / 10;
  }
  return percent;
};

export const discountText = (baseValue, strikethroughPrice, locale) => {
  const percent = percentPromotion(baseValue, strikethroughPrice);
  if (percent) {
    return t.hotel.hotelCard.priceOff({
      percent: getDiscountPercentageLocale(percent, locale),
    });
  }
  return null;
};

export const countNights = (checkIn, checkOut) =>
  differenceInCalendarDays(new Date(checkOut), new Date(checkIn));

export const mapBookingLinks = (links) => {
  const {
    pre_cancel: preCancel = {},
    cancel = {},
    send_voucher: sendVoucher = {},
  } = links;

  return {
    preCancel: preCancel.href,
    cancel: cancel.href,
    sendVoucher: sendVoucher.href,
  };
};

export const getAmenityTranslationKey = (type, id) => `${type}_${id}`;

export const getOccupancyArray = (
  rooms: {
    adult: number;
    child: number;
  }[],
) => {
  return rooms.map((r) => {
    return r.child ? `${r.adult}(${new Array(r.child).fill('0')})` : r.adult;
  });
};

export const getKeyFromUrl = (url) => {
  const params = new URLSearchParams(url);
  return params.get('key');
};

export const roundOffPrice = (price) => {
  let numConvert = price;
  if (typeof price === 'string') {
    numConvert = parseFloat(price);
  }
  if (!numConvert) return false;

  return Math.floor(numConvert).toString();
};

export const getHeroImage = (images, size) => {
  const heroImage = images.find((image) => image && image.hero_image);
  return (
    heroImage &&
    ((heroImage.links && heroImage.links[size]) ||
      (heroImage.imageLinks && heroImage.imageLinks[size]))
  );
};

export const makeImageCarouselFromImages = (images, size) => {
  const imagesCarousel = [];
  images.forEach((img) => {
    const link = img.links[size];
    if (link) {
      imagesCarousel.push(link);
    }
  });
  return imagesCarousel;
};

export const convertTagToTranslationID = (tag) => {
  if (typeof tag === 'string') {
    return tag;
  }
  const { translationId = '', numberOfRooms, value = '' } = tag;

  let translatedTag = '';
  if (translationId === 'tags.rooms_left') {
    translatedTag = t.hotel.tags.rooms_left({ smart_count: numberOfRooms });
  } else {
    translatedTag = t.hotel.tags[translationId.replace('tags.', '')];
  }
  if (translatedTag && translatedTag !== translationId) {
    return translatedTag;
  }
  return value;
};

export const convertedLeftPillTexts = (hotel) =>
  hotel.leftPillTexts?.map((label) => convertTagToTranslationID(label));

export const convertedRightPillTexts = (hotel) =>
  hotel.rightPillTexts?.map((label) => convertTagToTranslationID(label));

export const convertedAboveNameLabels = (hotel) =>
  hotel.aboveNameLabels?.map((label) => convertTagToTranslationID(label));

export const displayPrice = (
  basePrice,
  strikethroughPrice,
  applyBigpoints,
  baseRateAfterBigPointsDeduction,
  bigMembership,
) => {
  let roundBasePrice = basePrice;
  if (bigMembership && applyBigpoints) {
    roundBasePrice = {
      ...roundBasePrice,
      value:
        baseRateAfterBigPointsDeduction &&
        roundPrice(baseRateAfterBigPointsDeduction.value),
    };
  } else {
    roundBasePrice = {
      ...roundBasePrice,
      value: basePrice && roundPrice(basePrice.value),
    };
  }

  const roundStrikethroughPrice = {
    ...strikethroughPrice,
    value:
      strikethroughPrice && numberFormat(roundPrice(strikethroughPrice.value)),
  };

  return [roundBasePrice, roundStrikethroughPrice];
};
