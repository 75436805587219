import i18n from '@/helper/i18n';
import Config from '@/config';
import { ROUTE_URL } from '@/helper/constants';

export const getUHFConfig = () => {
  const chain = i18n.use();

  return {
    components: [
      {
        data: [
          {
            direction: 'left',
            iconName: '',
            iconUrl: 'https://b.staticaa.com/images/logos/airasiacom_logo.svg',
            id: 'aalogo',
            label: '',
            newTab: 'TRUE',
            redirectUrl: `${Config.APP_AIRAISA_URL}en/gb`,
            showInMobilePane: 'FALSE',
            type: 'logo',
          },
          {
            direction: 'right',
            iconName: 'CollectionsBookmarkIcon',
            iconUrl: '',
            id: 'mybookings',
            label: `${chain.header.myBookings}`,
            showInMobilePane: 'TRUE',
            type: 'navigation',
          },
          {
            direction: 'right',
            iconName: 'LanguageIcon',
            iconUrl: '',
            id: 'localecurrency',
            label: '',
            newTab: '',
            redirectUrl: '',
            showInMobilePane: 'TRUE',
            type: 'locale-currency',
          },
          {
            direction: 'right',
            iconName: 'AccountCircle',
            iconUrl: '',
            id: 'login',
            label: `${chain.header.login}`,
            newTab: '',
            redirectUrl: '',
            showInMobilePane: 'FALSE',
            type: 'login',
          },
        ],
      },
    ],
    currencyList: [
      {
        data: [
          {
            longform: 'Malaysia Ringgit',
            shortSymbol: 'RM',
            shortform: 'MYR',
          },
          {
            icon: 'MonetizationOnIcon',
            longform: 'US Dollar',
            shortSymbol: '$',
            shortform: 'USD',
          },
        ],
      },
    ],
    defaultCurrency: [
      {
        data: [
          {
            icon: 'MonetizationOnIcon',
            longform: 'Malaysia Ringgit',
            shortSymbol: 'RM',
            shortform: 'MYR',
          },
        ],
      },
    ],
    defaultLocale: [
      {
        data: [
          {
            default: 'en-gb',
            icon: 'LanguageIcon',
            longform: 'English',
            shortform: 'En',
          },
        ],
      },
    ],
    localeList: [
      {
        data: [
          {
            default: 'en-gb',
            longform: 'English',
            shortform: 'En',
          },
          /* ,
          {
            default: 'zh-cn',
            longform: '简体中文',
            shortform: 'ZH',
          }, */
        ],
      },
    ],
    loginMenu: [
      /* {
        memberData: [
          {
            icon: '',
            id: 'bigMemberId',
            label: `${chain.header.loginMenu.bigMemberId}`,
            newTab: 'FALSE',
            redirectUrl: '',
          },
          {
            icon: '',
            id: 'bigMemberPoints',
            label: `${chain.header.loginMenu.bigMemberPoints}`,
            newTab: 'FALSE',
            redirectUrl: '',
          },
          {
            icon: '',
            id: 'status',
            label: `${chain.header.loginMenu.status}`,
            newTab: 'FALSE',
            redirectUrl: '',
          },
        ],
      },
      {
        navigations: [
          {
            icon: '',
            id: 'myaccount',
            label: `${chain.header.myAccount}`,
            newTab: 'TRUE',
            redirectUrl: `${Config.APP_AIRAISA_URL}member/myaccount?culture=en-GB`,
          },
          {
            icon: '',
            id: 'mybookings',
            label: `${chain.header.myBookings}`,
            newTab: 'FALSE',
            redirectUrl: `${window.location.origin}${ROUTE_URL.MY_BOOKINGS}`,
          },
        ],
      }, */
      {
        logoutButton: [
          {
            icon: '',
            id: 'logout',
            label: `${chain.header.logOut}`,
            newTab: 'FALSE',
            redirectionUrl: '',
          },
        ],
      },
    ],
    loginTooltip: [
      {
        data: [
          {
            description: `${chain.header.loginTooltip.description}`,
            loginButtonText: `${chain.header.loginTooltip.loginButtonText}`,
            signupButtonText: '',
            title: `${chain.header.loginTooltip.title}`,
          },
        ],
      },
    ],
    recommendedCurrencyList: [
      {
        data: '',
      },
    ],
    recommendedLocaleList: [
      {
        data: '',
      },
    ],
    translations: [
      {
        data: [
          {
            currency: `${chain.header.translations.currency}`,
            locale: `${chain.header.translations.locale}`,
            mobileCurrencyLabel: `${chain.header.translations.mobileCurrencyLabel}`,
            mobileLocaleLabel: `${chain.header.translations.mobileLocaleLabel}`,
            settings: `${chain.header.translations.settings}`,
          },
        ],
      },
    ],
  };
};
