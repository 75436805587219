import React from 'react';
import { ROUTE_URL } from '@/helper/constants';
import { NoneLayout } from '@/components';

const Test3 = React.lazy(
  () => import(/* webpackChunkName: "demo-test3" */ '@/pages/demo/test3'),
);

export default [
  {
    path: `${ROUTE_URL.ROOT}/demo/test3`,
    component: Test3,
    isAuth: false,
    layout: NoneLayout,
  },
];
