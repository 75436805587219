import { action, makeObservable, observable } from 'mobx';

class LoadingStore {
  @observable loading = false;

  constructor() {
    makeObservable(this);
  }

  @action
  open = () => {
    this.loading = true;
  };

  @action
  close = () => {
    this.loading = false;
  };
}

export default new LoadingStore();
