import Storage from '@/helper/storage';
import { KEY_H5SERVICE_APIKEY } from '@/helper/constants';

export const getApiKey = async () => {
  //get from storage
  const apiKey = Storage.getItem(KEY_H5SERVICE_APIKEY);
  return apiKey;

  // const result: TokenResponse = await http.get<TokenResponse>(
  //   `${Config.H5_SERVICE.URL}/api/auth/api-key`,
  //   {},
  // );

  // apiKey = result.data.apiKey;
  // Storage.setItem(KEY_H5SERVICE_APIKEY, apiKey);

  // return apiKey;
};

export const setApiKey = async (apiKey: string) => {
  Storage.setItem(KEY_H5SERVICE_APIKEY, apiKey);
  return apiKey;
};
