import { action, observable } from 'mobx';
import Device from '@/helper/device';
import paramManager from '@/helper/param.manager';

class WebStore {
  @observable deviceType: string;
  @observable isMobile: boolean;
  paramManager = paramManager;

  @action
  init = async () => {
    this.isMobile = Device.isMobileDevice;
    this.deviceType = this.isMobile ? 'mobile' : 'desktop';
    this.loadCSS();
  };

  loadCSS = () => {
    if (this.isMobile) {
      import('@/assets/styles/index.mobile.scss');
    } else {
      import('@/assets/styles/index.scss');
    }
  };
}

export default new WebStore();
