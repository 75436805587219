import React from 'react';
import { ROUTE_URL } from '@/helper/constants';
import { NoneLayout } from '@/components';

const Default = React.lazy(
  () =>
    import(
      /* webpackChunkName: "flights/checkout" */ '@/pages/flights/checkout'
    ),
);

export default [
  {
    path: `${ROUTE_URL.FLIGHTS_CHECKOUT}`,
    component: Default,
    isAuth: false,
    layout: NoneLayout,
  },
];
