import { memo, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import i18n from '@/helper/i18n';
import NotificationBannerInline from '@airasia/phoenix-components/components/NotificationBannerInline';
import { useStore } from '@/hooks';
import { CommonApi } from '@/api';

import './index.scss';

interface IProps {
  component: any;
}

const Middle = (props: IProps) => {
  const [isInWhiteList, setIsInWhiteList] = useState(false);
  const [isValidatedWhiteList, setIsValidatedWhiteList] = useState(false);
  const { component } = props;
  const { webStore, userStore } = useStore();
  const chain = i18n.use();
  const isLogined = userStore.isLogin && userStore.isValidated;

  useEffect(() => {
    const fn = async () => {
      if (isLogined) {
        setIsValidatedWhiteList(true);
        setIsInWhiteList(true);
      }
    };
    fn();
  }, [isLogined]);

  return (
    <>
      {isLogined && isValidatedWhiteList ? (
        isInWhiteList ? (
          component
        ) : (
          <div>
            <div className="center-page">
              <NotificationBannerInline
                accessible
                border
                closable={false}
                ctaColor="primaryDark"
                device={webStore.deviceType}
                fullWidth={false}
                isBannerClickable
                largerPadding={false}
                message={`${chain.error.notInWhiteLit}`}
                showLeftIcon
                smallerPadding={false}
                timer={4800}
                type="error"
              />
            </div>
          </div>
        )
      ) : (
        <div></div>
      )}
    </>
  );
};

export default memo(observer(Middle));
