import i18n from '@/helper/i18n';
import {
  isActivePenalty,
  filterPenalties,
  getRefundType,
} from './cancellation';
import {
  RECOMMEND_KEY,
  PAY_LATER_FORMAT_SHORT_TIME,
  AmenityIconsMap,
  THUMBNAIL_SIZE,
  AMENITY_ICON_TYPE,
  HERO_SIZE,
  CHARGE_TYPE,
  PENALTY_TYPE_FULL,
} from './constants';
import {
  CANCELLATION_REFUND_TYPE,
  CANCELLATION_PENALTY_TYPES,
} from './booking';
import {
  discountText,
  getAmenityTranslationKey,
  formatLongerLocalTime,
  formatPayLaterDate,
  roundOffPrice,
  getHeroImage,
  numberFormat,
} from './utils';
import { calculateBigPointMultiplier } from '@/helper/hotel/big-point';

const { chain: t } = i18n;

const checkRecommendLevel = (tags = []) => {
  const isRecommended = tags.find((e) => e.id === RECOMMEND_KEY);
  return !!isRecommended;
};

export const transformHotelRoomsData = (
  roomData,
  locale: string,
  isLoggedUser: boolean,
  checkout,
) => {
  const { rooms = [], serverTime } = roomData;

  return reduceRooms(rooms, checkout, serverTime).map((room) => {
    const rates = room.rates.map((rate) => {
      const {
        rateNameId,
        baseRate,
        strikethroughPrice,
        refundType,
        isPayLater,
        payLaterDate,
        isCrossSellRate,
        isRecommended,
        isPayAtHotel,
        totalDiscountedPrice,
      } = rate;

      const [
        firstPenalty = {
          penalty_type: CANCELLATION_PENALTY_TYPES.FULL,
        },
      ] = rate.cancelPenalties;

      let { end: firstPenaltyEndDate } = firstPenalty;

      const timePlus = ' 23:59:00';

      if (firstPenaltyEndDate && firstPenaltyEndDate.length === 10) {
        //cancel penalties of Agoda always return yyyy-mm-dd
        firstPenaltyEndDate = firstPenaltyEndDate + timePlus;
      } else if (firstPenaltyEndDate && firstPenaltyEndDate.length === 16) {
        const firstPenaltyEndDateSplit = firstPenaltyEndDate.split(' ');
        //cancel penalties  of GoQuo always return yyyy-mm-dd hh:mm
        firstPenaltyEndDate = firstPenaltyEndDateSplit[0] + timePlus;
      } else {
        firstPenaltyEndDate = firstPenaltyEndDate?.split('+')[0];
      }
      let isNonRefundable = false;
      let isFreeCancellation = false;
      switch (refundType) {
        case CANCELLATION_REFUND_TYPE.FULL_REFUND:
          isFreeCancellation = true;
          break;
        case CANCELLATION_REFUND_TYPE.PARTIAL_REFUND:
          isFreeCancellation = true;
          break;
        case CANCELLATION_REFUND_TYPE.NO_REFUND:
          isNonRefundable = true;
          break;
        default:
          isNonRefundable = false;
          isFreeCancellation = false;
          break;
      }

      const refundTypeLang = refundType
        ? t.hotel.roomTags[`cancellationPolicyText.${refundType}`]
        : '';

      const penaltyDescriptionLabel =
        typeof refundTypeLang === 'string'
          ? ''
          : refundTypeLang({
              date: firstPenaltyEndDate
                ? formatLongerLocalTime(firstPenaltyEndDate)
                : '',
            });

      return {
        ...rate,
        rateName: t.hotel['roomCard.rateName'][`name_${rateNameId}`],
        bigPoint: rate.bigPoint?.points_earn
          ? {
              template: isLoggedUser
                ? t.hotel.common.earnBigPointTemplateForLoggedInOld
                : t.hotel.common.earnBigPointTemplate,
              pointTemplate: t.hotel.common.bigPointValueTemplate,
              pointValue: numberFormat(rate.bigPoint.points_earn),
              multiplierTemplate: t.hotel.common.bigPointMultiplierTemplate,
              multiplierValue: calculateBigPointMultiplier(
                rate.bigPoint.multiplier,
              ),
            }
          : null,
        freeCancellationText:
          isFreeCancellation &&
          refundType === CANCELLATION_REFUND_TYPE.FULL_REFUND
            ? penaltyDescriptionLabel
            : null,
        partiallyRefundableText:
          isFreeCancellation &&
          refundType === CANCELLATION_REFUND_TYPE.PARTIAL_REFUND
            ? penaltyDescriptionLabel
            : null,
        nonRefundableText: isNonRefundable ? penaltyDescriptionLabel : null,
        payLaterText:
          isPayLater && payLaterDate
            ? t.hotel.tripDetails['payLater.payNothingUntil']({
                date: formatPayLaterDate(
                  payLaterDate,
                  PAY_LATER_FORMAT_SHORT_TIME,
                ),
              })
            : null,
        payAtHotelText: isPayAtHotel
          ? t.hotel.tags.pay_at_hotel_description
          : null,
        payAtHotel: isPayAtHotel ? t.hotel.tags.pay_at_hotel : null,
        recommendedText: isRecommended ? 'Recommended' : null,
        flierDiscountText:
          isCrossSellRate && isLoggedUser ? t.hotel.tags.flyer_discount : null,
        CTAButtonText:
          isPayAtHotel || isPayLater
            ? t.hotel.roomCard.reserveNow
            : t.hotel.roomCard.bookNow,
        discountText: discountText(
          baseRate.value,
          strikethroughPrice ? strikethroughPrice.value : 0,
          locale,
        ),
        strikeThrough: strikethroughPrice,
        payLater: isPayLater ? t.hotel.roomCard.buyNowPayLater : null,
        isFreeCancellation: rate.isFreeCancellation,
        isNonRefundable: rate.isNonRefundable,
        isPayAtHotel: rate.isPayAtHotel,
        refundType: refundType,
        price: baseRate,
        totalPrice: {
          ...totalDiscountedPrice,
          text: t.hotel.bookingDetail['paymentDetails.payLater.total'],
        },
        cancellationPenaltyType: refundType,
      };
    });

    const rateDiscounts = room.rates.map((rate) => {
      const isDiscountExist = rate.discountData !== undefined;
      return {
        id: rate.id,
        title: isDiscountExist
          ? t.hotel.hotelCouponDiscount[
              `${rate.discountData.type}.listing.title`
            ]
          : '',
        type: isDiscountExist ? rate.discountData.type : '',
      };
    });

    return {
      ...room,
      rateDiscounts,
      rates,
    };
  });
};

const reduceRooms = (rooms = [], checkout, serverTime) => {
  const result = [];

  rooms.forEach((room) => {
    const { rates } = room;
    const validRates = reduceRates(
      rates,
      room.square_meter_area,
      checkout,
      serverTime,
    );

    let isRecommendRoom = false;
    if (validRates.length === 1)
      isRecommendRoom = !!validRates[0].isRecommended;

    const roomWithValidRates = { ...room, rates: validRates };
    const {
      id,
      name,
      gallery = {},
      room_amenities,
      views_from_room,
      occupancy,
      room_occupancy,
    } = roomWithValidRates;

    let { square_meter_area: squareMeterArea } = roomWithValidRates;
    if (squareMeterArea) {
      squareMeterArea = roundOffPrice(squareMeterArea);
    }

    const { images } = gallery;

    const heroImage = getHeroImage(images, THUMBNAIL_SIZE);

    const newRoom = {
      id,
      name,
      heroImage,
      occupancy,
      roomOccupancy: room_occupancy,
      otherAmenities:
        views_from_room &&
        views_from_room
          .filter(({ name }) => Boolean(name))
          .map((amenity) => {
            return {
              ...amenity,
              displayId: `other${AmenityIconsMap.split.level}${
                AmenityIconsMap.other[amenity.id]
              }`,
            };
          }),
      amenities:
        room_amenities &&
        room_amenities
          .filter(({ name }) => Boolean(name))
          .map((amenity) => {
            return {
              ...amenity,
              displayId: `room${AmenityIconsMap.split.level}${
                AmenityIconsMap.room[amenity.id]
              }`,
              translationKey: getAmenityTranslationKey(
                AMENITY_ICON_TYPE.ROOM,
                amenity.id,
              ),
            };
          }),
      squareMeterArea: squareMeterArea,
      rates: validRates,
      isRecommendRoom,
      images: makeGalleryFromImages(images),
    };

    if (validRates.length > 0) {
      result.push(newRoom);
    } else {
      console.log('[Discarded Room]', newRoom);
    }
  });

  return result;
};

const makeGalleryFromImages = (images) => {
  return images
    .filter((img) => img.links[HERO_SIZE] || img.links[THUMBNAIL_SIZE])
    .map((image) => {
      const processedImage = {
        ...image,
        links: {
          small: image.links[THUMBNAIL_SIZE] || image.links[HERO_SIZE],
          big: image.links[HERO_SIZE] || image.links[THUMBNAIL_SIZE],
        },
      };

      /* Because the back end is failed to translate the category name
        so we have to take the caption name instead base on the assumption
        that all image will have the caption name equal to category name
       */
      if (image.caption && image.category?.id) {
        processedImage.category = {
          ...image.category,
          name: image.caption,
        };
      } else {
        delete processedImage.category;
      }
      return processedImage;
    });
};

const reduceRates = (rates = [], squareMeterArea, checkout, serverTime) => {
  const result = [];

  let squareMeterAreaRoundOff = squareMeterArea;
  if (squareMeterAreaRoundOff) {
    squareMeterAreaRoundOff = roundOffPrice(squareMeterAreaRoundOff);
  }

  rates.forEach((rate) => {
    const {
      id,
      rate_option_id,
      refundable,
      amenities,
      price,
      available_rooms: availableRooms,
      bed_groups: bedGroups,
      cancel_penalties = [],
      payable_at_site,
      big_life: bigPoint,
      pay_at_hotel_available,
      tags,
      cross_sell_rate,
      buy_now_pay_later_available,
      buy_now_pay_later_date,
      discount,
    } = rate;
    const { type: paymentDiscountType } = rate.discount || {};
    const isRecommended = checkRecommendLevel(tags);

    const hotelChargedPropertyFee =
      payable_at_site &&
      payable_at_site.find((item) => item.type === 'mandatory_fee');

    const cancelPenalties = filterPenalties(
      cancel_penalties,
      checkout,
      serverTime,
    );
    const displayedBedGroups = bedGroups.map((bedGroup) => {
      return {
        ...bedGroup,
        squareMeterArea: squareMeterAreaRoundOff,
      };
    });
    const {
      average_nightly_price: averageNightlyPrice,
      total_tax: totalTax,
      total_fee: totalFee,
      tax_breakdown: taxBreakdown = [],
      fee_breakdown: feeBreakdown = [],
      total_big_points_redemption: totalBigpointsRedemption = {},
      total_payable_amount: totalPayable,
      discount_breakdown: discountBreakdown,
      total_discounted_price: totalDiscountedPrice,
    } = price;

    const {
      total_inclusive_price: totalInclusivePrice,
      total_exclusive_price: totalExclusivePrice,
    } = price;

    const {
      display_price: baseRate,
      strike_through: strikethroughPrice,
      price_after_deducting_big_points: baseRateAfterBigPointsDeduction,
    } = averageNightlyPrice;

    if (baseRate && baseRate.value) {
      baseRate.value = roundOffPrice(baseRate.value);
    }

    if (strikethroughPrice && strikethroughPrice.value) {
      strikethroughPrice.value = roundOffPrice(strikethroughPrice.value);
    }

    if (totalInclusivePrice && totalInclusivePrice.value) {
      totalInclusivePrice.value = roundOffPrice(totalInclusivePrice.value);
    }

    if (totalExclusivePrice && totalExclusivePrice.value) {
      totalExclusivePrice.value = roundOffPrice(totalExclusivePrice.value);
    }

    if (
      baseRateAfterBigPointsDeduction &&
      baseRateAfterBigPointsDeduction.value
    ) {
      baseRateAfterBigPointsDeduction.value = roundOffPrice(
        baseRateAfterBigPointsDeduction.value,
      );
    }

    const totalTaxValue = Number(totalTax?.value);
    const hasTaxesAndFee = taxBreakdown.some(
      ({ type }) => type === CHARGE_TYPE.TAXES_AND_FEES,
    );
    const totalPayableUsingBigpoints = totalBigpointsRedemption.amount;
    const currDate = new Date().getTime();
    let cancelPenalty = null;
    let isNonRefundable = false;
    if (!refundable) {
      cancel_penalties.forEach((i) => {
        if (i.end && i.start) {
          const startDate = new Date(i.start).getTime();
          const endDate =
            i.start === i.end
              ? new Date(i.end).getTime() + 24 * 60 * 60 * 1000
              : new Date(i.end).getTime();
          if (startDate <= currDate && currDate <= endDate) {
            cancelPenalty = i;
          }
        } else if (i.end) {
          const endDate = new Date(i.end).getTime();
          if (currDate <= endDate) {
            cancelPenalty = i;
          }
        } else if (i.start) {
          const startDate = new Date(i.start).getTime();
          if (currDate >= startDate) {
            cancelPenalty = i;
          }
        }
      });

      if (cancelPenalty) {
        isNonRefundable =
          cancelPenalty.penalty_type === PENALTY_TYPE_FULL ? true : false;
      }
    }

    const { penalty_type: activePenalty } =
      cancelPenalties.find(({ start, end }) =>
        isActivePenalty({ start, end, serverTime }),
      ) || {};
    const refundType = getRefundType(activePenalty);

    const hasFreeCancellation =
      refundType === CANCELLATION_REFUND_TYPE.FULL_REFUND;

    const hasFreeBreakfast =
      amenities &&
      amenities.find(
        (amenity) =>
          amenity.id === 2 ||
          amenity.id === 65 ||
          (amenity.id >= 22 && amenity.id <= 26) ||
          (amenity.id >= 70 && amenity.id <= 74),
      );
    const hasFreeLunch =
      amenities && amenities.find((amenity) => amenity.id === 27);
    const hasFreeDinner =
      amenities &&
      amenities.find((amenity) => amenity.id >= 28 && amenity.id <= 33);
    const hasHalfBoard =
      amenities &&
      amenities.find((amenity) => amenity.id === 67 || amenity.id === 75);
    const hasFullBoard =
      amenities &&
      amenities.find((amenity) => amenity.id === 58 || amenity.id === 69);

    let rateNameId = 0;
    if (hasFreeCancellation) {
      if (hasFullBoard) rateNameId = 7;
      else if (hasHalfBoard) rateNameId = 6;
      else if (hasFreeBreakfast) {
        if (hasFreeLunch && hasFreeDinner) rateNameId = 7;
        else if (hasFreeLunch || hasFreeDinner) rateNameId = 6;
        else rateNameId = 4;
      } else if (hasFreeLunch || hasFreeDinner) rateNameId = 5;
      else rateNameId = 3;
    } else if (hasFullBoard) rateNameId = 11;
    else if (hasHalfBoard) rateNameId = 10;
    else if (hasFreeBreakfast) {
      if (hasFreeLunch && hasFreeDinner) rateNameId = 11;
      else if (hasFreeLunch || hasFreeDinner) rateNameId = 10;
      else rateNameId = 2;
    } else if (hasFreeLunch) rateNameId = 8;
    else if (hasFreeDinner) rateNameId = 9;
    else rateNameId = 1;

    const newRate = {
      amenities:
        amenities &&
        amenities
          .filter(({ name }) => Boolean(name))
          .map((amenity) => {
            return {
              ...amenity,
              displayId: `rate${AmenityIconsMap.split.level}${
                AmenityIconsMap.rate[amenity.id]
              }`,
              translationKey: getAmenityTranslationKey(
                AMENITY_ICON_TYPE.RATE,
                amenity.id,
              ),
            };
          }),
      id,
      rateNameId,
      rateOptionId: rate_option_id,
      isFreeCancellation: refundable,
      isNonRefundable: isNonRefundable,
      baseRate,
      strikethroughPrice,
      totalInclusivePrice,
      totalExclusivePrice,
      totalPayableUsingBigpoints,
      totalPayable,
      totalDiscountedPrice,
      discountBreakdown,
      baseRateAfterBigPointsDeduction,
      isTaxIncluded: totalTaxValue === 0,
      isFeeIncluded: Number(totalFee?.value) === 0,
      isTaxAndFeeCombined: totalTaxValue > 0 && hasTaxesAndFee,
      feeBreakdown,
      taxBreakdown,
      payableAtSite: payable_at_site,
      availableRooms,
      bedGroups: displayedBedGroups.slice(0, 2),
      cancelPenalties,
      hotelChargedPropertyFee: hotelChargedPropertyFee
        ? Number(hotelChargedPropertyFee.amount.value)
        : 0,
      bigPoint,
      isPayAtHotel: pay_at_hotel_available,
      isRecommended,
      isCrossSellRate: cross_sell_rate,
      paymentDiscountType,
      refundType,
      isPayLater: buy_now_pay_later_available,
      payLaterDate: buy_now_pay_later_date,
      discountData: discount,
    };
    if (baseRate.value > 0) {
      result.push(newRate);
    } else {
      console.log('[Discarded Rate]', newRate);
    }
  });

  return result;
};
