import http from '../../base/http';
import Config from '@/config';

interface RefreshTokenResponse {
  accessToken: string;
}

interface TokenResponse {
  apiKey: string;
}

export const refreshToken = async (refreshToken: string) => {
  const bffEndpoint = `${Config.BFF_SERVICE.URL}`;
  const accessToken = await getApiKey();
  const res = await http.post<BFFResponse<RefreshTokenResponse>>(
    `${bffEndpoint}/wmp-bff/sso/big-loyalty-access-token`,
    { refreshToken },
    {
      headers: {
        'x-api-key': accessToken,
      },
    },
  );

  return res.data.accessToken;
};

export const getUserInfo = async (params) => {
  const bffEndpoint = `${Config.BFF_SERVICE.URL}`;
  const { userId, accessToken } = params;
  const res = await http.post<BFFResponse<User>>(
    `${bffEndpoint}/wmp-bff/sso/get-user-info`,
    {
      accessToken,
      userId,
    },
  );

  return res.data;
};

export const getApiKey = async () => {
  const bffEndpoint = `${Config.BFF_SERVICE.URL}`;
  const result = await http.get<BFFResponse<TokenResponse>>(
    `${bffEndpoint}/wmp-bff/sso/big-loyalty-api-key`,
  );
  return result.data.apiKey;
};
