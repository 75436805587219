import { CURRENCY_DEFAULT } from '@/helper/constants';

export function mapSelectionToPlainAddonItems(
  selectedWheelchairData: AACheckout.WheelchairSelectedData,
  componentData: AACheckout.WheelchairComponentData,
  journeys: AACheckout.Journey[],
  paxTitles: AACheckout.PaxTitle[],
): AACheckout.PlainAddonItem[] {
  const plainAddonItems: AACheckout.PlainAddonItem[] = [];

  // iterate through the selected wheelchair data
  selectedWheelchairData.selectedWheelchair.forEach((wheelchairItem, index) => {
    const componentWheelchair = componentData[index]; // get the corresponding component data

    wheelchairItem.wheelchair.forEach((paxWheelchairs, paxIndex) => {
      paxWheelchairs.forEach((option) => {
        const matchedWheelchairService =
          componentWheelchair.ancillarylist[option.keyOption][
            option.optionIndex
          ];
        const pwdValue =
          selectedWheelchairData.wheelchairPaxPwdId[index].wheelchair[paxIndex]
            .value || '';

        const addonItem: AACheckout.PlainAddonItem = {
          id: matchedWheelchairService.id,
          groupBy: matchedWheelchairService.id,
          currency: matchedWheelchairService.price.currency,
          name: option.label,
          price: pwdValue ? 0 : option.price,
          type: 'wheelchair',
          category: 'Wheelchair',
          departureStation: getStationCodeByStationName(
            journeys,
            wheelchairItem.departureStation,
          ),
          arrivalStation: getStationCodeByStationName(
            journeys,
            wheelchairItem.arrivalStation,
          ),
          passengerReferenceId: paxTitles[paxIndex].paxId,
          properties: {
            ssrCode: matchedWheelchairService.id,
            feeCode: matchedWheelchairService.properties.feeCode,
            docId: pwdValue,
          },
        };

        plainAddonItems.push(addonItem);
      });
    });
  });

  return plainAddonItems;
}

function getStationCodeByStationName(
  journeys: AACheckout.Journey[],
  stationName: string,
): string | undefined {
  for (const journey of journeys) {
    if (journey.destination.stationName === stationName) {
      return journey.destination.stationCode;
    } else if (journey.origin.stationName === stationName) {
      return journey.origin.stationCode;
    }
  }
  return undefined;
}

export function getComponentData(
  data: AACheckout.WheelchairComponentData,
  convertCurrency: (amount: number) => number,
): AACheckout.WheelchairComponentData {
  return data.map((wheelchairData) => {
    // iterate through NeedWheelchair and OwnWheelchair and convert the amount and currency
    const needWheelchairConverted =
      wheelchairData.ancillarylist.NeedWheelchair.map((service) =>
        convertServiceCurrency(service, convertCurrency),
      );
    const ownWheelchairConverted =
      wheelchairData.ancillarylist.OwnWheelchair.map((service) =>
        convertServiceCurrency(service, convertCurrency),
      );

    // return the new WheelchairData object
    return {
      ...wheelchairData,
      ancillarylist: {
        OwnWheelchair: ownWheelchairConverted,
        NeedWheelchair: needWheelchairConverted,
      },
    };
  });
}

// convert currency and amount
function convertServiceCurrency(
  service: AACheckout.WheelchairService,
  convertCurrency: (amount: number) => number,
): AACheckout.WheelchairService {
  return {
    ...service,
    price: {
      amount: convertCurrency(service.price.amount),
      currency: CURRENCY_DEFAULT,
    },
  };
}
