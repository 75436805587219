export enum BundleAncillaryTypeEnum {
  BAGGAGE = 'baggage',
  MEALS = 'meals',
  SEAT = 'seat',
  WHEELCHAIR = 'wheelchair',
  INSURANCE = 'insurance',
  FASTPASS = 'fastPass',
  BUNDLE = 'bundle',
  OTHER = 'other',
}

export enum FlightKeyEnum {
  DEPART_FLIGHT = 'departFlight',
  RETURN_FLIGHT = 'returnFlight',
}

export const WHEELCHAIR_READ_MORE_URL =
  'https://support.airasia.com/s/article/Prebook-special-needs-assistance-en?language=zh_CN';
export const WHEELCHAIR_UNAVAILABLE_LOGO_URL =
  'https://static.airasia.cn/design/images/flight/No_wheelchair_available.svg';
export const WHEELCHAIR_LOGO_URL =
  'https://static.airasia.cn/design/images/flight/Special_assistance.svg';

export const INSURANCE_MAIN_WIDGET_IMAGE =
  'https://a.staticaa.com/images/misc/insurance.svg';

export const paxTypes: { [key: string]: AACheckout.PaxType } = {
  infant: 'infant',
  child: 'child',
  adult: 'adult',
  ypta: 'ypta',
};

export const mapGuestTypes = {
  infant: 'Infant',
  child: 'Child',
  adult: 'Adult',
};
