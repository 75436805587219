import { action, observable, makeObservable, reaction } from 'mobx';
import { HotelApi } from '@/api';
import { userStore } from '@/store/base';
import { hotelSearchComponentStore, hotelGlobalStore } from '@/store/common';
import { reduceHotels } from '@/helper/hotel/hotel-data';
import { getOccupancyArray } from '@/helper/hotel/utils';
import { StoreUtil } from '@/store/common';

export default class HotelSearchStore implements PageStore {
  hotelSearchComponentStore = hotelSearchComponentStore;
  @observable hotels: any[] = [];
  @observable isLoading = true;
  @observable noMore = false;
  @observable hasError = false;
  @observable currentPage = 0;
  @observable location = '';
  @observable countryCode = '';
  @observable isShowSearchWidget = false;

  constructor() {
    makeObservable(this);

    reaction(
      () => {
        return userStore.currency;
      },
      () => {
        this.search();
      },
    );
  }

  setPagetitle() {
    document.title = '预订酒店';
  }

  @action
  onLoad = async () => {
    this.setPagetitle();
    await this.parseSearchParams();
    this.search();
  };

  parseSearchParams = async () => {
    if (StoreUtil.getUUIDFromSearchParams()) {
      const data = await StoreUtil.parseSearchParams();
      //recover search params
      this.updateSearchParams(data.searchInfo);
    }

    hotelGlobalStore.setFromURL();
    hotelSearchComponentStore.setFromURL();
  };

  updateSearchParams = (data) => {
    const newSearchParams = new URLSearchParams(data);
    const url = `/hotel/search?${newSearchParams.toString()}`;
    window.history.replaceState({}, '', url);
  };

  loadHotelDataByPageNumber = async (pageNumber: number) => {
    const tokenInfo = await userStore.getFlushedTokenInfo();

    const result = await HotelApi.searchHotels<any>(
      {
        countryCode: this.countryCode,
        occupancy: getOccupancyArray(hotelSearchComponentStore.roomOccupancy),
        currency: userStore.currency,
        language: userStore.locale,
        checkin: hotelSearchComponentStore.checkin,
        checkout: hotelSearchComponentStore.checkout,
        locationId: hotelSearchComponentStore.locationId,
        pageNumber,
        hotelsSize: 100,
      },
      tokenInfo,
    );

    const hotels = reduceHotels(result.hotels);

    if (result.hotels.length === 0) {
      this.noMore = true;
    }
    return hotels;
  };

  getDestDetail = async () => {
    try {
      const result = await HotelApi.getDestDetail<any>({
        language: userStore.locale,
        locationId: hotelSearchComponentStore.locationId,
      });

      this.countryCode = result.country_code;
      this.location = result.name;
    } catch (error) {
      //
    }
  };

  @action
  showSearchScreen = () => {
    this.isShowSearchWidget = true;
  };

  @action
  hideSearchScreen = () => {
    this.isShowSearchWidget = false;
  };

  @action
  handleOnScroll = (e) => {
    const target = e.target;
    if (
      Math.ceil(target.scrollTop) + target.clientHeight >=
      target.scrollHeight
    ) {
      this.loadMore();
    }
  };

  @action
  search = async () => {
    this.hotels = [];
    this.isLoading = true;
    this.currentPage = 0;
    this.noMore = false;
    this.hasError = false;
    await this.getDestDetail();

    try {
      this.hotels = await this.loadHotelDataByPageNumber(this.currentPage);

      while (this.hotels.length === 0 && this.noMore === false) {
        this.currentPage++;
        this.hotels = await this.loadHotelDataByPageNumber(this.currentPage);
      }
    } catch (error) {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  };

  @action
  loadMore = async () => {
    if (this.isLoading || this.noMore) {
      return;
    }

    this.isLoading = true;

    try {
      const nextPageHotels = await this.loadHotelDataByPageNumber(
        this.currentPage + 1,
      );
      this.hotels = [...this.hotels, ...nextPageHotels];
      this.currentPage++;
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  };

  @action
  onUnload = () => {};
}
