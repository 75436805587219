import React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Log } from '@/helper/log';

function getComponentName(
  Component: React.ComponentType<any>,
  componentName?: string,
): string {
  return (
    componentName ||
    Component.displayName ||
    Component.name ||
    (Component as any).type?.displayName ||
    'Unknown'
  );
}

export default function withError<P extends object>(
  WrapComponent: React.ComponentType<P>,
  componentName?: string,
): React.ComponentType<P> {
  const WithErrorComponent = (props: P) => {
    const componentFactName = getComponentName(WrapComponent, componentName);

    const handleError = (error: Error, info: { componentStack: string }) => {
      Log.errorUI(error as Error, componentFactName);
    };

    const fallbackRender = ({ error }: FallbackProps) => (
      <div>
        {/* Error:
        <div>{error.message}</div> */}
      </div>
    );

    return (
      <ErrorBoundary fallbackRender={fallbackRender} onError={handleError}>
        <WrapComponent {...props} />
      </ErrorBoundary>
    );
  };

  WithErrorComponent.displayName = `WithError(${getComponentName(
    WrapComponent,
    componentName,
  )})`;

  return WithErrorComponent;
}
