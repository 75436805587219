import { action, makeObservable, observable } from 'mobx';
import { ZodiacApi } from '@/api';
import { userStore } from '@/store/base';
import { Log } from '@/helper/log';
import dayjs from 'dayjs';
import { FastPassParser } from './parse';

const METHOD_NAMES = {
  LOAD_DATA: 'loadData',
} as const;

export default class AncillaryFastPassStore {
  private CLASS_NAME = 'AncillaryFastPassStore';
  @observable fastPassData: AACheckout.FastPassData = [];
  @observable fastPassDescription: AACheckout.FastPassDescription;
  @observable startPrice: number | null = null;
  @observable totalAmount = 0;
  @observable plainAddonItems: AACheckout.PlainAddonItem[] = [];
  @observable selectedFastPassData: AACheckout.SelectedFastPassData = [];

  @observable dataLoading = false;
  @observable isDataLoaded = false;

  tripsInfo: TripsInfo = null;
  paxTitles: AACheckout.PaxTitle[];
  conversionRates: AACheckout.ConversionRates;
  journeys: AACheckout.Journey[];

  constructor() {
    makeObservable(this);
  }

  @action
  loadData = async () => {
    try {
      if (
        !(
          (this.tripsInfo &&
            this.conversionRates &&
            this.conversionRates.length > 0) ||
          (this.paxTitles && this.paxTitles.length > 0)
        )
      ) {
        return;
      }

      if (this.isDataLoaded || this.dataLoading) return;

      this.dataLoading = true;

      const tokenInfo = await userStore.getFlushedTokenInfo();
      const originalPageData = await ZodiacApi.getPageData(
        this.tripsInfo.tripIds,
        this.tripsInfo.airlineProfile,
        tokenInfo,
        ['fastPass'],
        ['ancillary'],
      );

      const { startPrice, fastPassData, fastPassDescription } =
        new FastPassParser(originalPageData, this.conversionRates).parse();

      this.startPrice = startPrice;
      this.fastPassData = fastPassData;
      this.fastPassDescription = fastPassDescription;

      this.dataLoading = false;
      this.isDataLoaded = true;
    } catch (e) {
      Log.errorData(e, this.CLASS_NAME, METHOD_NAMES.LOAD_DATA);
    }
  };

  @action
  onSelectedFastPass = async (
    selectedFastPassData: AACheckout.SelectedFastPassData,
  ) => {
    this.selectedFastPassData = selectedFastPassData;
    this.plainAddonItems = await this.generatePlainAddonItems(
      selectedFastPassData,
    );

    this.totalAmount = this.plainAddonItems
      .map((i) => i.price)
      .reduce((a, b) => a + b, 0);
  };

  @action
  generatePlainAddonItems = async (
    selectedFastPassData: AACheckout.SelectedFastPassData,
  ) => {
    const items: AACheckout.PlainAddonItem[] = [];

    selectedFastPassData.map((j) => {
      j.fastPass.map((p, pIndex) => {
        p.map((f) => {
          items.push({
            id: f.code,
            groupBy: 'fastPass',
            currency: f.currency,
            name: f.label,
            price: f.price,
            type: 'fastPass',
            category: 'FastPass',
            departureStation: j.departureStation,
            arrivalStation: j.arrivalStation,
            properties: {
              feeCode: f.code,
            },
            passengerReferenceId: this.paxTitles[pIndex].paxId,
          });
        });
      });
    });

    return items;
  };

  generateCheckoutAddonItems = () => {
    return this.plainAddonItems.map((addonItem) => {
      const journey = this.journeys.find(
        (j) =>
          j.origin.stationCode === addonItem.departureStation &&
          j.destination.stationCode === addonItem.arrivalStation,
      );
      const departureTime = dayjs(journey.designator.departureTime).format(
        'DD/MM/YYYY',
      );
      const arrivalTime = dayjs(journey.designator.arrivalTime).format(
        'DD/MM/YYYY',
      );

      return {
        category: addonItem.category,
        designator: {
          departureStation: addonItem.departureStation,
          departureTime,
          departureTimeUtc: departureTime,
          arrivalStation: addonItem.arrivalStation,
          arrivalTime,
          arrivalTimeUtc: arrivalTime,
        },
        id: addonItem.id,
        passengerReferenceId: addonItem.passengerReferenceId,
        properties: addonItem.properties,
      };
    });
  };
}
