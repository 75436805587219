import { action, makeObservable, observable } from 'mobx';
import { ZodiacApi } from '@/api';
import { userStore } from '@/store/base';
import { Log } from '@/helper/log';
import { mapSelectionToPlainAddonItems, getComponentData } from './parse';

const METHOD_NAMES = {
  LOAD_DATA: 'loadData',
} as const;

export default class AncillaryWheelchairStore {
  private CLASS_NAME = 'AncillaryWheelchairStore';
  @observable wheelchairData: AACheckout.WheelchairComponentData = null;
  @observable startPrice: number | null = null;
  @observable totalAmount = 0;
  @observable plainAddonItems: AACheckout.PlainAddonItem[] = [];
  @observable conversionRates: AACheckout.ConversionRates;
  @observable paxTitles: AACheckout.PaxTitle[];
  @observable selectedWheelchairData: AACheckout.WheelchairSelectedData;
  @observable dataLoading = false;
  @observable isShow = false;
  @observable journeys: AACheckout.Journey[];
  originalResponse: AACheckout.WheelchairOriginalResponse = null;
  tripsInfo: TripsInfo = null;
  convertCurrency: (amount: number) => number;

  constructor() {
    makeObservable(this);
  }

  @action
  loadData = async () => {
    try {
      if (this.isShow || this.dataLoading) {
        return;
      }
      this.dataLoading = true;

      await this.loadDataWith();
      this.parseData();

      this.dataLoading = false;
    } catch (e) {
      Log.errorData(e, this.CLASS_NAME, METHOD_NAMES.LOAD_DATA);
    }
  };

  @action
  loadDataWith = async () => {
    if (this.originalResponse) return;

    const tokenInfo = await userStore.getFlushedTokenInfo();
    const wheelchairParams = { tripIds: this.tripsInfo.tripIds, ...tokenInfo };
    this.originalResponse = await ZodiacApi.getAncillaryWheelchair(
      wheelchairParams,
    );
  };

  isEmptyAncillary(res) {
    return !res.ancillary?.wheelchair;
  }

  @action
  parseData = () => {
    if (
      !this.originalResponse ||
      !this.convertCurrency ||
      !(this.journeys && this.journeys.length > 0) ||
      !(this.paxTitles && this.paxTitles.length > 0)
    ) {
      return;
    }

    if (this.isEmptyAncillary(this.originalResponse)) {
      return;
    }

    const wheelchair = this.originalResponse.ancillary.wheelchair;
    this.wheelchairData = getComponentData(
      wheelchair.data,
      this.convertCurrency,
    );
    this.startPrice = wheelchair.startPrice;
    this.isShow = true;
  };

  @action
  onSelectedWheelchair = async (
    selectedWheelchairData: AACheckout.WheelchairSelectedData,
  ) => {
    this.selectedWheelchairData = selectedWheelchairData;
    this.plainAddonItems = mapSelectionToPlainAddonItems(
      selectedWheelchairData,
      this.wheelchairData,
      this.journeys,
      this.paxTitles,
    );

    this.totalAmount = this.plainAddonItems
      .map((i) => i.price)
      .reduce((a, b) => a + b, 0);
  };

  generateCheckoutAddonItems = () => {
    return this.plainAddonItems.map((addonItem) => {
      return {
        id: addonItem.id,
        category: addonItem.category,
        designator: {
          departureStation: addonItem.departureStation,
          arrivalStation: addonItem.arrivalStation,
          departureTime: null,
          arrivalTime: null,
          departureTimeUtc: null,
          arrivalTimeUtc: null,
          arrivalTerminal: null,
          departureTerminal: null,
        },
        properties: addonItem.properties,
        passengerReferenceId: addonItem.passengerReferenceId,
      };
    });
  };
}
