import LogAPI from '../../base/log.api';
import { RequestInfo, ResponseInfo } from './types';

export class BaseLogHelper {
  static logID = '';
  static route = '';

  static setLogger({ logID, route }: { logID: string; route: string }) {
    BaseLogHelper.logID = logID;
    BaseLogHelper.route = route;
  }

  static getCurrentRoute() {
    return BaseLogHelper.route || window.location.pathname;
  }

  static parseResponse(response: ResponseInfo) {
    return response
      ? {
          status: response.status,
          data: response.data,
          statusText: response.statusText,
        }
      : null;
  }

  static getCallingDetail(request: RequestInfo, response: ResponseInfo) {
    return {
      callingDetail: {
        request,
        response: this.parseResponse(response),
      },
    };
  }

  static async logInfo({ msg, extra = {} }) {
    const route = this.getCurrentRoute();
    return LogAPI.info({
      msg,
      route,
      extra,
    });
  }

  static async logError({ msg, extra = {} }) {
    const route = this.getCurrentRoute();
    return LogAPI.error({
      msg,
      route,
      extra,
    });
  }

  static async logWarn({ msg, extra = {} }) {
    const route = this.getCurrentRoute();
    return LogAPI.warn({
      msg,
      route,
      extra,
    });
  }
}
