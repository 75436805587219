export const CHINA_LOCALES = ['zh-cn', 'zh-tw', 'zh-hk'];

export const AMENITY_ICON_TYPE = {
  HOTEL: 'hotel',
  ROOM: 'room',
  RATE: 'rate',
};

export const API_DATE_FORMAT = 'yyyy-MM-dd';
export const TIMELINE_LOCAL_TIME_LONG_FORMAT = "d MMM, h:mmaaaaa'm'";
export const TIMELINE_LOCAL_TIME_LONGER_FORMAT = "do MMMM yyyy, h:mmaaaaa'm'";
export const PAY_LATER_FORMAT_SHORT_TIME = 'MMM dd, yyyy';
export const RECOMMEND_KEY = 'recommended';
export const THUMBNAIL_SIZE = '350px';
export const HERO_SIZE = '1000px';
export const PENALTY_TYPE_FULL = 'Full';
export const REFUND_DATE_FORMAT = 'ccc, dd LLLL';
export const SHORT_DATE_FORMAT = 'ccc, dd LLL'; // Eg. Thu, 22 Oct

export const AmenityIconsMap = {
  split: {
    level: '#',
    web: '_',
    valid: '-',
  },
  hotel: {
    1: 'wifi',
    2: 'pool',
    3: 'fitness_center',
    4: 'spa',
    5: 'waves',
    6: 'airport_shuttle',
    7: 'smoking_rooms',
    8: 'smoke_free',
    9: 'restaurant',
    10: 'icon-poolside-bar',
    11: 'local_cafe',
    12: 'local_bar',
    13: 'sports_tennis',
    14: 'hot_tub',
    15: 'icon-nightclub',
    16: 'local_parking',
    17: 'room_service',
    18: 'local_laundry_service',
    19: 'luggage_storage',
    20: 'business_center',
    21: 'icon-wheat',
    22: 'concierge_services',
  },
  rate: {
    1: 'wifi',
    2: 'free_breakfast',
    3: 'local_parking',
    20: 'wifi',
    21: 'wifi',
    22: 'restaurant',
    23: 'restaurant',
    24: 'restaurant',
    25: 'restaurant',
    26: 'restaurant',
    27: 'restaurant',
    28: 'restaurant',
    29: 'restaurant',
    30: 'restaurant',
    31: 'restaurant',
    32: 'restaurant',
    33: 'restaurant',
    34: 'restaurant',
    35: 'departure_board',
    36: 'departure_board',
    37: 'departure_board',
    38: 'departure_board',
    39: 'restaurant',
    40: 'restaurant',
    41: 'restore',
    42: 'restore',
    43: 'restore',
    44: 'restaurant',
    45: 'monetization_on',
    46: 'restaurant',
    47: 'monetization_on',
    48: 'restore',
    49: 'monetization_on',
    50: 'restaurant',
    51: 'departure_board',
    52: 'restaurant',
    53: 'restaurant',
    54: 'restaurant',
    55: 'departure_board',
    56: 'departure_board',
    57: 'restaurant',
    58: 'restaurant',
    59: 'restore',
    60: 'restore',
    61: 'monetization_on',
    62: 'restaurant',
    63: 'directions_boat',
    64: 'wifi',
    65: 'restaurant',
    66: 'drive_eta',
    67: 'half_broad',
    68: 'all_inclusive',
    69: 'full_broad',
    70: 'includes_breakfast',
    71: 'includes_breakfast',
    72: 'includes_breakfast',
    73: 'includes_breakfast',
    74: 'includes_breakfast',
    75: 'includes_breakfast',
  },
  room: {
    4: 'smoke_free',
    5: 'smoking_rooms',
    6: 'icon-bathtub',
    7: 'icon-balcony',
    8: 'tv',
    9: 'air_conditioning',
    10: 'kitchen',
    11: 'icon-toiletries',
    12: 'room_service',
    13: 'icon-bottled-water',
    14: 'hot_tub',
    15: 'desk',
    16: 'hair_dryer',
    17: 'kitchen',
    18: 'microwave',
    19: 'phone',
  },
  other: {
    1: 'satellite',
    2: 'satellite',
    3: 'satellite',
    4: 'satellite',
    5: 'satellite',
    6: 'satellite',
    7: 'satellite',
    8: 'satellite',
    9: 'satellite',
    10: 'satellite',
    11: 'satellite',
    12: 'satellite',
    13: 'satellite',
    14: 'satellite',
    15: 'satellite',
    16: 'satellite',
    17: 'satellite',
    18: 'satellite',
    19: 'satellite',
    20: 'satellite',
    21: 'satellite',
    22: 'satellite',
    23: 'satellite',
    24: 'satellite',
    25: 'satellite',
    26: 'satellite',
    27: 'satellite',
    28: 'satellite',
    29: 'satellite',
    30: 'satellite',
    31: 'satellite',
    32: 'satellite',
    33: 'satellite',
    34: 'satellite',
    35: 'satellite',
    36: 'satellite',
    37: 'satellite',
    38: 'satellite',
    39: 'satellite',
    40: 'satellite',
    41: 'satellite',
    42: 'satellite',
    43: 'satellite',
    44: 'satellite',
    45: 'satellite',
    46: 'satellite',
    47: 'satellite',
    48: 'satellite',
    49: 'satellite',
    50: 'satellite',
    51: 'satellite',
    52: 'satellite',
  },
};

export const CHARGE_TYPE = {
  MANDATORY_FEE: 'mandatory_fee',
  MANDATORY_TAX: 'mandatory_tax',
  PROPERTY_FEE: 'property_fee',
  RESORT_FEE: 'resort_fee',
  SALES_TAX: 'sales_tax',
  SERVICE_FEE: 'service_fee',
  SERVICE_TAX: 'service_tax',
  TAXES_AND_FEES: 'taxes_and_fees',
};

export const PAYMENT_METHOD = {
  LOYALTY: 'LOYALTY',
  CREDIT_CARD: 'CREDIT_CARD',
  PROMO: 'PROMO',
  DIRECT_DEBIT: 'DIRECT_DEBIT',
  BNPL: 'BNPL',
  DISCOUNT: 'DISCOUNT',
};

export const DISCOUNT_METHOD = {
  CARD_DISCOUNT: 'Payment|Coupon Engine',
  LOB_DISCOUNT: 'Hotel',
};

export const PAY_AT_HOTEL_STATUS_BOOKING_CONFIRMATION_SCOPED_TRANSLATE = [
  'payment_failed',
  'user_refund_failed',
];

export const PAY_AT_HOTEL_STATUS_BOOKING_DETAIL_SCOPED_TRANSLATE = [
  'payment_failed',
  'user_cancelled',
  'hotel_cancelled',
  'airasia_cancelled',
  'user_refund_pending',
  'user_refund_failed',
  'user_inventory_release_pending',
  'booking_failed',
  'user_inventory_release_failed',
  'aa_inventory_release_failed',
];

export const ROOMS_LEFT_TAG = 'rooms_left';
