import {
  convertMoneyToDefaultCurrency,
  traverseObj,
} from '@/helper/util/common';
import { CURRENCY_DEFAULT } from '@/helper/constants';

export class FastPassParser {
  private pageData;
  private conversionRates;

  constructor(pageData, conversionRates) {
    this.pageData = pageData;
    this.conversionRates = conversionRates;
  }

  public parse() {
    const { ancillary: originalAncillary = {} } = this.pageData;

    const { fastPass = {} } = originalAncillary;
    const {
      startPrice = '',
      isPaxDependent,
      measure,
      data,
      metaData,
    } = fastPass;

    const fastPassData = this.getFastPassData(data);
    const fastPassDescription = metaData
      ? this.getFastPassDescription(metaData)
      : null;

    return {
      startPrice,
      isPaxDependent,
      measure,
      fastPassData,
      fastPassDescription,
    };
  }

  private getFastPassData(fastPassData): AACheckout.FastPassData {
    const newFastPassData = this.convertPrice(fastPassData, ['price']);

    return this.convertNumber(newFastPassData, ['limitPerPassenger']);
  }

  private getFastPassDescription(metaData): AACheckout.FastPassDescription {
    return {
      fastPass: metaData.map((obj) => {
        const { body } = obj;

        return {
          items: body.map((item) => ({
            icon: 'CheckIcon',
            iconColor: 'green',
            text: item.text,
            textColor: 'greyDark',
          })),
        };
      }),
    };
  }

  private convertPrice(obj, keys) {
    return traverseObj(obj, keys, (obj, prop) => {
      if (obj[prop] !== null && !isNaN(obj[prop])) {
        obj[prop].price = convertMoneyToDefaultCurrency(
          Number(obj[prop].price),
          obj[prop].currency,
          this.conversionRates,
        );
        obj[prop].currency = CURRENCY_DEFAULT;
      }
    });
  }

  private convertNumber(obj, keys) {
    return traverseObj(obj, keys, (obj, prop) => {
      if (obj[prop] !== null && !isNaN(obj[prop])) {
        obj[prop] = Number(obj[prop]);
      }
    });
  }
}
