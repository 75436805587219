import { CURRENCY_DEFAULT } from '@/helper/constants';

// create a single baggage item
function createBaggageItem(item, convertCurrency, includeBundle) {
  const result: any = {
    //amount: item.price.amount,
    amount: convertCurrency(item.price.amount),
    //currency: item.price.currency || 'CNY',
    currency: CURRENCY_DEFAULT,
    id: item.id,
    isIncluded: item.rules?.isIncluded || false,
    isPreSelected: item.rules?.isPreselected || false,
    title: item.properties.baggageDimension.map((dimension) => ({
      dimension: formatDimension(dimension),
      weight: `${dimension.weight?.value || '0'} kg`,
    })),
  };
  if (includeBundle) {
    const withBundle = item.rules.withBundle;
    result.withBundle = withBundle;
    result.isIncluded = result.isIncluded || withBundle;
    result.icon = withBundle ? 'StarInvertedIcon' : '';
  }

  return result;
}

// format dimension info
function formatDimension(dimension) {
  const length = dimension.length?.value || '0';
  const width = dimension.width?.value || '0';
  const height = dimension.height?.value || '0';
  return `${length} x ${width} x ${height} cm`;
}

// generate baggage data for each pax
function generatePaxBaggageData(
  paxTitles,
  handCarryBaggageData,
  checkedBaggageData,
  sportsEquipmentBaggageData,
  metaData,
) {
  return paxTitles.map(() => [
    {
      baggageHeadingColor: 'secondaryBlue',
      baggageList: handCarryBaggageData,
      baggageType: 'handCarry',
      baggageTypeColor: 'secondaryLight',
      showToolTip: true,
      toolTipColor: 'secondaryBlue',
      ...generateToolTipInfo('hand', metaData),
    },
    {
      baggageHeadingColor: 'blueMedium',
      baggageList: checkedBaggageData,
      baggageType: 'checkedBaggage',
      baggageTypeColor: 'blueActive',
      showToolTip: true,
      toolTipColor: 'blueMedium',
      ...generateToolTipInfo('hold', metaData),
    },
    {
      baggageHeadingColor: 'yellow',
      baggageList: sportsEquipmentBaggageData,
      baggageType: 'sportsEquip',
      baggageTypeColor: 'yellowLight',
      showToolTip: true,
      toolTipColor: '',
      ...generateToolTipInfo('sportsEquipment', metaData),
    },
  ]);
}

// generate tool tip content
function generateToolTipInfo(baggageType, metaData) {
  const tooltips = metaData.tooltips[baggageType];
  const toolTipContent = [];

  if (tooltips && tooltips.body) {
    tooltips.body.forEach((section) => {
      if (section.type === 'list') {
        toolTipContent.push({
          content: section.texts,
          type: 'list',
        });
      } else if (section.type === 'text') {
        toolTipContent.push({
          content: section.texts,
          type: 'text',
        });
      } else if (section.type === 'icons') {
        toolTipContent.push({
          content: section.texts.map((icon) => ({
            icon: icon.id,
            translationKey: icon.text,
          })),
          type: 'icons',
        });
      }
    });
  }

  if (tooltips && tooltips.hyperlink) {
    toolTipContent.push({
      ctaLink: tooltips.hyperlink.url,
      ctaText: tooltips.hyperlink.text,
      type: 'link',
    });
  }

  return {
    toolTipTitle: tooltips.header,
    toolTipContent,
  };
}

// parse response and get data for component
export function getBaggageComponentData(
  response,
  paxTitles,
  convertCurrency,
  journeys,
  includeBundle = true,
) {
  const handCarryBaggageData = parseBaggageList(
    response,
    'hand',
    0,
    convertCurrency,
    includeBundle,
  ); // hand carry baggage data
  const checkedBaggageData = parseBaggageList(
    response,
    'hold',
    0,
    convertCurrency,
    includeBundle,
  );
  // get meta data from reponse
  const metaData = response.ancillary.baggage.metaData;
  const sportsEquipmentBaggageData = parseBaggageList(
    response,
    'sportsEquipment',
    0,
    convertCurrency,
    includeBundle,
  );
  const paxBaggageData = generatePaxBaggageData(
    paxTitles,
    handCarryBaggageData,
    checkedBaggageData,
    sportsEquipmentBaggageData,
    metaData,
  );

  // parse return journey data if exists
  let returnJourneyPaxBaggageData = [];
  if (response.ancillary.baggage.data.length > 1) {
    const returnHandCarryBaggageData = parseBaggageList(
      response,
      'hand',
      1,
      convertCurrency,
      includeBundle,
    );
    const returnCheckedBaggageData = parseBaggageList(
      response,
      'hold',
      1,
      convertCurrency,
      includeBundle,
    );
    const returnSportsEquipmentBaggageData = parseBaggageList(
      response,
      'sportsEquipment',
      1,
      convertCurrency,
      includeBundle,
    );

    returnJourneyPaxBaggageData = generatePaxBaggageData(
      paxTitles,
      returnHandCarryBaggageData,
      returnCheckedBaggageData,
      returnSportsEquipmentBaggageData,
      metaData,
    );
  }

  // get station codes
  const stationCodes = getStationCodes(journeys);

  // get start price
  const startPrice = response.ancillary.baggage.startPrice || 0;

  return {
    paxBaggageData,
    returnJourneyPaxBaggageData,
    stationCodes,
    startPrice,
  };
}

// parse baggage list and sort
function parseBaggageList(
  response,
  baggageType,
  journeyIndex,
  convertCurrency,
  includeBundle,
) {
  const result = [];

  const journey = response.ancillary.baggage.data[journeyIndex];
  if (!journey) return result;

  const baggageItems = journey.ancillarylist[baggageType];
  if (baggageItems) {
    // handle baggage items for current journey
    baggageItems.forEach((item) => {
      const baggageItem = createBaggageItem(
        item,
        convertCurrency,
        includeBundle,
      );
      result.push(baggageItem);
    });
  }

  // sort by weight
  result.sort((a, b) => {
    if (a.isIncluded && !b.isIncluded) return -1;
    if (!a.isIncluded && b.isIncluded) return 1;
    return parseFloat(a.title[0].weight) - parseFloat(b.title[0].weight);
  });

  return result;
}

function getStationCodes(journeys: AACheckout.Journey[]) {
  if (journeys.length === 0) return {};

  const firstJourney = journeys[0];

  // use the first journey's origin and the first journey's destination
  return {
    departStation: {
      stationCode: firstJourney.origin.stationCode,
      stationName: firstJourney.origin.stationName,
    },
    arrivalStation: {
      stationCode: firstJourney.destination.stationCode,
      stationName: firstJourney.destination.stationName,
    },
  };
}

// get depart designator
export function getDepartDesignator(response) {
  return response?.ancillary.baggage.data[0]?.designator;
}

// get return designator
export function getReturnDesignator(response) {
  return response?.ancillary.baggage.data[1]?.designator;
}
