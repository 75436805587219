import Spinner from '@airasia/phoenix-components/components/Spinner';

import './index.scss';
const Default = (props: { fullScreen?: boolean; height?: number | string }) => {
  const { fullScreen, height = 220 } = props;

  const style: React.CSSProperties = {
    height: fullScreen
      ? '100vh'
      : typeof height === 'number'
      ? `${height}px`
      : height,
  };

  return (
    <div
      className={`loading-component ${fullScreen ? 'full-screen' : ''}`}
      style={style}
    >
      <Spinner
        animationDuration="1s"
        height="50px"
        show
        strokeWidth="2"
        theme="AIRASIA_CLASSIC"
        width="50px"
      />
    </div>
  );
};

export default Default;
