import React from 'react';
import { observer } from 'mobx-react';
import globalMessageStore from '@/store/common/global-message';
import PopupMessage from '@airasia/phoenix-components/components/PopupMessage';

export default observer(() => {
  return (
    <PopupMessage
      accessible
      autoHideDuration={globalMessageStore.duration}
      isBannerClickable
      isClosable={false}
      isMobile
      isShow={globalMessageStore.showMessage}
      message={globalMessageStore.message}
      onClose={globalMessageStore.onClose}
      showLeftIcon={false}
      showOnTopMobile
      theme="AIRASIA_CLASSIC"
      type={globalMessageStore.type}
      zIndex={9}
    />
  );
});
