class ParamManager {
  private params: { [key: string]: any } = {};

  setParam(key: string, value: any) {
    this.params[key] = value;
  }

  getParam(key: string): any {
    return this.params[key];
  }

  setParams(params: { [key: string]: any }) {
    Object.keys(params).forEach((key) => {
      this.setParam(key, params[key]);
    });
  }

  getParams(): { [key: string]: any } {
    return { ...this.params };
  }

  getOpenId(): string | null {
    return this.getParam('openId');
  }

  getBgKey(): string | null {
    return this.getParam('bgKey');
  }
}

const paramManager = new ParamManager();
export default paramManager;
