import { action, observable, makeObservable } from 'mobx';

class GlobalAlertStore {
  @observable isVisible = false;
  @observable translation = {
    title: '',
    subTitle: '',
    primaryButtonTitle: '',
  };
  @observable headerImage = '';
  @observable primaryHandleClick = () => {};

  constructor() {
    makeObservable(this);
  }

  @action
  showAlert({ translation, headerImage = '', primaryHandleClick }) {
    this.translation = translation;
    this.headerImage = headerImage;
    this.primaryHandleClick = primaryHandleClick;
    this.isVisible = true;
  }

  @action
  hideAlert() {
    this.isVisible = false;
  }
}

export default new GlobalAlertStore();
