import validator from 'validator';
import { ValidationRule, ValidationRules } from '@/helper/validator';
import i18n from '@/helper/i18n';

const { chain: t } = i18n;

const nameRule: ValidationRule = {
  rules: [
    { required: true },
    {
      pattern: /^[a-zA-Z\s]+$/,
      message: t.hotel.bookerDetails['message.alphabetsError'],
    },
  ],
};

export const validationRules: ValidationRules = {
  'guests.[].firstName': nameRule,
  'guests.[].lastName': nameRule,
  'booking.firstName': nameRule,
  'booking.lastName': nameRule,
  'booking.email': {
    rules: [
      {
        required: true,
      },
      {
        validatorMethod: 'isEmail',
        message: t.hotel.bookerDetails['message.emailError'],
      },
    ],
  },
  'booking.phone.dialingCode': {
    rules: [
      {
        required: true,
      },
    ],
  },
  'booking.phone.number': {
    rules: [
      {
        required: true,
      },
      {
        callback: (
          value: any,
          path: string,
          validationPath: string,
          rule: ValidationRules,
          data: object,
        ) => {
          const phoneNumber =
            '+' + (data as any).booking.phone.dialingCode + value;

          const valid = validator.isMobilePhone(phoneNumber);
          return {
            message: t.hotel.bookerDetails['message.mobileError'],
            hasError: !valid,
          };
        },
      },
    ],
  },
};
