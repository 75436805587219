import { getCountries } from './countries';
import {
  getGuests,
  getGuestsLimit,
  getPaxDefinitions,
  getYPTAAgeRange,
} from './guests';
import { getFamilyAndFriends } from './family-and-friends';


export const transform = async (pageData, tripsInfo) => {
  const {
    paxDetails = [],
    countryList,
    content,
    bigPointsCalculation,
  } = pageData;

  const { flightDetails } = pageData.flightDetails;
  const { friendsAndFamily: originalFriendsAndFamily, userDetails } =
    pageData.sso;
  const { favoriteMembers = [] } = originalFriendsAndFamily || {};
  const { token: bigPointsCalculationToken } = bigPointsCalculation;
  const passengerDefinitions =
    pageData.flightDefinitions?.definitions?.passenger || {};

  const departureTime = flightDetails.designator.departureTime;
  const familyAndFriends = getFamilyAndFriends(
    favoriteMembers,
    userDetails,
    departureTime,
    passengerDefinitions,
  );
  const countries = getCountries(countryList);
  const guests = getGuests(paxDetails, passengerDefinitions, tripsInfo);
  const guestsLimit = getGuestsLimit(guests);

  const paxDefinition = getPaxDefinitions(passengerDefinitions);
  const yptaAgeRange = getYPTAAgeRange(passengerDefinitions);

  return {
    countries,
    gender: content.gender,
    salutation: content.salutation,
    guests,
    guestsLimit,
    familyAndFriends,
    departureTime,
    bigPointsCalculationToken,
    paxDefinition,
    passengerDefinitions,
    yptaAgeRange,
  };
};
