import { action, makeObservable, observable } from 'mobx';
import { ZodiacApi, CommonApi } from '@/api';
import { userStore } from '@/store/base';
import { transformPageData } from '@/helper/zodiac';
import {
  getBase64Value,
  getURLParams,
  convertMoneyToDefaultCurrency,
} from '@/helper/util';
import { LOCALE_CODE_DEFAULT } from '@/helper/constants';
import i18n from '@/helper/i18n';
import { Zodiac } from '@/helper/constants';
import Config from '@/config';
import { loadWechatSDK } from '@/helper/wechat';
import { AxiosError } from 'axios';
import { Log } from '@/helper/log';
import { CURRENCY_DEFAULT } from '@/helper/constants';

const t = i18n.chain;
const genderTitleMap = {
  Male: 'MR',
  Female: 'MS',
};

type AddonItem = {
  category: string;
  designator?: object;
  id: string;
  passengerReferenceId: string;
  properties?: object;
};

type TransferOption = '' | 'protected_by_airline' | 'self_transfer';

export default class FlightsCheckoutStore implements PageStore {
  @observable isLoading = true;
  @observable pageData = null;
  @observable showJourneyDetails = false;
  @observable showBaggageChanging = false;

  @observable showTransferInfo = false;
  @observable transferOption: TransferOption = '';

  @observable hasError = false;
  @observable submitLoading = false;

  @observable showMessage = false;
  @observable message = '';

  @observable guestsData: OTACheckout.Passenger[] = [];
  @observable contact = {
    countryCode: '',
    email: '',
    familyName: '',
    givenName: '',
    mobileNumber: '',
    salutation: 'MR',
  };

  @observable defaultContact = {
    countryCode: '',
    email: '',
    familyName: '',
    givenName: '',
    mobileNumber: '',
    salutation: 'MR',
  };

  @observable baggageBreakdown: any[] = [];
  @observable baggageFare = {
    currency: CURRENCY_DEFAULT,
    isDiscount: false,
    title: t.flights.checkout.fareSummary.addOns,
    totalAmount: 0,
    baseTotalAmount: 0,
  };
  @observable baggageSelection: any = {};
  @observable countries: any[] = [];
  @observable documentTypes: any[] = [];
  @observable gender: any = [];
  @observable salutation: any = [];

  @observable contactHasError = false;
  @observable hasDefaultContact = false;
  @observable defaultContactChecked = false;
  @observable loggedInHandled = false;

  addons: AddonItem[] = [];
  originalPageData = null;
  tripsInfo: TripsInfo = null;
  contactFormRef = null;
  guestsFormRef = {};

  _baggageSelectionTemp: any = {};

  constructor() {
    makeObservable(this);
  }

  get totalAmount() {
    if (this.pageData) {
      return convertMoneyToDefaultCurrency(
        this.originalPageData.feeDetails.total +
          this.baggageFare.baseTotalAmount,
        this.pageData.baseCurrency,
        this.pageData.conversionRates,
      );
    } else {
      return 0;
    }
  }

  get fareDetails() {
    if (this.pageData) {
      if (this.baggageChangingFlag) {
        return [
          ...this.pageData.fareDetails,
          {
            fareType: 'addOns',
            ...this.baggageFare,
            fareBreakdown: this.baggageBreakdown
              .filter((b) => b.quantity > 0)
              .map((b) => {
                return {
                  ...b,
                  fare_title: `${b.weight} ${b.displayName} x ${
                    b.quantity *
                    (this.pageData?.journeyDetails.length > 1 ? 2 : 1)
                  }`,
                };
              }),
          },
        ];
      } else {
        return this.pageData.fareDetails;
      }
    } else {
      return [];
    }
  }

  get baggageChangingFlag() {
    if (this.pageData) {
      return this.baggageBreakdown.filter((b) => b.unitPrice > 0).length > 0;
    } else {
      return false;
    }
  }

  @action
  onLoad = async () => {
    document.title = t.flights.checkout.title;
    this.parseSearchParams();
    this.loadData();

    loadWechatSDK();
  };

  @action
  onUnload = () => {};

  parseSearchParams = async () => {
    const searchParams = getURLParams();
    this.tripsInfo = getBase64Value(searchParams.get('tripsInfo'));
    await userStore.setLoginFromSearchParams(searchParams);
  };

  loadData = async () => {
    try {
      const tokenInfo = await userStore.getFlushedTokenInfo();

      this.originalPageData = await ZodiacApi.checkPriceWithoutPolling(
        this.tripsInfo.tripIds,
        this.tripsInfo.airlineProfile,
        tokenInfo,
      );

      const pageData = await transformPageData(
        this.originalPageData,
        this.protectedByActionCallback,
        this.selfTransferActionCallback,
      );
      Log.debug(pageData);

      this.baggageBreakdown = pageData.baggage.baggageBreakdown;
      this.guestsData = pageData.guests;
      this.countries = pageData.countries;
      this.documentTypes = pageData.documentTypes;
      this.gender = pageData.gender;
      this.salutation = pageData.salutation;

      this.pageData = pageData;
    } catch (error) {
      console.error(error);
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  };

  @action
  toggleOnShowJourneyDetails = async () => {
    this.showJourneyDetails = true;
  };

  @action
  toggleOffShowJourneyDetails = async () => {
    this.showJourneyDetails = false;
  };

  @action
  handleContactField = async (e) => {
    if (e.trigger || e.trigger === undefined) {
      this.contact = {
        ...this.contact,
        ...e,
      };
    }
  };

  @action
  handleGuestField = async (e, guestData: OTACheckout.Passenger) => {
    if (e.trigger || e.trigger === undefined) {
      if (e.travellingWith) {
        guestData.data.travellingWith &&
          (this.guestsData.find(
            (g) => g.referenceId === guestData.data.travellingWith,
          ).infantRef = '');
        this.guestsData.find(
          (g) => g.referenceId === e.travellingWith,
        ).infantRef = guestData.referenceId;
      }
      guestData.data = {
        ...guestData.data,
        ...e,
      };

      if (
        guestData.seq === 1 &&
        e.trigger &&
        (e.familyName || e.givenName) &&
        this.guestsData[0].data.familyName &&
        this.guestsData[0].data.givenName &&
        !this.hasDefaultContact
      ) {
        this.defaultContact = {
          countryCode: '',
          email: '',
          familyName: this.guestsData[0].data.familyName,
          givenName: this.guestsData[0].data.givenName,
          mobileNumber: '',
          salutation: genderTitleMap[this.guestsData[0].data.gender],
        };

        if (!this.hasDefaultContact) {
          this.hasDefaultContact = true;
          this.defaultContactChecked = true;
        }
      }
    }
  };

  @action
  loggedInUpdated = async () => {
    if (this.loggedInHandled) return;

    this.loggedInHandled = true;

    const findCountries = this.countries.filter(
      (m) => m.dialingCode.replace('+', '') === userStore.user.dialingCode,
    );
    let countryCode = '';
    let mobileNumber = '';
    if (findCountries.length === 1) {
      mobileNumber = userStore.user.phoneNumber;
      countryCode = findCountries[0].value;
    }
    this.defaultContact = {
      countryCode,
      email: userStore.user.username,
      familyName: userStore.user.lastName,
      givenName: userStore.user.firstName,
      mobileNumber,
      salutation: userStore.user.title,
    };

    if (!this.hasDefaultContact) {
      this.hasDefaultContact = true;
      this.defaultContactChecked = true;
    }
  };

  @action
  validateForm = async () => {
    if (this.contactFormRef && this.contactFormRef.current) {
      await this.contactFormRef.current.validateForm();
      await this.contactFormRef.current.submitForm();
      this.contactHasError = !this.contactFormRef.current.isValid;
    } else {
      this.contactHasError = true;
    }

    await Promise.all(
      Object.getOwnPropertyNames(this.guestsFormRef).map(async (index) => {
        const formRef = this.guestsFormRef[index] || {};

        await formRef.personalInfoRef?.current?.validateForm();
        await formRef.passportInfoRef?.current?.validateForm();
        await formRef.personalInfoRef?.current?.submitForm();
        await formRef.passportInfoRef?.current?.submitForm();

        this.guestsData[index].hasError =
          !formRef.personalInfoRef?.current?.isValid ||
          !formRef.passportInfoRef?.current?.isValid;

        this.guestsData[index].data = {
          ...formRef.personalInfoRef?.current?.values,
          type: formRef.passportInfoRef?.current?.values.type,
          expiryDate: formRef.passportInfoRef?.current?.values.expiryDate,
          docNumber: formRef.passportInfoRef?.current?.values.docNumber,
        };

        if (this.guestsData[index].paxType === 'infant') {
          this.guestsData[index].data.travellingWith =
            formRef.passportInfoRef?.current?.values.travellingWith;
        }
      }),
    );

    const formHasError =
      this.guestsData.some((g) => g.hasError) || this.contactHasError;

    const contactValues = this.contactFormRef?.current?.values;

    if (contactValues) {
      this.contact = {
        ...this.contact,
        ...contactValues,
      };
    }

    if (formHasError) {
      this.message = t.flights.checkout.errors.formError;
      this.showMessage = !!this.message;
      this.submitLoading = false;
      return false;
    } else {
      return true;
    }
  };

  @action
  toggleOnShowBaggageChanging = async () => {
    this._baggageSelectionTemp = JSON.parse(
      JSON.stringify(this.baggageSelection),
    );
    this.showBaggageChanging = true;
  };

  @action
  toggleOffShowBaggageChanging = async () => {
    this.showBaggageChanging = false;
    this.baggageSelection = this._baggageSelectionTemp;
    this.recalcbaggage();
  };

  @action
  baggageSelected = async (pax: OTACheckout.Passenger, selection: any) => {
    this.baggageSelection[pax.referenceId] = selection;
    this.recalcbaggage();
  };

  @action
  recalcbaggage = async () => {
    const addons: any[] = [];
    const designator =
      this.originalPageData?.flightDetails.flightDetails.designator;

    Object.getOwnPropertyNames(this.baggageSelection).map((prop) => {
      const paxSelection = this.baggageSelection[prop];
      const pax = this.guestsData.filter((g) => g.referenceId === prop)[0];

      if (paxSelection.handCarry) {
        paxSelection.handCarry.map((s) => {
          if (s >= 0) {
            const baggage = pax.baggageData[0].baggageList[s];

            if (!baggage.isIncluded) {
              addons.push({
                category: 'Baggage',
                designator,
                id: baggage.id,
                passengerReferenceId: pax.referenceId,
                properties: baggage.properties,
              });
            }
          }
        });
      }

      if (paxSelection.checkedBaggage) {
        paxSelection.checkedBaggage.map((s) => {
          if (s >= 0) {
            const baggage = pax.baggageData[1].baggageList[s];

            if (!baggage.isIncluded) {
              addons.push({
                category: 'Baggage',
                designator,
                id: baggage.id,
                passengerReferenceId: pax.referenceId,
                properties: baggage.properties,
              });
            }
          }
        });
      }
    });

    this.baggageBreakdown.map((b) => {
      if (!b.isIncluded) {
        b.quantity = addons.filter((a) => a.id === b.id).length;
        b.basePrice = b.quantity * b.baseUnitPrice;
        b.price = convertMoneyToDefaultCurrency(
          b.basePrice,
          this.pageData.baseCurrency,
          this.pageData.conversionRates,
        );
      }
    });
    this.addons = addons;

    this.baggageFare.baseTotalAmount = this.baggageBreakdown
      .map((b) => b.basePrice)
      .reduce((a, b) => a + b);

    this.baggageFare.totalAmount = convertMoneyToDefaultCurrency(
      this.baggageFare.baseTotalAmount,
      this.pageData.baseCurrency,
      this.pageData.conversionRates,
    );
  };

  @action
  baggageChangingSubmit = async () => {
    this.showBaggageChanging = false;
  };

  @action
  protectedByActionCallback = async () => {
    this.showTransferInfo = true;
    this.transferOption = 'protected_by_airline';
  };

  @action
  selfTransferActionCallback = async () => {
    this.showTransferInfo = true;
    this.transferOption = 'self_transfer';
  };

  @action
  onKnowIt = async () => {
    this.showTransferInfo = false;
  };

  @action
  onContinue = async () => {
    this.message = '';
    this.showMessage = false;
    this.submitLoading = true;

    // 1. form validation
    try {
      const formValid = await this.validateForm();

      if (!formValid) {
        this.submitLoading = false;
        return;
      }
    } catch (error) {
      console.error(error);
      this.submitLoading = false;
      return;
    }

    // 2. check price
    try {
      const tokenInfo = await userStore.getFlushedTokenInfo();
      const checkResult = await ZodiacApi.checkPrice(
        this.tripsInfo.tripIds,
        this.tripsInfo.airlineProfile,
        tokenInfo,
      );

      this.originalPageData = checkResult.pageData;
      const pageData = await transformPageData(
        checkResult.pageData,
        this.protectedByActionCallback,
        this.selfTransferActionCallback,
      );
      this.pageData = pageData;

      if (!checkResult.isValid) {
        this.message = t.flights.checkout.errors.priceValidationError;
        this.showMessage = !!this.message;
        this.submitLoading = false;
        return;
      }
    } catch (error) {
      console.error(error);
      this.submitLoading = false;
      return;
    }

    // 3. compose checkout data
    const checkoutData = await this.composeCheckoutData();

    // 4. submit checkout
    try {
      const res = await ZodiacApi.checkout(checkoutData);
      this.submitLoading = false;

      if (res.successRedirectionUrl) {
        const wmpURL = '/pages/ota/payment/index';
        const param = new URLSearchParams();
        param.set('data', res.successRedirectionUrl);
        (window as any).wx.miniProgram.navigateTo({
          url: `${wmpURL}?${param.toString()}`,
        });
      }
    } catch (error) {
      console.error(error);

      this.message = t.flights.checkout.errors.checkoutUnknownError;

      if (error instanceof AxiosError) {
        const { message } = error.response.data.error;

        this.message = message;

        this.message &&
          (this.message =
            this.message.charAt(0).toUpperCase() + this.message.slice(1));
      }

      this.showMessage = !!this.message;
      this.submitLoading = false;
      return;
    }
  };

  composeCheckoutData = async () => {
    const loggedIn = userStore.isValidated && userStore.isLogin;

    let loggedInUserInfo = null;
    if (loggedIn) {
      const tokenInfo = await userStore.getFlushedTokenInfo();
      const apiKey = await CommonApi.SSOAuth.getApiKey();

      const findCountries = this.countries.filter(
        (m) => m.dialingCode.replace('+', '') === userStore.user.dialingCode,
      );

      let countryCode = null;
      if (findCountries.length === 1) {
        countryCode = findCountries[0].value;
      }

      loggedInUserInfo = {
        firstName: userStore.user.firstName,
        middleName: null,
        lastName: userStore.user.lastName,
        title: userStore.user.title,
        gender: userStore.user.gender,
        dob: null,
        emailId: userStore.user.username,
        countryCode,
        dialingCode: userStore.user.dialingCode,
        nationality: null,
        residentCountry: null,
        customerNumber: null,
        bigMemberId: userStore.user.loyaltyId,
        paxType: userStore.user.paxType,
        ssoDetails: {
          accessToken: tokenInfo.accessToken,
          refreshToken: tokenInfo.refreshToken,
          clientId: Config.SSO.CLIENT,
          apiKey: apiKey,
          userId: userStore.user.id,
          origin: window.location.origin,
          phoneNumberVerified: userStore.user.phoneNumberVerified,
          notificationPreference: userStore.user.notificationPreference,
          ssoStatus: userStore.user.status,
        },
        postalCode: null,
        state: null,
        city: null,
      };
    }

    const contactDetails = {
      title: this.contact.salutation,
      firstName: this.contact.givenName,
      middleName: '',
      lastName: this.contact.familyName,
      emailId: this.contact.email,
      contactNumber: this.contact.mobileNumber,
      countryCode: this.contact.countryCode,
      dialingCode: this.countries.find(
        (c) => c.value === this.contact.countryCode,
      ).dialingCode,
    };

    const passengerDetails = this.guestsData.map((g) => {
      return {
        firstName: g.data.givenName,
        lastName: g.data.familyName,
        dob: g.data.dateOfBirth,
        nationality: g.data.nationality,
        paxType: g.paxType,
        referenceId: g.referenceId,
        bigMemberId: '',
        title: genderTitleMap[g.data.gender],
        gender: g.data.gender,
        travelDocuments: [
          {
            docNumber: g.data.docNumber,
            type: g.data.type,
            expiryDate: g.data.type === 'id' ? null : g.data.expiryDate,
          },
        ],
      };
    });

    const selectedAncillaries = this.addons;

    return {
      geoId: Zodiac.GEO_ID,
      locale: LOCALE_CODE_DEFAULT,
      airlineProfile: this.tripsInfo.airlineProfile,
      tripType: this.pageData?.journeyDetails.length > 1 ? 'R' : 'O',
      tripId: [`${this.tripsInfo.tripIds}`],
      totalCartOrder: this.totalAmount,
      totalCardOrderCurrency: this.pageData?.currency,
      passengerDetails,
      loggedInUserInfo,
      contactDetails,
      emergencyContact: null,
      mailingAddress: null,
      companyDetails: null,
      currencyDetails: {
        baseCurrency: this.pageData?.baseCurrency,
        transactionCurrency: this.pageData?.currency,
      },
      selectedAncillaries,
      bookingFlightDetails: {
        cartDetails: this.originalPageData?.flightDetails.cartDetails,
        flightDetails: this.originalPageData?.flightDetails.flightDetails,
        priceChanged: this.originalPageData?.flightDetails.priceChanged,
      },
      clientInfo: {
        platform: 'WMP',
        cleverTapId: null,
        gaId: null,
      },
      redirectionLinks: {
        selectPage: Config.AIRASIA_WMP_H5,
        confirmationPage: Config.AIRASIA_WMP_H5,
      },
      favouriteMembers: null,
    };
  };
}
