import { useState, useEffect } from 'react';

function useLoadScript(src: string, retryCount = 2): boolean {
  const [loaded, setLoaded] = useState(false);
  const [retry, setRetry] = useState(0);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      setLoaded(true);
    };
    script.onerror = () => {
      if (retry < retryCount) {
        setTimeout(() => {
          script.remove();
          document.body.appendChild(script);
        }, 1000);
        setRetry(retry + 1);
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [src, retry, retryCount]);

  return loaded;
}

export default useLoadScript;
