import i18n from '@/helper/i18n';
import { AIRLINE_PROFILES } from '../consts';

const t = i18n.chain;

export const getTravelDocTypesForGuest = (
  supportedDocTypes,
): AACheckout.TravelDocTypes[] => {
  const { guestDetailsForm } = t.flights.checkout;

  const documentTypes = supportedDocTypes.map((doc) => {
    const { type } = doc;

    return {
      label: guestDetailsForm[type],
      value: type,
    };
  });

  return documentTypes;
};

export const travelDocIsAvailable = (airlineProfile, doc) => {
  const { isDisplayed } = doc;

  // TODO will validate in future for kiwi airline profile
  if (airlineProfile === AIRLINE_PROFILES[1]) {
    return isDisplayed;
  }

  return isDisplayed && airlineProfile === AIRLINE_PROFILES[0];
};

export const isDocMandatory = (docs, type) => {
  const passportDef = docs.find((doc) => type === doc.type);

  return passportDef && passportDef.isRequired;
};
