// Calculate the BIG Point multiplier. Consider to remove this when the backend returns the right multiplier value
// See more: https://airasia.atlassian.net/browse/HOTEL-3008
export const calculateBigPointMultiplier = (multiplier) => {
  const multiplierNum = multiplier ? Number(multiplier) : undefined;
  if (multiplierNum) {
    const BASE_VALUE = 0.5;
    let value = multiplierNum / BASE_VALUE;
    value = parseFloat(value.toFixed(2));
    if (value !== 0 && value !== 1) {
      return value;
    }
  }
  return undefined;
};
