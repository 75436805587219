import { action, observable, makeObservable, computed } from 'mobx';
import { convertToDefaultDate, jsonToQuery } from '@/helper/util';
import { getURLParams } from '@/helper/util';

class HotelSearchComponent {
  @observable isShow = false;
  @observable checkin = '';
  @observable checkout = '';
  @observable locationId = '';
  @observable locationName = '';
  @observable totalRooms = 1;
  @observable totalAdults = 1;
  @observable totalChildren = 0;
  @observable adults = '';
  @observable children = '';
  @observable isChangedlocation = false;
  @observable isChangedDate = false;
  @observable roomOccupancy: {
    adult: number;
    child: number;
  }[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  toggle = () => {
    this.isShow = !this.isShow;
  };

  @action
  set = (data) => {
    const preCheckin = this.checkin;
    const preCheckout = this.checkout;
    const preLocationId = this.locationId;

    this.checkin = convertToDefaultDate(data.checkin);
    this.checkout = convertToDefaultDate(data.checkout);
    this.locationId = data.destination.id;

    if (preCheckin != this.checkin || preCheckout != this.checkout) {
      this.isChangedDate = true;
    }

    if (preLocationId != this.locationId) {
      this.isChangedlocation = true;
    }

    this.calculateRooms(data.rooms);
  };

  @action
  calculateRooms = (rooms) => {
    if (rooms.length === 0) {
      return;
    }

    this.roomOccupancy = rooms;
    this.totalRooms = rooms.length;

    let adultTotal = 0;
    let childTotal = 0;
    let adults = '';
    let children = '';
    for (const passenger of rooms) {
      adultTotal += parseInt(passenger.adult.toString());
      childTotal += parseInt(passenger.child.toString());
      adults += passenger.adult + ',';
      children += passenger.child ? passenger.child + '_0,' : '';
    }

    adults = adults.slice(0, -1);
    children = children.slice(0, -1);

    this.adults = adults;
    this.children = children;
    this.totalAdults = adultTotal;
    this.totalChildren = childTotal;
  };

  @action
  setFromURL = () => {
    const params = getURLParams();
    this.checkin = params.get('checkin');
    this.checkout = params.get('checkout');
    this.locationId = params.get('locationId');
    this.locationName = params.get('locationName');
    this.adults = params.get('adults');
    this.children = params.get('children');
    this.totalAdults = parseInt(params.get('totalAdults'));
    this.totalChildren = parseInt(params.get('totalChildren'));
    this.totalRooms = parseInt(params.get('totalRooms'));

    const children =
      this.children === '0'
        ? []
        : this.children.split(',').map((c) => parseInt(c.replace('_0', '')));

    const adults = this.adults.split(',');
    this.roomOccupancy = adults.map((_n, i) => {
      return {
        adult: parseInt(adults[i]),
        child: children.filter((c) => c === i + 1).length,
        infant: 0,
      };
    });
  };

  @computed
  get query() {
    return jsonToQuery({
      checkin: this.checkin,
      checkout: this.checkout,
      locationId: this.locationId,
      adults: this.adults,
      children: this.children,
      totalRooms: this.totalRooms,
      totalAdults: this.totalAdults,
      totalChildren: this.totalChildren,
    });
  }
}

export default new HotelSearchComponent();
