import { CURRENCY_DEFAULT } from '@/helper/constants';

export const mapSelectionToCheckoutData = (
  selectedInsurances: AACheckout.SelectedInsurance[],
  paxTitles: AACheckout.PaxTitle[],
  designator: any,
  convertCurrency: (amount: number) => number,
): AACheckout.InsuranceCheckoutData[] => {
  // extract paxId for all passengers
  const paxIds = paxTitles.map((paxTitle) => paxTitle.paxId);

  // generate payment data for each insurance item
  return selectedInsurances.map((insurance, index) => ({
    id: `insurance_${index + 1}`, // generate ID dynamically based on index
    passengerReferenceId: paxIds.join(','),
    category: 'Insurance',
    designator,
    properties: {
      paxCovered: paxIds, //  all paxIds from paxTitles array
      selectedInsurance: [
        {
          planCode: insurance.planCode,
          ssrFeeCode: insurance.ssrFeeCode.toString(),
          totalPremiumAmount: convertCurrency(insurance.price),
        },
      ],
    },
  }));
};

// create plaiin addon item
function createPlainAddonItem(
  insurance: AACheckout.SelectedInsurance,
  index: number,
  paxIds: string[],
  designator: any,
  convertCurrency: (amount: number) => number,
): AACheckout.PlainAddonItem {
  return {
    id: `insurance_${index + 1}`,
    groupBy: `insurance_${index + 1}`,
    currency: CURRENCY_DEFAULT,
    name: `${paxIds.length} x ${insurance.title}`,
    price: insurance.price,
    type: 'insurance',
    category: 'Insurance',
    departureStation: designator.departureStation,
    arrivalStation: designator.arrivalStation,
    properties: {
      paxCovered: paxIds,
      selectedInsurance: [
        {
          planCode: insurance.planCode,
          ssrFeeCode: insurance.ssrFeeCode.toString(),
          totalPremiumAmount: convertCurrency(insurance.price),
        },
      ],
    },
    passengerReferenceId: paxIds.join(','),
  };
}

// map selected insurances to plain addon items
export const mapSelectionToPlainAddonItems = (
  selectedInsurances: AACheckout.SelectedInsurance[],
  paxTitles: AACheckout.PaxTitle[],
  designator: any,
  convertCurrency: (amount: number) => number,
): AACheckout.PlainAddonItem[] => {
  const paxIds = paxTitles.map((paxTitle) => paxTitle.paxId);

  return selectedInsurances.map((insurance, index) =>
    createPlainAddonItem(insurance, index, paxIds, designator, convertCurrency),
  );
};

// calculate total price for single flight
export function calculateTotalPrice(
  flightSelections: AACheckout.SelectedInsurance[],
): number {
  let totalPrice = 0; // initial total price as 0

  // iterate over each insurance in flightSelection
  Object.values(flightSelections).forEach((insurance) => {
    // calculate the total price
    insurance && (totalPrice += insurance.price);
  });

  return totalPrice;
}
