export const CANCELLATION_REASONS = [
  'BETTER_DEAL',
  'PLAN_CHANGE',
  'BOOK_DIFFERENT_HOTEL',
  'MISTAKE',
  'OTHER_REASON',
];

export const CANCELLATION_REFUND_TYPE = {
  FULL_REFUND: 'fullRefund',
  PARTIAL_REFUND: 'partialRefund',
  NO_REFUND: 'noRefund',
};

export const BOOKING_STATUS = {
  CONFIRMED: 'confirmed',
  USER_REFUND_PENDING: 'user_refund_pending',
};

export const CANCELLATION_PENALTY_TYPES = {
  FULL: 'Full',
  NONE: 'None',
  PARTIAL: 'Partial',
};

export const CANCELLATION_CHARGE_TYPES = {
  AMOUNT_TYPE: 'A',
  PERCENT_TYPE: 'P',
  NIGHTS_TYPE: 'N',
};

export const BOOKING_STATUS_COLOR = {
  CONFIRMED: 'confirmed',
  SUCCESS: 'success',
  WARNING: 'warning',
  FAILED: 'failed',
};

export const BOOKING_PENDING_STATUSES = [
  'payment_pending',
  'inventory_pending',
];

export const BOOKING_CHECKOUT_KEY = 'aa_hotels_checkout_key';

export const DEFAULT_LAYOUT_STATUS = 'defaultStatus';

export const BOOKING_MAPPING = {
  orderId_null: {
    statusId: 'orderIdNull',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: false,
      paymentDetail: true,
    },
  },
  payment_failed_temp: {
    statusId: 'paymentFailed',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: false,
      showRefundInformation: false,
      refundPolicy: false,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: false,
      paymentDetail: false,
    },
    confirmation: {
      status: true,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  payment_pending: {
    statusId: 'paymentPending',
    color: BOOKING_STATUS_COLOR.WARNING,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: false,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: true,
      paymentDetail: true,
    },
  },
  payment_failed: {
    statusId: 'paymentFailed',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: false,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: false,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  inventory_pending: {
    statusId: 'bookingPending',
    color: BOOKING_STATUS_COLOR.WARNING,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: true,
      paymentDetail: true,
    },
  },
  refund_pending: {
    statusId: 'refundPending',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: true,
      paymentDetail: true,
    },
  },
  payment_void_refund_failed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: false,
      showRefundInformation: false,
      refundPolicy: false,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: false,
      paymentDetail: false,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  booking_failed: {
    statusId: 'refundPending',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: false,
      paymentDetail: true,
    },
  },
  confirmed: {
    statusId: 'bookingConfirmed',
    color: BOOKING_STATUS_COLOR.CONFIRMED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: false,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: true,
      sendVoucher: true,
      cancellation: true,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: true,
      paymentDetail: true,
    },
  },
  completed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.SUCCESS,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: false,
      showRefundInformation: false,
      refundPolicy: false,
      hotelPolicy: false,
      sendVoucher: true,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  user_inventory_release_pending: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'requestedByUser',
    bookingDetail: {
      bookingStatusInfoSymbol: false,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  user_inventory_release_failed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'requestedByUser',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  user_refund_pending: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'requestedByUser',
    bookingDetail: {
      bookingStatusInfoSymbol: false,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  user_refund_failed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'requestedByUser',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  user_cancelled: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'requestedByUser',
    bookingDetail: {
      bookingStatusInfoSymbol: false,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  aa_inventory_release_pending: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  aa_inventory_release_failed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  aa_refund_pending: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  aa_refund_failed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  airasia_cancelled: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  pvdr_refund_pending: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  pvdr_refund_failed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  hotel_cancelled: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  defaultStatus: {
    statusId: 'defaultStatus',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: false,
      paymentDetail: true,
    },
  },
  provisionally_confirmed: {
    statusId: 'bookingReserved',
    color: BOOKING_STATUS_COLOR.CONFIRMED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: false,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: true,
      cancellation: true,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: true,
      paymentDetail: true,
    },
  },
  Debit_Failed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: false,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  hotel_refund_pending: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  hotel_refund_failed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
};

export const BOOKING_PAY_AT_HOTEL_MAPPING = {
  orderId_null: {
    statusId: 'orderIdNull',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: false,
      paymentDetail: true,
    },
  },
  payment_failed_temp: {
    statusId: 'paymentFailed',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: false,
      showRefundInformation: false,
      refundPolicy: false,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: false,
      paymentDetail: false,
    },
    confirmation: {
      status: true,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  payment_pending: {
    statusId: 'paymentPending',
    color: BOOKING_STATUS_COLOR.WARNING,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: false,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: true,
      paymentDetail: true,
    },
  },
  payment_failed: {
    statusId: 'paymentFailed',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: false,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: false,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  inventory_pending: {
    statusId: 'bookingPending',
    color: BOOKING_STATUS_COLOR.WARNING,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: true,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: true,
      paymentDetail: true,
    },
  },
  refund_pending: {
    statusId: 'refundPending',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: true,
      paymentDetail: true,
    },
  },
  payment_void_refund_failed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: false,
      showRefundInformation: false,
      refundPolicy: false,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: false,
      paymentDetail: false,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  booking_failed: {
    statusId: 'refundPending',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: false,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: true,
      paymentDetail: true,
    },
  },
  confirmed: {
    statusId: 'bookingConfirmed',
    color: BOOKING_STATUS_COLOR.CONFIRMED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: true,
      sendVoucher: true,
      cancellation: true,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: true,
      paymentDetail: true,
    },
  },
  completed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.SUCCESS,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: false,
      hotelPolicy: false,
      sendVoucher: true,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  user_inventory_release_pending: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'requestedByUser',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  user_inventory_release_failed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'requestedByUser',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  user_refund_pending: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'requestedByUser',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  user_refund_failed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'requestedByUser',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  user_cancelled: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'requestedByUser',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  aa_inventory_release_pending: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  aa_inventory_release_failed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  aa_refund_pending: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  aa_refund_failed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  airasia_cancelled: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  pvdr_refund_pending: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  pvdr_refund_failed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  hotel_cancelled: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: 'refundedByAAPvdr',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: true,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  defaultStatus: {
    statusId: 'defaultStatus',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: false,
      paymentDetail: true,
    },
  },
};

export const BOOKING_PAY_LATER_MAPPING = {
  payment_failed_temp: {
    statusId: 'paymentFailed',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: false,
      showRefundInformation: false,
      refundPolicy: false,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: false,
      paymentDetail: false,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: false,
      paymentDetail: true,
    },
  },
  payment_pending: {
    statusId: 'paymentPending',
    color: BOOKING_STATUS_COLOR.WARNING,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: false,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: true,
      paymentDetail: true,
    },
  },
  payment_failed: {
    statusId: 'paymentFailed',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: false,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: false,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: false,
      paymentDetail: true,
    },
  },
  inventory_pending: {
    statusId: 'bookingPending',
    color: BOOKING_STATUS_COLOR.WARNING,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: true,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: true,
      paymentDetail: true,
    },
  },
  refund_pending: {
    statusId: 'refundPending',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: true,
      paymentDetail: true,
    },
  },
  booking_failed: {
    statusId: 'refundPending',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: false,
      paymentDetail: true,
    },
  },
  provisionally_confirmed: {
    statusId: 'bookingReserved',
    color: BOOKING_STATUS_COLOR.CONFIRMED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: false,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: true,
      cancellation: true,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: true,
      paymentDetail: true,
    },
  },
  confirmed: {
    statusId: 'bookingConfirmed',
    color: BOOKING_STATUS_COLOR.CONFIRMED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: false,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: true,
      sendVoucher: true,
      cancellation: true,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: true,
      paymentDetail: true,
    },
  },
  completed: {
    statusId: 'bookingCompleted',
    color: BOOKING_STATUS_COLOR.SUCCESS,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: false,
      showRefundInformation: false,
      refundPolicy: false,
      hotelPolicy: false,
      sendVoucher: true,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  inventory_release_pending: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: false,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  inventory_release_failed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  user_refund_pending: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: false,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  user_refund_failed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  user_cancelled: {
    statusId: 'userCancelled',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: false,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: true,
      manageBooking: true,
      paymentDetail: true,
    },
  },
  Debit_Failed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: false,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  aa_inventory_release_pending: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  aa_inventory_release_failed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  aa_refund_pending: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  aa_refund_failed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  airasia_cancelled: {
    statusId: 'airasia_cancelled',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: true,
      bookingId: true,
      manageBooking: true,
      paymentDetail: true,
    },
  },
  hotel_refund_pending: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  hotel_refund_failed: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
  hotel_cancelled: {
    statusId: '',
    color: BOOKING_STATUS_COLOR.FAILED,
    refundMsgId: '',
    bookingDetail: {
      bookingStatusInfoSymbol: true,
      showRefundInformation: false,
      refundPolicy: true,
      hotelPolicy: false,
      sendVoucher: false,
      cancellation: false,
      bookingId: true,
      paymentDetail: true,
    },
    confirmation: {
      status: false,
      bookingId: false,
      manageBooking: false,
      paymentDetail: false,
    },
  },
};

export const PAY_AT_HOTEL_ALLOW_STATUS = [
  'payment_failed',
  'payment_pending',
  'refund_pending',
  'inventory_pending',
  'confirmed',
  'pending',
  'completed',
  'user_cancelled',
  'hotel_cancelled',
  'airasia_cancelled',
  'user_refund_pending',
  'user_refund_failed',
  'booking_failed',
];
