import React from 'react';
import { ROUTE_URL } from '@/helper/constants';
import { NoneLayout } from '@/components';

const Test4 = React.lazy(
  () => import(/* webpackChunkName: "demo-test4" */ '@/pages/demo/test4'),
);

export default [
  {
    path: `${ROUTE_URL.ROOT}/demo/test4`,
    component: Test4,
    isAuth: false,
    layout: NoneLayout,
  },
];
