export interface ErrorConfig<T> {
  [key: string]:
    | {
        getErrorKey: (error: T) => string | undefined;
        handleError: (error: T) => void;
        [key: string]: any;
      }
    | undefined;
}

export const createErrorHandler = <T>(errorConfig: ErrorConfig<T>) => {
  return (error: T) => {
    for (const key in errorConfig) {
      const config = errorConfig[key];
      if (!config) continue;

      const errorKey = config.getErrorKey(error);

      if (errorKey === key) {
        config.handleError(error);
        return;
      }
    }

    // Handle unknown errors
    errorConfig.default?.handleError(error);
  };
};
