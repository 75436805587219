import { observable, makeObservable, action } from 'mobx';

export default class BStore {
  @observable otherData = 'this is B Store';

  constructor() {
    makeObservable(this);
  }

  @action
  updateOtherData(newData) {
    this.otherData = newData;
  }
}
