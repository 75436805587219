import {
  action,
  makeObservable,
  observable,
  computed,
  reaction,
  IReactionDisposer,
} from 'mobx';
import { ZodiacApi } from '@/api';
import { userStore } from '@/store/base';
import { Log } from '@/helper/log';
import {
  getBaggageComponentData,
  mapSelectionToPlainAddonItems,
  getDepartDesignator,
  getReturnDesignator,
  calculateFlightBaggagePrice,
  processUserBaggageSelection,
  initialSelectedBaggageData,
} from './parse';
import BundleInfluence from './bundle';

const METHOD_NAMES = {
  LOAD_DATA: 'loadData',
} as const;

export default class AncillaryBaggageStore implements ComponentStore {
  private CLASS_NAME = 'AncillaryBaggageStore';
  @observable tripsInfo: TripsInfo = null;
  @observable hasReturnFlight = false;
  @observable paxTitles: AACheckout.PaxTitle[];
  @observable baggageData: AACheckout.Baggage = {
    paxBaggageData: [],
    returnJourneyPaxBaggageData: [],
    stationCodes: [],
    startPrice: 0,
  };
  @observable bundleBaggageData = {
    isBundleSelected: false,
    includedIcon: '',
  };
  @observable selectedBaggageData: AACheckout.BaggageSelections = {};
  @observable selectedOriginBaggageData: any = [];
  baggageResponse: AACheckout.BaggageResponse; //baggage response data
  isDataLoaded = false;
  isFlushing = false;
  journeys: AACheckout.Journey[];
  @observable isShow = false;
  @observable plainAddonItems: AACheckout.PlainAddonItem[] = [];
  bundleInfluence: BundleInfluence = new BundleInfluence();
  @observable bundleInfluenceData: AACheckout.BundleInfluenceData;
  @observable selectedFastPassData: AACheckout.SelectedFastPassData;

  bundleReactionDisposer: IReactionDisposer;

  convertCurrency: (amount: number) => number;

  constructor() {
    makeObservable(this);

    this.bundleReactionDisposer = reaction(
      () => [
        this.bundleInfluenceData,
        this.selectedFastPassData,
        this.baggageResponse,
      ],
      () => {
        if (this.baggageResponse) {
          const { baggageResponse, includedIcon, isBundleSelected } =
            this.bundleInfluence.updateLoadData(
              this.baggageResponse,
              this.bundleInfluenceData,
            );

          this.baggageResponse = baggageResponse;
          this.bundleBaggageData = { includedIcon, isBundleSelected };

          // reset parse
          this.parseData(true);
          this.handleFastPassSelected();
        }
      },
    );
  }
  onMount = async () => {};

  onUnmount = async () => {
    if (this.bundleReactionDisposer) {
      this.bundleReactionDisposer();
    }
  };

  loadData = async () => {
    try {
      if (this.isShow || this.isFlushing) {
        return;
      }
      this.isFlushing = true;

      //get baggage data
      await this.loadDataWith();

      //2. parse baggage data
      this.parseData();

      this.isFlushing = false;
    } catch (e) {
      Log.errorData(e, this.CLASS_NAME, METHOD_NAMES.LOAD_DATA);
    }
  };

  @action
  loadDataWith = async () => {
    if (this.isDataLoaded) {
      return;
    }

    const tokenInfo = await userStore.getFlushedTokenInfo();
    const baggageData = { tripIds: this.tripsInfo.tripIds, ...tokenInfo };

    const res = await ZodiacApi.getAncillaryBaggage(baggageData);
    //const res = FACK_RESPONSE;

    this.isDataLoaded = true;
    this.baggageResponse = res;
  };

  isEmptyAncillary(res) {
    return !res.ancillary?.baggage;
  }

  @action
  parseData = (isForceUpdate = false) => {
    if (
      !this.convertCurrency ||
      !this.baggageResponse ||
      !(this.paxTitles && this.paxTitles.length > 0) ||
      !(this.journeys && this.journeys.length > 0)
    ) {
      return;
    }

    if (!isForceUpdate && this.baggageData.paxBaggageData.length > 0) {
      return;
    }

    if (this.isEmptyAncillary(this.baggageResponse)) {
      return;
    }

    const parsedData = getBaggageComponentData(
      this.baggageResponse,
      this.paxTitles,
      this.convertCurrency,
      this.journeys,
    );

    this.baggageData = parsedData;
    this.selectedBaggageData = initialSelectedBaggageData(this.baggageData);
    this.resetPlainAddonItems();
    this.isShow = true;
    this.parseSelectedBaggageData();
  };

  @action
  onSelectedBaggage = (data: AACheckout.BaggageSelections) => {
    this.selectedBaggageData = data;
    this.parseSelectedBaggageData();
  };

  @action
  resetPlainAddonItems = () => {
    this.plainAddonItems = [];
  };

  @action
  parseSelectedBaggageData = () => {
    // handle user selected baggage data
    this.selectedOriginBaggageData = processUserBaggageSelection(
      this.selectedBaggageData,
      this.baggageData.paxBaggageData,
      this.baggageData.returnJourneyPaxBaggageData,
    );

    // Exclude fasspass baggage, and fasspass baggage will displyed under fasspass item, refer to WMP-2951
    let plainAddonItems = this.generatePlainAddonItems().filter(
      (p) => !p.fastpassBaggage,
    );

    // Exclude bundled plainAddonItems
    plainAddonItems = this.bundleInfluence.excludeBundleBaggage(
      plainAddonItems,
      this.bundleInfluenceData,
    );

    this.plainAddonItems = plainAddonItems;
  };

  /**
   * Special handling after having selected Fast Pass.
   * We will reset the price of the second hand carry baggage to be 0, and also include it.
   */
  @action
  handleFastPassSelected = async () => {
    if (!this.selectedFastPassData) return;

    const selectedBaggageData = this.selectedBaggageData;
    const baggageData = this.baggageData;
    const selectedFastPassData = this.selectedFastPassData;

    selectedFastPassData.map((journey, journeyIndex) => {
      const { fastPass } = journey;
      fastPass.map((pax, paxIndex) => {
        const baggageProp =
          journeyIndex === 0 ? 'paxBaggageData' : 'returnJourneyPaxBaggageData';
        const secondHandCarry = baggageData[baggageProp][paxIndex].find(
          (b) => b.baggageType === 'handCarry',
        ).baggageList[1];

        const selectedBaggageProp = journeyIndex === 0 ? 'depart' : 'return';
        const selectedHandCarry =
          selectedBaggageData[selectedBaggageProp][paxIndex].handCarry;

        if (pax.length > 0) {
          if (secondHandCarry) {
            if (!secondHandCarry.original) {
              secondHandCarry.original = {
                amount: secondHandCarry.amount,
                isIncluded: secondHandCarry.isIncluded,
              };
            }

            secondHandCarry.amount = 0;
            secondHandCarry.isIncluded = true;
            secondHandCarry.isFastpassBaggage = true;
          }

          if (selectedHandCarry.length === 1) {
            selectedHandCarry.push(1);
          }
        }

        if (pax.length === 0) {
          if (secondHandCarry && secondHandCarry.original) {
            secondHandCarry.amount = secondHandCarry.original.amount;
            secondHandCarry.isIncluded = secondHandCarry.original.isIncluded;
            secondHandCarry.isFastpassBaggage = false;
          }

          if (selectedHandCarry.length === 2) {
            selectedHandCarry.pop();
          }
        }
      });
    });

    // Make it available to component
    this.baggageData = JSON.parse(JSON.stringify(baggageData));
    this.onSelectedBaggage(JSON.parse(JSON.stringify(selectedBaggageData)));
  };

  @action
  updateJourneys = (journeys: AACheckout.Journey[]) => {
    this.journeys = journeys;
    this.hasReturnFlight = journeys.length > 1;
  };

  generatePlainAddonItems = (): AACheckout.PlainAddonItem[] => {
    const selectedData = this.selectedOriginBaggageData;
    const departDesignator = getDepartDesignator(this.baggageResponse);
    const returnDesignator = getReturnDesignator(this.baggageResponse);

    const addonItems = mapSelectionToPlainAddonItems(
      selectedData,
      this.baggageResponse,
      departDesignator,
      returnDesignator,
      this.paxTitles,
    );
    return addonItems;
  };

  generateCheckoutAddonItems = () => {
    // no need to filter addonItem with price > 0, see WMP-2974
    return this.plainAddonItems.map((addonItem) => {
      return {
        id: addonItem.id,
        category: addonItem.category,
        designator: {
          departureStation: addonItem.departureStation,
          arrivalStation: addonItem.arrivalStation,
          departureTime: null,
          arrivalTime: null,
          departureTimeUtc: null,
          arrivalTimeUtc: null,
          arrivalTerminal: null,
          departureTerminal: null,
        },
        properties: addonItem.properties,
        passengerReferenceId: addonItem.passengerReferenceId,
      };
    });
  };

  // baggage total price
  @computed
  get totalAmount() {
    const selectedData = this.selectedOriginBaggageData;
    let totalAmount = 0;

    // handle baggage price for depart flight
    if (selectedData.depart) {
      selectedData.depart.forEach((flightSelection) => {
        totalAmount += calculateFlightBaggagePrice(flightSelection);
      });
    }

    // handle baggage price for return flight
    if (selectedData.return) {
      selectedData.return.forEach((flightSelection) => {
        totalAmount += calculateFlightBaggagePrice(flightSelection);
      });
    }

    return totalAmount;
  }
}
