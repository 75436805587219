import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import router from '@/router';
import { useStore } from '@/hooks';
import { HeaderLayout, RequireAuth, withError, Loading } from '@/components';
import { ROUTE_URL } from '@/helper/constants';
import GlobalAlert from '@/pages/common/components/global-alert';
import GlobalLoading from '@/pages/common/components/global-loading';
import GlobalMessage from './pages/common/components/global-message';

import './App.scss';

function AppRoutes() {
  return (
    <Routes>
      {router.routes.map((route) => {
        const WrappedComponent = withError(route.component, route.path);
        return (
          <Route
            key={route.path}
            element={route.layout ? <route.layout /> : <HeaderLayout />}
          >
            <Route
              path={route.path}
              element={
                route.isAuth === false ? (
                  <WrappedComponent />
                ) : (
                  <RequireAuth>
                    <WrappedComponent />
                  </RequireAuth>
                )
              }
              key={route.path}
            >
              {route.children?.map((childRoute) => {
                const WrappedChildComponent = withError(
                  childRoute.component,
                  `${route.path}${childRoute.path}`,
                );
                return (
                  <Route
                    key={childRoute.path}
                    path={childRoute.path}
                    element={<WrappedChildComponent />}
                  />
                );
              })}
            </Route>
          </Route>
        );
      })}
      <Route path="*" element={<Navigate to={ROUTE_URL.ROOT} replace />} />
    </Routes>
  );
}

function App() {
  const { webStore } = useStore();
  webStore.init();

  return (
    <div className="app">
      <Router>
        <Suspense fallback={<Loading fullScreen />}>
          <AppRoutes />
        </Suspense>
      </Router>
      <GlobalAlert />
      <GlobalLoading />
      <GlobalMessage />
    </div>
  );
}

export default App;
