import { IDecorate } from './types';
import { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import Config from '@/config';
import Default from './default';
import GCPService from './gcpService';

/*
 * HTTP Decoration class
 * For multiple backends, different config such as headers, different response structures, they are mapped to different decoration classes for processing
 * Default: Decorate Default
 */
let HTTP_DECORATE_REGISTER: { [key: string]: () => IDecorate } = {
  [Config.H5_SERVICE.URL]: () => new GCPService(),
  [Config.BFF_SERVICE.URL]: () => new GCPService(),
};
{
  HTTP_DECORATE_REGISTER = { ...HTTP_DECORATE_REGISTER };
}

export default class HttpDecorate {
  Declare: IDecorate;
  register(url: string) {
    this.Declare = new Default();

    for (const key in HTTP_DECORATE_REGISTER) {
      if (url.startsWith(key)) {
        this.Declare = HTTP_DECORATE_REGISTER[key]();
        break;
      }
    }
  }

  async config(config: AxiosRequestConfig) {
    this.register(config.url);
    await this.Declare.config?.(config);
  }

  async success(
    result: AxiosResponse,
    request: (config: AxiosRequestConfig) => any,
  ) {
    return await this.Declare.success?.(result, request);
  }

  async error(err: AxiosError, request: (config: AxiosRequestConfig) => any) {
    return await this.Declare.error?.(err, request);
  }
}
