import http from './base/http';
import Config from '@/config';

export const getCountries = async <T>(language: string) => {
  return http.get<T>(
    `${Config.AIRAISA_STATIC}/flights/datasource/country/${language}/file.json`,
  );
};

export const getStations = async <T>(language: string) => {
  return http.get<T>(
    `${Config.AIRAISA_STATIC}/flights/station/v3/${language}/file.json`,
  );
};
