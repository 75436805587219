import http from '@/api/base/http';
import Config from '@/config';
import ParamManager from '@/helper/param.manager';
import { BaseLogData } from './types';

const LogAPI = {
  async error(e: BaseLogData) {
    return await this._saveLog(e, 'error');
  },

  async info(e: BaseLogData) {
    return await this._saveLog(e, 'info');
  },

  async warn(e: BaseLogData) {
    return await this._saveLog(e, 'warn');
  },

  async _saveLog<T>(e: BaseLogData, type: string) {
    const eData = await this._addOpenId(e);

    return http.post<T>(`${Config.WMP.BUSINESS_URL}/new-log/${type}`, eData, {
      headers: {
        'bg-key': ParamManager.getBgKey(),
      },
    });
  },

  async _addOpenId(e: BaseLogData) {
    if (e && typeof e === 'object' && !e.openId) {
      e.openId = ParamManager.getOpenId();
    }
    return e;
  },
};

export default LogAPI;
