import { action, observable, makeObservable, reaction } from 'mobx';
import { HotelApi } from '@/api';
import { hotelSearchComponentStore } from '@/store/common';
import { userStore } from '@/store/base';
import { transformHotelRoomsData } from '@/helper/hotel/hotel-rooms';
import { getOccupancyArray } from '@/helper/hotel/utils';
import { transformHotelContentData } from '@/helper/hotel/hotel-content';

export default class HotelDetailStore implements PageStore {
  hotelSearchComponentStore = hotelSearchComponentStore;

  @observable hotelId = '';
  @observable rooms = [];
  @observable hotelContent: Hotel.HotelContentInfo = null;
  @observable isLoading = true;
  @observable isRoomLoading = true;
  @observable galleryVisible = false;
  @observable hasError = false;
  @observable location = '';
  @observable countryCode = '';
  @observable isShowSearchWidget = false;

  constructor() {
    makeObservable(this);
    this.setPagetitle();

    reaction(
      () => {
        return userStore.currency;
      },
      () => {
        this.search();
      },
    );
  }

  @action
  onLoad = async () => {
    hotelSearchComponentStore.setFromURL();
    this.search();
  };

  @action
  onUnload = () => {};

  @action
  switchGallery = () => {
    this.galleryVisible = !this.galleryVisible;
  };

  @action
  showSearchScreen = () => {
    this.isShowSearchWidget = true;
  };

  @action
  hideSearchScreen = () => {
    this.isShowSearchWidget = false;
  };

  @action
  search = async () => {
    this.rooms = [];
    this.hotelContent = null;
    this.isLoading = true;
    this.isRoomLoading = true;
    this.hasError = false;

    try {
      await this.getDestDetail();
      await this.loadRooms();
      await this.loadHotelContent();

      this.setPagetitle();
    } catch (error) {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  };

  loadHotelContent = async () => {
    const hotelContent = await HotelApi.getHotelContent<any>(
      this.hotelId,
      userStore.locale,
    );
    this.hotelContent = transformHotelContentData(hotelContent);
    this.setPagetitle();
  };

  loadRooms = async () => {
    const tokenInfo = await userStore.getFlushedTokenInfo();

    try {
      const roomData = await HotelApi.getRooms<any>(
        this.hotelId,
        {
          countryCode: this.countryCode,
          occupancy: getOccupancyArray(hotelSearchComponentStore.roomOccupancy),
          currency: userStore.currency,
          language: userStore.locale,
          checkin: hotelSearchComponentStore.checkin,
          checkout: hotelSearchComponentStore.checkout,
        },
        tokenInfo,
      );
      this.rooms = transformHotelRoomsData(
        roomData,
        userStore.locale,
        userStore.isLogin,
        hotelSearchComponentStore.checkout,
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.isRoomLoading = false;
    }
  };

  getDestDetail = async () => {
    try {
      const result = await HotelApi.getDestDetail<any>({
        language: userStore.locale,
        locationId: hotelSearchComponentStore.locationId,
      });

      this.countryCode = result.country_code;
      this.location = result.name;
      this.setPagetitle();
    } catch (error) {
      //
    }
  };

  setPagetitle() {
    document.title = this.hotelContent
      ? this.hotelContent.hotelName
      : 'Loading...';
  }
}
