export default {
  checkout: {
    title: '乘客资料',
    continue: '继续',
    errors: {
      formError: '请填写标出部分以继续',
      dateOfBirthError: [
        '以下乘客的出生日期填写不正确：{{names}}，正确的日期格式应为DD/MM/YYYY',
        { names: '' },
      ],
      expiryDateError: [
        '以下乘客的旅行证件失效日期填写不正确：{{names}}，正确的日期格式应为DD/MM/YYYY',
        { names: '' },
      ],
      duplicatedTravelDocError: [
        '以下乘客的旅行证件号码填写重复：{{names}}',
        { names: '' },
      ],
      duplicatedNameAndDOBError: [
        '以下乘客的姓名和出生日期填写重复：{{names}}',
        { names: '' },
      ],
      invalidNationalityError: [
        '请重新选择以下乘客的“国籍/地区”：{{names}}',
        { names: '' },
      ],
      infantChildWithNoAdultError: '婴儿和儿童必须在18岁以上成人的陪伴下旅行',
      checkoutUnknownError: '未知异常错误，请重新尝试。',
      priceValidationError: '价格验证失败！请尝试重新搜索。',
    },
    baggageNames: {
      personal_item: '小包',
      cabin_bag: '手提包',
      hold_bag: '托运行李',
    },
    fareSummary: {
      FlightSummaryTitle: '航班信息',
      fareSummaryTitle: '航费总览',
      fareDiscount: '航费折扣',
      serviceCharge: '服务费',
      taxes: '税费, 费用\u0026附加费',
      baseFare: '基础费',
      fareChange:
        '我们的票价可能已经改变。重新搜索，看看我们最新的票价和可用性。',
      processingFee: '手续费',
      totalAmount: '支付总额',
      addOns: '增值服务',
      expand: '显示更多',
      collapse: '精简显示',
    },
    guestDetailsForm: {
      adult: '成人',
      child: '儿童',
      infant: '婴儿',
      infantSubtitle: '{max} 周岁以下',
      Error: '未正确填写',
      travelingWith: '同行乘客：{0}',
      id: '身份证',
      Complete: '完整',
      noRightKeywordLabel: '我们无法根据您的搜索找到任何结果',
      expiryDate: '失效日期(自由选填)',
      dateOfBirth: '出生日期',
      passport: '护照',
      ypta: 'Young passenger travelling alone',
      passportDetails: '旅游文件信息',
      ToComplete: '待填写',
      childSubtitle: '{age} - {max}周岁',
      searchNationality: '按国家/地区搜索',
      familyName: '姓氏',
      visaDisclaimerContent: '请确保您持有进入中转及/或最终目的地的旅游文件。',
      adultSubtitle: '{age}周岁级以上',
      popoverTitle: '姓名填写指南',
      nationality: '国籍/地区',
      Incomplete: '不完整',
      gender: '性别',
      clear: '清除',
      dateFormat: '日日/月月/年年年年',
      expiredError: '护照已失效',
      expiryDateRequired: 'Expiry date',
      personalDetails: '个人信息',
      passportNumber: '护照/身份证号码',
      documentNumber: '身份证号码',
      type: '旅行证件类型',
      docNumber: '身份证号',
      givenName: '名',
      popoverMessage:
        '姓名须与身份证件或护照上的英文字母(A至Z)一致，不支持特殊字符或符号。若您的姓名仅有1个字，请将其填写在姓氏及名称中。',
      visaDisclaimerTitle: '您或许需要为本次旅行办理签证。',
    },
    guestContactForm: {
      mobileNumber: '手机号码',
      countryCode: '电话代码',
      primaryContact: '主要联系方式',
      contactDetails: '联系方式',
      helpText: '我们会向您的电子邮箱及手机发送预订确认及任何更新信息。',
      salutation: '称呼',
      makeLabel: ['将{{name}}设为联系人', { name: '' }],
      email: '电子邮箱',
    },
    baggageSelector: {
      allowanceTitle: 'Baggage Allowance',
      allowanceContent:
        'Here&apos;s how much each guest can carry on every flight',
      sportsEquipmentLabel: '我需要运输体育器材',
      notificationMessage: '',
      checkBoxTitle: '为全部乘客选择一样的行李限额',
      total: '总价',
      depart: '出发航班',
      handCarry: '手提包',
      title: '行李',
      summaryButtonText: '变更',
      priceDescription: '行李限额总费用',
      checkedBaggage: '托运行李',
      handCarryToolTipTitle: '什么是手提行李？',
      sportsEquip: '运动装备',
      included: '包括',
      none: '无',
      handCarrySizeDesc: '',
      handCarryToolTipLabel:
        '这是您可以带入飞机的行李。您须将其存放入客舱行李架中。',
      return: '返程航班',
      submitButtonTitle: '完成',
      buttonTitle: '添加行李限额',
      tileDescription: '提前预订价格最优惠，所示价格适用于您行程中的全部航班。',
    },
    journey: {
      premium: '豪华经济舱',
      kiwiGuaranteeCTA: 'Kiwi.com保障',
      depart: '出发',
      return: '回程',
      business: '商务舱',
      hour: '小时',
      selfTransfer: '经由{0}自行中转',
      flight: '航班',
      multiStop: '{stop}转机',
      anotherMultiStop: '转机',
      carrier: '航空公司',
      via: '',
      technicalStop: '这是技术性停留，您无需变更航班。',
      nonStop: '直飞',
      singleStop: '1转机',
      layover: '转机',
      stopAt: '{1}停留{0}次',
      first: '头等舱',
      protectionByStr: '中转航班由{0}提供保障',
      operated: '运营：',
      day: '日',
      minute: '分钟',
      economy: '经济舱',
      dateFormat: 'M月D日 (ddd)',
    },
    validateMessage: {
      emailError: '请输入一个有效的电子邮箱地址（如：example@email.com）',
      childAgeError: '在出发当日2至18周岁(不满)的乘客为儿童乘客',
      familyNameMaxError:
        '已达{number}最高字符限制。请将其余姓氏输入名称栏中。',
      genericMaxError: 'Max allowed character is {max}.',
      adultAgeError: '乘客在出发当日须年满18周岁',
      alphanumericError: '请仅使用英文字母(A至Z)及数字，不支持特殊字符或符号。',
      givenNameMaxError: '已达{number}最高字符限制。请将其余名称输入姓氏栏中。',
      mobileNumberError: '请输入有效的手机号码。',
      numericError: '请仅输入数字',
      dateError: '请输入有效的日期',
      totalNameMaxError: '姓氏及名称不可超过{number}总字符。',
      dateExpiredAfter: '您的护照在旅行当日须至少拥有6个月的有效期',
      alphabetError: '请仅使用英文字母(A至Z)，不支持特殊字符或符号。',
      firstNameMaxError: '已达{number}最高字符限制。请将其余名称输入姓氏栏中。',
      infantAgeError: '在出发当日2周岁以下的乘客为婴儿乘客',
      dateExpired: '您的密码已失效',
      requiredError: '必填项',
    },
    bigMember: {
      search: '搜索',
      unavailable: '不可选',
      searchSubtitle: '通过该乘客的注册电子邮箱地址搜索亚航会员ID',
      points: '积分',
      earnBigPoints: '通过此预订赚取亚航积分',
      earnBigPointsGuest: '为此乘客赚取亚航积分',
      earnUpTo: '最多可赚取积分',
      detailedInvalidEmailMessage:
        '请输入一个有效的电子邮箱地址（如：example@email.com）',
      noData: '我们无法找到您的亚航会员ID。请再次尝试。',
      bigMemberId: '亚航会员ID',
      placeholder: '邮件地址(可选)',
    },
    sessionTimerPopup: {
      title: '对话已终止',
      subTitle: '有阵没来啦？没关系，马上搜索新航班了解最新航费！',
      primaryButtonTitle: '再次搜索',
    },
    sessionTimer: {
      min: '分',
      secLeft: '秒',
    },
    familyAndFriends: {
      maxSelectedText:
        '最多可选择{0}位乘客（包括成人，儿童，婴儿），请取消选择乘客以选择新乘客',
      selectedCountText: '最多可选择{0}位乘客',
      title: '家人和朋友',
    },
  },
};
